import { defineStore } from 'pinia'
import * as api from '../api'

export const useThirdPartyStore = defineStore('thirdParty', {
  state: () => ({
    getGoogleRegisteringUserBusy: false,
    getGoogleRegisteringUserSuccess: false,
    getGoogleRegisteringUserErrors: undefined,
    googleRegisteringUser: undefined,
    getMicrosoftRegisteringUserBusy: false,
    getMicrosoftRegisteringUserSuccess: false,
    getMicrosoftRegisteringUserErrors: undefined,
    microsoftRegisteringUser: undefined,
    getEliisRegisteringUserBusy: false,
    getEliisRegisteringUserSuccess: false,
    getEliisRegisteringUserErrors: undefined,
    eliisRegisteringUser: undefined,
    passkeysLoginOptionsBusy: false,
    passkeysLoginOptionsSuccess: false,
    passkeysLoginOptionsResponse: undefined,
  }),

  actions: {
    // Google registration actions
    async getGoogleRegisteringUser() {
      this.getGoogleRegisteringUserBusy = true
      try {
        const response = await api.getGoogleRegisteringUser()
        this.getGoogleRegisteringUserSuccess = response.success
        if (response.success) {
          this.googleRegisteringUser = response.data
        }
        return response
      } catch (error) {
        this.getGoogleRegisteringUserErrors = error.errors || []
        return error
      } finally {
        this.getGoogleRegisteringUserBusy = false
      }
    },

    // Microsoft registration actions
    async getMicrosoftRegisteringUser() {
      this.getMicrosoftRegisteringUserBusy = true
      try {
        const response = await api.getMicrosoftRegisteringUser()
        this.getMicrosoftRegisteringUserSuccess = response.success
        if (response.success) {
          this.microsoftRegisteringUser = response.data
        }
        return response
      } catch (error) {
        this.getMicrosoftRegisteringUserErrors = error.errors || []
        return error
      } finally {
        this.getMicrosoftRegisteringUserBusy = false
      }
    },

    // Eliis registration actions
    async getEliisRegisteringUser() {
      this.getEliisRegisteringUserBusy = true
      try {
        const response = await api.getEliisRegisteringUser()
        this.getEliisRegisteringUserSuccess = response.success
        if (response.success) {
          this.eliisRegisteringUser = response.data
        }
        return response
      } catch (error) {
        this.getEliisRegisteringUserErrors = error.errors || []
        return error
      } finally {
        this.getEliisRegisteringUserBusy = false
      }
    },

    // Passkeys login options
    async getPasskeysLoginOptions() {
      this.passkeysLoginOptionsBusy = true
      try {
        const response = await api.getPasskeysLoginOptions()
        this.passkeysLoginOptionsSuccess = true
        this.passkeysLoginOptionsResponse = response
        return response
      } catch (error) {
        this.passkeysLoginOptionsSuccess = false
        return error
      } finally {
        this.passkeysLoginOptionsBusy = false
      }
    },

    // Passkeys verification actions
    async getPasskeysVerification(passkeysAuthorizationResp) {
      return api.getPasskeysVerification(passkeysAuthorizationResp)
    },
  },
})