import { defineStore } from 'pinia'
import * as api from '../api'

export const useTermsStore = defineStore('terms', {
  state: () => ({
    serviceTermsBusy: false,
    serviceTerms: '',
    cookiesPrivacyPolicyTermsBusy: false,
    cookiesPrivacyPolicyTerms: '',
    privacyPolicyTermsBusy: false,
    privacyPolicyTerms: '',
    downloadServiceTermsBusy: false,
    downloadPrivacyPolicyBusy: false,
    acceptTermsBusy: false,
    acceptTermsError: '',
    acceptTermsSuccess: false,
    serviceTermsReadBusy: false,
    serviceTermsRead: '',
  }),

  actions: {
    async getServiceTerms() {
      this.serviceTermsBusy = true
      try {
        const response = await api.getServiceTerms()
        this.serviceTerms = response
      } catch (error) {
        // Handle error
      } finally {
        this.serviceTermsBusy = false
      }
    },

    async getPrivacyPolicyTerms() {
      this.privacyPolicyTermsBusy = true
      try {
        const response = await api.getPrivacyPolicyTerms()
        this.privacyPolicyTerms = response
      } catch (error) {
        // Handle error
      } finally {
        this.privacyPolicyTermsBusy = false
      }
    },

    async getCookiesPrivacyPolicyTerms() {
      this.cookiesPrivacyPolicyTermsBusy = true
      try {
        const response = await api.getCookiesPrivacyPolicyTerms()
        this.cookiesPrivacyPolicyTerms = response
      } catch (error) {
        // Handle error
      } finally {
        this.cookiesPrivacyPolicyTermsBusy = false
      }
    },

    async downloadServiceTerms() {
      this.downloadServiceTermsBusy = true
      try {
        await api.downloadServiceTerms()
      } catch (error) {
        // Handle error
      } finally {
        this.downloadServiceTermsBusy = false
      }
    },

    async downloadPrivacyPolicyTerms() {
      this.downloadPrivacyPolicyTermsBusy = true
      try {
        await api.downloadPrivacyPolicyTerms()
      } catch (error) {
        // Handle error
      } finally {
        this.downloadPrivacyPolicyTermsBusy = false
      }
    },

    async acceptTerms({ termsAccepted }) {
      this.acceptTermsBusy = true
      try {
        const response = await api.acceptTerms(termsAccepted)
        this.acceptTermsSuccess = response.success
      } catch (error) {
        this.acceptTermsError = error.errors[0]
      } finally {
        this.acceptTermsBusy = false
      }
    },

    async getServiceTermsRead() {
      this.serviceTermsReadBusy = true
      try {
        const response = await api.getServiceTermsRead()
        this.serviceTermsRead = response
      } catch (error) {
        // Handle error
      } finally {
        this.serviceTermsReadBusy = false
      }
    },
  },
})