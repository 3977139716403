<template>
  <div class="d-flex align-center">
    <div class="country-flag">
      <CountryFlag
        class="country-flag-item"
        :country="country === 'UK' ? 'GB' : country"
      />
    </div>
    <span
      v-if="countryName"
      class="ml-2 text-truncate"
    > {{ countryName }} </span>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag-next'
export default {
    components: {
        CountryFlag,
    },
    props: {
        country: {
            type: String,
            default: '',
        },
        countryName: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss">
.country-flag {
  height: 20px;
  &-item {
    &.normal-flag {
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
      transform: translate(-4%, -25%) scale(0.5) !important;
    }
  }
}
</style>