<template>
  <div class="view-content__wrapper">
    <div class="view-content">
      <div
        class="d-flex justify-center align-center"
        :class="activeItem !== '' || activeItem == 'trustDevice' ? 'mb-8' : 'mb-6'"
      >
        <div
          v-if="project == 'eKool'"
          class="logo-container"
          :class="{ 'mr-2': activeItem !== '' && activeItem !== 'trustDevice' }"
        >
          <img
            src="../assets/ekool-logo.svg"
            alt="eKool logo"
          >
          <img
            class="logo-container__small-logo"
            src="../assets/protected-logo.svg"
            alt="shield logo"
          >
        </div>
        <div
          v-if="activeItem !== '' && activeItem !== 'trustDevice' && project == 'eKool'"
          class="arrow-icons"
        >
          <v-icon
            size="20"
            class="arrow-icons__arrow-left"
          >
            {{ $vuetify.theme.global.name == 'dark' ? 'icon-arrow-left text-grayscale600' : 'icon-arrow-left text-grayscale400' }}
          </v-icon>
          <v-icon
            size="20"
            class="arrow-icons__arrow-right"
          >
            {{ $vuetify.theme.global.name == 'dark' ? 'icon-arrow-right text-grayscale600' : 'icon-arrow-right text-grayscale400' }}
          </v-icon>
        </div>
        <div
          v-if="activeItem !== '' && activeItem !== 'trustDevice'"
          class="logo-container"
        >
          <img
            v-if="activeItem == 'sms'"
            :src="$vuetify.theme.global.name == 'dark' ? require(`../assets/sms-logo-white.svg`) : require(`../assets/sms-logo.svg`)"
            alt="SMS logo"
          >
          <img
            v-if="activeItem == 'email'"
            :src="
              $vuetify.theme.global.name == 'dark' ? require(`../assets/letter-logo-white.svg`) : require(`../assets/letter-logo.svg`)
            "
            alt="letter logo"
          >
          <img
            v-if="activeItem == 'otp'"
            :src="$vuetify.theme.global.name == 'dark' ? require(`../assets/phone-logo-white.svg`) : require(`../assets/phone-logo.svg`)"
            alt="phone logo"
          >
          <img
            v-if="activeItem == 'recovery'"
            :src="
              $vuetify.theme.global.name == 'dark' ? require(`../assets/key-icon-white.svg`) : require(`../assets/key-icon-black.svg`)
            "
            alt="key icon"
          >
        </div>
      </div>
      <div
        class="headline-1 text-center"
        :class="activeItem == '' ? 'mb-6' : 'mb-1'"
      >
        <span v-if="activeItem == 'otp'">{{ $t('two_factor_options.check_your_authenticatior') }}</span>
        <span v-else-if="activeItem == 'email'">{{ $t('two_factor_options.check_your_inbox') }}</span>
        <span v-else-if="activeItem == 'sms'">{{ $t('two_factor_options.check_your_messages') }}</span>
        <span v-else-if="activeItem == 'trustDevice'">{{ $t('two_factor_options.trust_device_title') }}</span>
        <span v-else-if="activeItem == 'recovery'">{{ $t('two_factor_options.recovery_title') }}</span>
        <span v-else>{{ $t('two_factor_options.select_authentication_method') }}</span>
      </div>
      <transition
        name="fade"
        mode="out-in"
      >
        <div v-if="activeItem == ''">
          <div
            v-for="(method, index) in availableMethods"
            :key="index"
            :class="{ 'mb-4': index < availableMethods.length }"
            class="two-factor-option"
            @click="selectItem(method.name)"
          >
            <div class="two-factor-option__icon">
              <v-icon
                v-if="method.icon"
                size="20"
              >
                {{ `icon-${method.icon} text-primary400` }}
              </v-icon>
              <img
                v-else
                :src="method.imageSrc"
              >
            </div>
            <div class="two-factor-option__content ml-3">
              <div class="two-factor-option__content__header">
                <span
                  class="subhead-2"
                  :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-grayscale800'"
                >
                  {{ $t(method.title) }}
                </span>
                <v-icon size="24">
                  icon-chevron-right text-primary300
                </v-icon>
              </div>
              <div>
                <span
                  class="body-3"
                  :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
                >
                  {{ $t(method.description) }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-center">
            <e-btn
              variant="tertiary"
              @click="cancel"
            >
              {{ $t('terms.cancel') }}
            </e-btn>
          </div>
        </div>
        <TrustDevice v-else-if="activeItem == 'trustDevice'" />
        <TwoFactor
          v-else-if="activeItem !== ''"
          :method="activeItem"
        />
      </transition>
      <div
        v-if="activeItem !== '' && activeItem !== 'trustDevice' && availableMethods.length > 1"
        class="d-flex flex-column align-center"
      >
        <div class="separator my-6" />
        <span
          class="mt-4 caption-1 caption-1-plus cursor-pointer"
          :class="$vuetify.theme.global.name == 'dark' ? 'text-primary300' : 'text-primary400'"
          @click="selectItem('')"
        >{{ $t('two_factor_options.try_different_method') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import TwoFactor from '@/components/TwoFactor.vue'
import TrustDevice from '@/components/TrustDevice.vue'
import { mapState, mapGetters, mapActions } from 'pinia'
import { usePasswordStore, useGlobalStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { useToast } from "@k12tech/ekool-vue-framework/src/plugins/toast"
export default {
  components: {
    TwoFactor,
    TrustDevice,
    EBtn,
  },
  setup() {
    const { toast } = useToast()
    return { toast }
  },
  data() {
    return {
      activeItem: '',
      allowedMethods: [
        {
          name: 'otp',
          title: 'two_factor_options.otp',
          icon: 'phone',
          description: 'two_factor_options.otp_description',
        },
        {
          name: 'email',
          title: 'two_factor_options.email',
          icon: 'letter',
          description: 'two_factor_options.email_description',
        },
        {
          name: 'sms',
          title: 'two_factor_options.sms',
          icon: 'message-bubbles',
          description: 'two_factor_options.sms_description',
        },
        {
          name: 'recovery',
          title: 'two_factor_options.recovery',
          imageSrc: require(`../assets/key-icon.svg`),
          description: 'two_factor_options.recovery_description',
        },
      ],
      availableMethods: [],
      timeLeft: 0,
      timeoutTimer: false,
      project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
    }
  },
  computed: {
    ...mapState(useGlobalStore, ['mobileView']),
    ...mapState(usePasswordStore, [
      'twoFactorAvailableMethods',
      'twoFactorRequestBusy',
      'twoFactorRequestSuccess',
      'twoFactorRequestError',
      'twoFactorRequestErrors',
      'twoFactorRequestTimeout',
      'rememberRequestRequired',
    ]),
    ...mapGetters(useGlobalStore, ['getError']),
  },
  watch: {
    twoFactorAvailableMethods() {
      this.handleAvailableMethods()
    },
    rememberRequestRequired(val) {
      if (val) {
        this.selectItem('trustDevice')
      }
    },
  },
  mounted() {
    this.handleAvailableMethods()
  },
  methods: {
    ...mapActions(usePasswordStore, ['twoFactorRequest']),
    selectItem(item) {
      const method = item
      if (method !== 'otp' && method !== 'trustDevice' && method !== 'recovery' && method !== '') {
        this.twoFactorRequest({ method }).then(() => {
          if (this.twoFactorRequestSuccess) {
            this.activeItem = item
          } else {
            this.countdown()
            this.twoFactorRequestErrors == 'too_many_requests'
              ? this.toast.error(this.$i18n.t('setup_account.too_many_requests', { x: this.timeLeft }))
              : this.toast.error(this.$i18n.t(`setup_account.${this.twoFactorRequestErrors}`))
          }
        })
      } else {
        this.activeItem = item
      }
    },
    handleAvailableMethods() {
      try {
        this.availableMethods = this.allowedMethods.filter((method) =>
          this.twoFactorAvailableMethods.some((availableMethod) => availableMethod.name === method.name)
        )
        const priorityMethod = this.twoFactorAvailableMethods.find((method) => method.priority === true)
        //set activeItem by priority
        this.activeItem = priorityMethod.name
      } catch {
        this.$router.push({
          name: 'login',
        })
      }
    },
    countdown() {
      clearInterval(this.timeoutTimer)
      this.timeLeft = this.twoFactorRequestTimeout
      if (this.timeLeft > 0) {
        //this.requestNew = true;
      }
      this.timeoutTimer = setInterval(() => {
        if (this.timeLeft == 0) {
          clearInterval(this.timeoutTimer)
          //this.requestNew = false;
        } else {
          this.timeLeft -= 1
        }
      }, 1000)
    },
    cancel() {
      window.top.location.href = getEnv('VUE_APP_FRONTEND_API_URL') + '/logout'
    },
  },
}
</script>
