import * as api from '../api'
import { defineStore } from 'pinia'

export const useNewPasswordStore = defineStore('newPassword', {
  state: () => ({
    newPasswordBusy: false,
    newPasswordError: false,
    newPasswordSuccess: false,
    newPasswordErrors: undefined,
    newPasswordValidationBusy: false,
    newPasswordValidToken: false,
  }),

  actions: {
    // set new password
    async setNewPassword({ token, username, password, passwordConfirmation }) {
      this.newPasswordBusy = true
      try {
        const response = await api.resetPassword(token, username, password, passwordConfirmation)
        this.setNewPasswordSuccess(response)
        return response
      } catch (error) {
        this.setNewPasswordFail(error)
        return error
      }
    },

    setNewPasswordSuccess(response) {
      this.newPasswordBusy = false
      if (response.success) {
        this.newPasswordSuccess = true
      }
    },

    setNewPasswordFail(error) {
      this.newPasswordBusy = false
      if (error.errors) {
        this.newPasswordErrors = error.errors
        this.newPasswordError = true
      } else {
        this.newPasswordError = true
      }
    },

    // validate token
    async validateToken({ token, type }) {
      this.newPasswordValidationBusy = true
      try {
        const response = await api.validateToken(token, type)
        this.validateTokenSuccess(response)
        return response
      } catch (error) {
        this.validateTokenFail(error)
        return error
      }
    },

    validateTokenSuccess(response) {
      this.newPasswordValidationBusy = false
      if (response.success) {
        this.newPasswordValidToken = true
      } else {
        this.newPasswordValidToken = false
      }
    },

    validateTokenFail(error) {
      this.newPasswordValidationBusy = false
      this.newPasswordValidToken = false
      this.newPasswordErrors = error.errors
      this.newPasswordError = true
    },
  },
})