import { defineStore } from 'pinia'

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    showFooter: true,
    showNav: true,
    showIlustration: true,
    iframeView: false,
    showLanguages: true,
    mobileAppPromo: true,
  }),

  actions: {
    setShowFooter(value) {
      this.showFooter = value
    },
    setShowNav(value) {
      this.showNav = value
    },
    setShowIlustration(value) {
      this.showIlustration = value
    },
    setIframeView(value) {
      this.iframeView = value
    },
    setShowLanguages(value) {
      this.showLanguages = value
    },
    setMobileAppPromo(value) {
      this.mobileAppPromo = value
    },
  },
})