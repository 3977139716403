<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="view-content__wrapper">
    <div class="view-content d-block d-sm-flex">
      <!-- <ErrorMessage
				v-if="thirdPartyError"
				:errorMessage="thirdPartyError"> -->
      <transition name="fade">
        <div
          v-if="!thirdPartyBusy && !thirdPartyRequestBusy"
          class="permission-view mx-auto"
        >
          <h1
            class="headline-1 text-graybase mb-8"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('permissions.wants_access_to', { X: permissionData.client_name }) }}
          </h1>
          <div class="permission-list">
            <div
              v-for="(permission, index) in permissionDataEdited.scopes"
              v-if="index < itemLimit"
              :key="index"
              class="permission-list__entry"
            >
              <div
                class="permission-list__entry__icon"
                :class="$vuetify.theme.global.name == 'dark' ? 'grayscale600' : 'grayscale100'"
              >
                <span
                  class="text-primary"
                  :class="permission.icon"
                />
              </div>
              <div
                class="permission-list__entry__text body-2"
                :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale600' : 'text-graybase'"
              >
                {{ $t(permission.description) }}
              </div>
            </div>
            <div
              v-if="itemLimit == 4 && permissionDataEdited.scopes.length > 4"
              class="permission-list__entry"
            >
              <div
                class="permission-list__entry__icon"
                :class="$vuetify.theme.global.name == 'dark' ? 'grayscale600' : 'grayscale100'"
              >
                <span class="text-primary icon-dots-horizontal" />
              </div>
              <div class="permission-list__entry__text">
                <e-btn
                  variant="link"
                  size="small"
                  @click="showAllPermissions()"
                >
                  {{ $t('View more') }}
                </e-btn>
              </div>
            </div>
          </div>

          <!-- If you change any of this, youre on your own, dont @ me on slack -->
          <div class="permission-footer mt-6">
            <form
              class="w-100"
              method="post"
              action="/oauth/authorize"
            >
              <input
                type="hidden"
                name="_method"
                value="DELETE"
              >
              <input
                type="hidden"
                name="state"
                :value="permissionData.state"
              >
              <input
                type="hidden"
                name="client_id"
                :value="permissionData.client_id"
              >
              <e-btn
                :block="false"
                type="submit"
                variant="tertiary"
                size="large"
                @click="denyThirdPartyRequestMethod"
              >
                {{ $t('permissions.reject') }}
              </e-btn>
            </form>
            <form
              class="w-100 ml-4"
              method="post"
              action="/oauth/authorize"
            >
              <input
                type="hidden"
                name="state"
                :value="permissionData.state"
              >
              <input
                type="hidden"
                name="client_id"
                :value="permissionData.client_id"
              >
              <e-btn
                :block="false"
                type="submit"
                variant="primary"
                size="large"
                @click="acceptThirdPartyRequestMethod"
              >
                {{ $t('permissions.allow') }}
              </e-btn>
            </form>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <e-circular-loading
          v-if="thirdPartyBusy || thirdPartyRequestBusy"
          class="pt-4"
          value
          :full-page="false"
          :size="42"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'pinia'
import { useThirdPartyPermissionsStore } from '@/store'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      itemLimit: 4,
    }
  },
  computed: {
    ...mapState(useThirdPartyPermissionsStore, ['thirdPartyBusy', 'permissionData', 'thirdPartyRequestBusy']),
    ...mapGetters(useThirdPartyPermissionsStore, ['permissionDataEdited']),
  },
  mounted() {
    this.getThirdPartyData()
  },
  methods: {
    ...mapActions(useThirdPartyPermissionsStore, ['getThirdPartyData', 'acceptThirdPartyRequest', 'denyThirdPartyRequest']),
    acceptThirdPartyRequestMethod() {
      this.acceptThirdPartyRequest()
    },
    denyThirdPartyRequestMethod() {
      this.denyThirdPartyRequest()
    },
    showAllPermissions() {
      this.itemLimit = 1000
    },
  },
}
</script>
