<template>
  <div class="terms-view">
    <e-dialog
      v-model="dialog"
      persistent
      max-width="484"
      hide-default-header
    >
      <e-dialog-body>
        <e-dialog-content>
          <div class="d-flex flex-column align-center">
            <div class="w-100 pa-8">
              <div class="d-flex mb-8 justify-center align-center">
                <div class="logo-container mr-2">
                  <img
                    src="../assets/ekool-logo.svg"
                    alt="eKool logo"
                  >
                  <img
                    class="logo-container__small-logo"
                    src="../assets/protected-logo.svg"
                    alt="shield logo"
                  >
                </div>
                <div class="arrow-icons">
                  <v-icon
                    size="20"
                    class="arrow-icons__arrow-left"
                  >
                    {{ $vuetify.theme.global.name == 'dark' ? 'icon-arrow-left text-grayscale600' : 'icon-arrow-left text-grayscale400' }}
                  </v-icon>
                  <v-icon
                    size="20"
                    class="arrow-icons__arrow-right"
                  >
                    {{
                      $vuetify.theme.global.name == 'dark' ? 'icon-arrow-right text-grayscale600' : 'icon-arrow-right text-grayscale400'
                    }}
                  </v-icon>
                </div>
                <div class="logo-container">
                  <img
                    :src="require(`../assets/${method}-logo.svg`)"
                    alt="logo"
                    height="42px"
                  >
                </div>
              </div>
              <div class="d-flex flex-column align-center">
                <span class="headline-3 mb-1">{{
                  $t('third_party_login.connect_title', { method: methodNameCapitalize })
                }}</span>
                <span
                  class="body-2 text-center"
                  :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
                >
                  {{ $t('third_party_login.connect_description', { method: methodNameCapitalize }) }}</span>
              </div>
            </div>
            <e-dialog-actions>
              <e-btn
                :block="false"
                variant="tertiary"
                @click="cancel"
              >
                {{ $t('terms.cancel') }}
              </e-btn>
              <e-btn
                :block="false"
                variant="primary"
                :href="connectAccountUrl"
              >
                {{ $t('third_party_login.connect_account') }}
              </e-btn>
            </e-dialog-actions>
          </div>
        </e-dialog-content>
      </e-dialog-body>
    </e-dialog>
  </div>
</template>
<script>
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { EDialog, EDialogBody, EDialogContent, EDialogActions } from "@k12tech/ekool-vue-framework/src/components/EDialog"
import { useToast } from "@k12tech/ekool-vue-framework/src/plugins/toast";
export default {
  components: {
    EBtn,
    EDialog,
    EDialogBody,
    EDialogContent,
    EDialogActions,
  },
  props: {
    method: {
      type: String,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { toast } = useToast()
    return { toast }
  },
  data() {
    return {
      dialog: true,
    }
  },
  computed: {
    connectAccountUrl() {
      return `link/${this.method}/callback?hash=${this.hash}`
    },
    methodNameCapitalize() {
      return this.method.charAt(0).toUpperCase() + this.method.slice(1)
    },
  },
  watch: {},
  mounted() {
    this.dialog = true
  },
  beforeUnmount() {},
  methods: {
    close() {
      this.dialog = false
    },
    cancel() {
      this.toast(this.$i18n.t(`messages.accounts_were_not_linked`))
      this.close()
    },
  },
}
</script>
