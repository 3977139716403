<template>
  <div class="terms-view">
    <e-dialog
      v-model="dialog5"
      :title="$t(termsModalHeading)"
      persistent
      max-width="785"
      hide-default-header
    >
      <e-dialog-body>
        <div class="px-4 pt-4">
          <div class="pb-4 d-flex flex-row terms-modal__header">
            <e-btn
              v-if="currentTermsPage == 'privacyPolicy'"
              class="terms-modal__header__button"
              icon="icon-arrow-left"
              variant="ghost"
              @click="openServiceTerms"
            />
            <div class="text-center">
              <h1 class="headline-1">
                {{ $t(termsModalHeading) }}
              </h1>
            </div>
          </div>
        </div>
        <e-dialog-content>
          <div class="d-flex flex-column overflow-hidden">
            <div
              class="terms-content"
            >
              <!-- Service terms -->
              <div
                v-if="currentTermsPage == 'service'"
                @click="handleClick"
                v-html="serviceTerms"
              />
              <!-- Privacy policy terms -->
              <div
                v-if="currentTermsPage == 'privacyPolicy'"
                @click="handleClick"
                v-html="privacyPolicyTerms"
              />
            </div>
          </div>
        </e-dialog-content>
        <div class="terms-button-container">
          <e-btn
            variant="ghost"
            @click="download"
          >
            {{ $t('terms.save') }}
          </e-btn>
          <div
            v-if="currentTermsPage == 'service'"
            class="terms-button-container__group"
          >
            <e-btn
              variant="tertiary"
              class="mr-3"
              @click="toLogin"
            >
              {{ $t('terms.cancel') }}
            </e-btn>
            <e-btn
              variant="primary"
              :loading="acceptTermsBusy"
              @click="acceptTerms"
            >
              {{ $t('terms.agree') }}
            </e-btn>
          </div>
        </div>
      </e-dialog-body>
    </e-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useTermsStore, useLayoutStore, usePasswordStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { EDialog, EDialogBody, EDialogContent } from "@k12tech/ekool-vue-framework/src/components/EDialog"
export default {
  components: {
    EBtn,
    EDialog,
    EDialogBody,
    //EDialogActions,
    EDialogContent,
  },
  data() {
    return {
      dialog5: true,
      currentTermsPage: 'service',
      prevShowIlustration: false,
    }
  },
  computed: {
    ...mapState(useTermsStore, [
      'serviceTermsBusy',
      'serviceTerms',
      'cookiesPrivacyPolicyTermsBusy',
      'privacyPolicyTerms',
      'privacyPolicyTermsBusy',
      'acceptTermsBusy',
      'acceptTermsError',
      'acceptTermsSuccess',
      'showIlustration',
    ]),
    termsModalHeading() {
      return this.currentTermsPage == 'service' ? 'terms.terms_of_service' : 'terms.privacy_policy'
    },
  },
  mounted() {
    this.prevShowIlustration = this.showIlustration

    this.setShowIlustration(false) //hide ilustration
    this.getServiceTerms()
    this.getPrivacyPolicyTerms()
  },
  beforeUnmount() {
    this.prevShowIlustration ? this.setShowIlustration(true) : null //show ilustration
  },
  methods: {
    ...mapActions(useTermsStore, [
      'getServiceTerms',
      'getPrivacyPolicyTerms',
      'acceptTerms',
    ]),
    ...mapActions(useLayoutStore, ['setShowIlustration']),
    ...mapActions(usePasswordStore, ['setUser']),
    toLogin() {
      location.href = getEnv('VUE_APP_FRONTEND_API_URL') + '/logout'
    },
    getServiceTerms() {
      this.getServiceTerms()
    },
    getPrivacyPolicyTerms() {
      this.getPrivacyPolicyTerms()
    },
    openPrivacyPolicy() {
      this.currentTermsPage = 'privacyPolicy'
    },
    handleClick(e) {
      let clickedElId = e.target.id
      if (clickedElId === 'privacyPolicy') {
        this.openPrivacyPolicy()
      }
    },
    acceptTerms() {
      let termsAccepted = true
      this.acceptTerms({ termsAccepted }).then((response) => {
        if (this.acceptTermsSuccess) {
          if (response.redirect) {
            window.top.location.href = response.url
          } else {
            this.setUser({ url: response.url })
          }
        }
        if (!this.acceptTermsSuccess && this.acceptTermsError != '') {
          location.href = location.href =
            getEnv('VUE_APP_FRONTEND_API_URL') + `/logout?error_code=${this.acceptTermsError}`
        }
      })
    },
    download() {
      let fileLink =
        this.currentTermsPage == 'service'
          ? `${getEnv('VUE_APP_FRONTEND_API_URL')}/terms/service-terms/download`
          : `${getEnv('VUE_APP_FRONTEND_API_URL')}/terms/privacy-policy/download`
      window.open(fileLink, '_blank')
    },
    openServiceTerms() {
      this.currentTermsPage = 'service'
    },
  },
}
</script>
