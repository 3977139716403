<template>
  <div>
    <div
      v-if="!register && showFullPasswordForm && showPasskeyDisclaimer"
      class="passkey-promo mb-5"
    >
      <img
        src="../assets/passkey-logo-blue.svg"
        alt="passkey icon"
      >
      <div>
        <span
          class="body-3"
          :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-grayscale800'"
        >
          {{ $t('password_login.passkey_promo_1') + ' ' }}
        </span>
        <a
          class="body-3 primary-text-link primary-text-link--underline cursor-pointer"
          :href="computedLink"
          target="_blank"
        >{{ $t('password_login.passkey_promo_2') }}
        </a>
      </div>
    </div>
    <v-form
      v-if="!register && !showFullPasswordForm"
      ref="formA"
      data-form-type="login"
      validate-on="submit lazy"
      @submit.prevent="proceed"
    >
      <div class="pt-4">
        <e-text-field
          v-model="username"
          :type="usernameType"
          :rules="[rules.required($i18n.t('fields.email'))]"
          hide-details="auto"
          size="large"
          :label="$t('password_login.email')"
          data-form-type="username"
          autocomplete="username"
          name="username"
        />
      </div>
      <e-btn
        class="mt-2"
        variant="link"
        size="small"
        :to="'/' + locale + '/forgotPassword'"
      >
        {{ $t('password_login.forgot_password') }}
      </e-btn>
      <e-btn
        class="callout-3 mb-4 mt-4"
        :block="true"
        type="submit"
        variant="primary"
        size="large"
      >
        {{ $t('password_login.continue_with_password') }}
      </e-btn>
    </v-form>
    <v-form
      v-if="!register && showFullPasswordForm"
      ref="formB"
      data-form-type="login"
      autocomplete="on"
      validate-on="submit lazy"
      @submit.prevent="
        () => {
          passwordLoginBusy ? null : login()
        }
      "
    >
      <div class="py-4">
        <e-text-field
          v-model="username"
          :type="usernameType"
          :rules="[rules.required($i18n.t('fields.email'))]"
          hide-details="auto"
          size="large"
          :label="$i18n.t('password_login.email')"
          data-form-type="username"
          autocomplete="username"
          name="username"
        />
      </div>
      <div>
        <e-text-field
          ref="password"
          v-model="password"
          size="large"
          :label="$i18n.t('password_login.password')"
          :type="showPassword ? 'text' : 'password'"
          :rules="[rules.required($i18n.t('fields.password'))]"
          hide-details="auto"
          :append-inner-icon="showPassword ? 'icon-eye-cut' : 'icon-eye'"
          data-form-type="password"
          autocomplete="current-password"
          name="password"
          @click:append-inner="showPassword = !showPassword"
        />
      </div>

      <e-btn
        class="mt-2"
        variant="link"
        size="small"
        :to="'/' + locale + '/forgotPassword'"
      >
        {{ $t('password_login.forgot_password') }}
      </e-btn>
      <e-btn
        class="mt-4 callout-3"
        :block="true"
        type="submit"
        :loading="passwordLoginBusy && !register"
        :disabled="disableButton"
        variant="primary"
        size="large"
      >
        {{ $t('password_login.login') }}
      </e-btn>
    </v-form>
    <v-form
      v-if="register"
      ref="formC"
      data-form-type="login"
      validate-on="submit lazy"
      @submit.prevent="toRegistration"
    >
      <div class="py-4">
        <e-text-field
          v-model="username"
          :type="usernameType"
          :rules="[rules.required($i18n.t('fields.email')), rules.email($i18n.t('fields.email'))]"
          hide-details="auto"
          size="large"
          :label="$i18n.t('password_login.email')"
          data-form-type="username"
          name="username"
        />
      </div>
      <e-btn
        class="callout-3 mb-4"
        :block="true"
        type="submit"
        variant="primary"
        size="large"
      >
        {{ $t('password_login.continue_with_password') }}
      </e-btn>
    </v-form>
    <div
      v-show="showTurnstileWidget"
      ref="turnstileRef"
      class="turnstile-widget mt-4"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'pinia'
import { usePasswordStore, useCFTurnstileStore, useGlobalStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ETextField } from '@k12tech/ekool-vue-framework/src/components/ETextField'
import { validationRules } from '@/validationRules.js'
import { turnstileMixin } from "@/utils/turnstileMixin";
export default {
  components: {
    EBtn,
    ETextField,
  },
  mixins: [turnstileMixin],
  props: {
    register: Boolean,
    showFullPasswordForm: Boolean,
  },
  data() {
    return {
      codeSent: false,
      username: '',
      password: '',
      twoFactor: '',
      showPassword: false,
      usernameType: 'username',
      rules: validationRules,
    }
  },

  computed: {
    locale() {
      return this.$i18n.locale
    },
    ...mapState(usePasswordStore, [
      'passwordLoginBusy',
      'passwordSuccess',
      'passwordError',
      'passwordLoggedIn',
      'passwordShowTwoFactor',
      'passwordRedirect',
      'passwordErrors',
      'twoFactorBusy',
      'twoFactorError',
      'twoFactorErrors',
      'twoFactorSuccess',
      'termsRequired',
    ]),
    ...mapState(useCFTurnstileStore, [
      'disableButton',
      'CFTurnstileScriptLoaded',
    ]),
    ...mapState(useGlobalStore, ['miniView']),
    ...mapGetters(useGlobalStore, [
      'getError',
    ]),
    showPasskeyDisclaimer() {
      const allowedMethods = getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS').split(', ')
      return allowedMethods.includes('passkey')
    },
    computedLink() {
      return this.$i18n.locale == 'en' ? 'https://help.ekool.eu/en_US/passkey' : 'https://help.ekool.eu/et_ET/turvavoti'
    },
  },
  watch: {
    CFTurnstileScriptLoaded(val) {
      if (val) {
        this.initializeTurnstile(this.$refs.turnstileRef)
      }
    }
  },
  mounted() {
    this.initError()
    this.$nextTick(() => {
      if (this.CFTurnstileScriptLoaded && this.$refs.turnstileRef) {
        this.initializeTurnstile(this.$refs.turnstileRef); // Initialize Turnstile after DOM is updated
      }
    });
  },
  beforeUnmount() {
    this.removeTurnstile()
  },
  methods: {
    ...mapActions(usePasswordStore, [
      'loginUser',
      'setUser',
    ]),
    ...mapActions(useGlobalStore, [
      'showError',
      'changeError',
    ]),
    ...mapActions(useCFTurnstileStore, [
      'setDisableButton',
    ]),
    login() {
      this.$refs.formB.validate().then((valid) =>{
        if(valid.valid){
          var email = this.username
          var password = this.password
          var twoFactorCode = this.twoFactor
          var token = this.turnstileToken
          this.loginUser({ email, password, twoFactorCode, token }).then((response) => {
            console.log(response)
            this.resetTurnstile()
            if (this.termsRequired) {
              this.$router.push({
                name: 'agreeToTerms',
              })
              return
            }
            if (this.passwordShowTwoFactor) {
              this.$router.push({
                name: 'twoFactorOptions',
              })
              return
            }
            if (response.session_active === false) {
              this.username = ''
              this.password = ''
              this.twoFactor = ''
            }
            else if (this.passwordLoggedIn) {
              if (this.miniView) {
                this.postLoginMessage('success')
                return
              }
              //iframe login
              if (this.passwordRedirect == 'false') {
                this.postLoginMessage('success')
                return
              }
              if (response.redirect) {
                window.top.location.href = response.url
              } else {
                this.setUser({ url: response.url })
              }
            }
            if (this.passwordError) {
              this.setDisableButton(true)
              if (this.passwordErrors == 'check_email_and_password') {
                this.changeError('password_login.submit_error')
                this.showError(true)
              } else {
                if (this.$te('password_login.' + this.passwordErrors)) {
                  this.changeError('password_login.' + this.passwordErrors)
                  this.showError(true)
                } else {
                  this.changeError('repeating_errors.' + this.passwordErrors)
                  this.showError(true)
                }
              }
            }
          })
        }
      })
    },

    sendTwoFactor() {
      var twoFactorCode = this.twoFactor
      this.sendTwoFactor({ twoFactorCode }).then((response) => {
        if (this.termsRequired) {
          this.$router.push({
            name: 'agreeToTerms',
          })
        }
        if (this.twoFactorSuccess && !this.termsRequired) {
          if (this.miniView) {
            this.postLoginMessage('success')
            return
          }
          if (response.redirect) {
            window.top.location.href = response.url
          } else {
            this.setUser({ url: response.url })
          }
        } else if (this.twoFactorError && this.twoFactorErrors !== undefined) {
          this.changeError('password_login.' + this.twoFactorErrors)
          this.showError(true)
        } else if (this.twoFactorError) {
          this.changeError('password_login.submit_error')
          this.showError(true)
        }
      })
    },
    initError() {
      this.getError
    },
    togglePasswordVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    proceed() {
      this.$refs.formA.validate().then((valid) => {
        if (valid.valid) {
          this.$emit('showFullForm', true)
        }
      })
    },
    passkeyInfo() {
      console.log('redirect to passkey info page')
    },
    toRegistration() {
      this.$refs.formC.validate().then((valid) => {
        if (valid.valid) {
          this.$router.push({
            name: 'setupAccount',
            state: {
              user: { email: this.username },
              allowEdit: true,
            },
          })
        }
      })
    },
  },
}
</script>
