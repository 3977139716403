<template>
  <div class="smart-id mt-7">
    <v-form
      v-if="!mobileCodeSent && userNotFound == false && !mobileLoggedIn"
      ref="form"
      validate-on="submit lazy"
      @submit.prevent="
        () => {
          mobileLoginBusy ? '' : login()
        }
      "
    >
      <div class="pb-1">
        <e-text-field
          v-model="idCode"
          :rules="[rules.required($i18n.t('fields.id-code'))]"
          hide-details="auto"
          size="large"
          :label="$i18n.t('mobile_id_login.id_code')"
          name="id-code"
        />
      </div>
      <div class="phone-container pb-4">
        <div class="pt-4 mr-2 phone-container__code">
          <e-select
            v-model="code"
            :rules="[rules.required()]"
            hide-details="auto"
            size="large"
            class="body-2"
            :value="code"
            :label="$i18n.t('mobile_id_login.code')"
            :items="mobilePrefixes"
            name="code"
          />
        </div>
        <div class="pt-4 phone-container__number flex">
          <e-text-field
            v-model="number"
            :rules="[rules.required($i18n.t('fields.phone')), rules.numeric($i18n.t('fields.phone'))]"
            hide-details="auto"
            size="large"
            class="display-inline"
            :label="$i18n.t('mobile_id_login.phone_number')"
            name="phone"
          />
        </div>
      </div>
      <e-btn
        class="mt-2"
        :block="true"
        type="submit"
        :loading="mobileLoginBusy"
        variant="primary"
        size="large"
      >
        {{ $t('password_login.login') }}
      </e-btn>
    </v-form>

    <div v-if="mobileCodeSent && !mobileLoggedIn">
      <p class="large-title text-center mb-3">
        {{ mobileCode }}
      </p>
      <p class="subhead-1 text-center mb-0 px-10">
        {{ $t('mobile_id_login.check_code') }}
      </p>
      <div class="text-center mt-6">
        <e-btn
          variant="link"
          size="small"
          @click="cancelPing()"
        >
          {{ $t('mobile_id_login.cancel') }}
        </e-btn>
      </div>
    </div>
    <div
      v-if="mobileLoggedIn"
      class="text-center"
    >
      <e-circular-loading
        class="pt-4"
        value
        :full-page="false"
        :size="42"
      />
      <p class="subhead-1 text-center mb-0 px-10 mt-10">
        {{ $t('smart_id_login.auth_successful') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useMobileStore, useGlobalStore, usePasswordStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ETextField } from '@k12tech/ekool-vue-framework/src/components/ETextField'
import { ESelect } from '@k12tech/ekool-vue-framework/src/components/ESelect'
import { ECircularLoading } from '@k12tech/ekool-vue-framework/src/components/ECircularLoading'
import { validationRules } from '@/validationRules.js'
export default {
  components: {
    EBtn,
    ETextField,
    ESelect,
    ECircularLoading,
  },
  props: {
    register: Boolean,
  },
  data() {
    return {
      codeSent: false,
      userNotFound: false,
      idCode: '',
      number: undefined,
      code: '+372',
      mobilePrefixes: ['+372', '+370'],
      fullNumber: '',
      ping: false,
      accounts: {},
      user: {},
      empty: {
        fullNumber: false,
      },
      rules: validationRules,
    }
  },
  computed: {
    ...mapState(useMobileStore, [
      'mobileLoginBusy',
      'mobileError',
      'mobileLoggedIn',
      'mobileSuccess',
      'mobileErrors',
      'mobileCodeSent',
      'mobileCode',
      'mobileAccounts',
      'mobileUser',
      'mobilePing',
      'mobilePingBusy',
      'mobileUrl',
      'mobileRedirect',
    ]),
  },
  beforeUnmount() {
    this.resetMobileState()
  },
  methods: {
    ...mapActions(useMobileStore, ['loginCardMobile', 'loginPingMobile', 'cancelMobilePing', 'registerCardMobile', 'registerPingMobile', 'resetMobileState']),
    ...mapActions(useGlobalStore, ['showError', 'changeError']),
    ...mapActions(usePasswordStore, ['setUser']),
    login() {
      this.$refs.form.validate().then((valid) => {
        if (valid.valid) {
          this.fullNumber = this.code.concat(this.number)
          if (this.register == false) {
            this.loginMobileId()
          } else {
            this.registerMobileId()
          }
        }
      })
    },
    //login
    loginMobileId() {
      var personalCode = this.idCode
      var phoneNumber = this.fullNumber
      this.resetMobileState()
      this.loginCardMobile({ personalCode, phoneNumber }).then(() => {
        //this.loginPingMobileId();
        if (this.mobileCodeSent) {
          this.loginPingMobileId()
        } else if (this.mobileError && this.mobileErrors !== '') {
          this.changeError('mobile_id_login.' + this.mobileErrors)
          this.showError(true)
        } else if (this.mobileError && this.mobileErrors == '') {
          this.changeError('mobile_id_login.' + this.mobileErrors)
          this.showError(true)
        }
      })
    },
    cancelPing() {
      this.cancelMobilePing()
    },
    loginPingMobileId() {
      //if(this.mobilePing == true){
      this.ping = setInterval(() => {
        if (this.mobilePing && !this.mobilePingBusy) {
          this.loginPingMobile()
        } else {
          if (!this.mobilePingBusy) {
            this.cancelPing()
            clearInterval(this.ping)
          }
        }
        if (this.mobileLoggedIn) {
          if (this.mobileRedirect) {
            window.top.location.href = this.mobileUrl
          } else {
            this.setUser({ url: this.mobileUrl })
          }
        }
        if (this.mobileAccounts) {
          this.$router.push({
            name: 'chooseUser',
            state: {
              users: this.mobileAccounts,
            },
          })
        }
        if (this.mobileErrors) {
          if (this.$te('mobile_id_login.' + this.mobileErrors)) {
            this.changeError('mobile_id_login.' + this.mobileErrors)
            this.showError(true)
          } else {
            this.changeError('repeating_errors.' + this.mobileErrors)
            this.showError(true)
          }
          this.cancelPing()
          clearInterval(this.ping)
        }
      }, 3000)
    },
    //registration
    registerMobileId() {
      var personalCode = this.idCode
      var phoneNumber = this.fullNumber
      this.resetMobileState()
      this.registerCardMobile({ personalCode, phoneNumber }).then(() => {
        if (this.mobileCodeSent) {
          this.registerPingMobileId()
        } else if (this.mobileError && this.mobileErrors !== '') {
          this.changeError('mobile_id_login.' + this.mobileErrors)
          this.showError(true)
        } else if (this.mobileError && this.mobileErrors == '') {
          this.changeError('mobile_id_login.' + this.mobileErrors)
          this.showError(true)
        }
      })
    },
    registerPingMobileId() {
      this.ping = setInterval(() => {
        if (this.mobilePing && !this.mobilePingBusy) {
          this.registerPingMobile()
        } else {
          if (!this.mobilePingBusy) {
            this.cancelMobilePing()
            clearInterval(this.ping)
          }
        }
        if (this.mobileError && this.mobileErrors !== '') {
          if (this.$te('mobile_id_login.' + this.mobileErrors)) {
            this.changeError('mobile_id_login.' + this.mobileErrors)
            this.showError(true)
          } else {
            this.changeError('repeating_errors.' + this.mobileErrors)
            this.showError(true)
          }
          this.cancelMobilePing()
          clearInterval(this.ping)
        }
        if (this.mobileUser) {
          this.$router.push({
            name: 'setupAccount',
            state: {
              user: JSON.parse(JSON.stringify(this.mobileUser)),
            },
          })
        }
      }, 3000)
    },
    //end of registration
    registerUser() {
      this.userNotFound = false
      this.$router.push({
        name: 'setupAccount',
        state: {
          user: this.user,
        },
      })
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
