<template>
  <div class="view-content__wrapper">
    <div class="view-content view-content--wide d-block d-sm-flex">
      <div class="verify-data mx-auto">
        <div class="d-flex justify-center align-center">
          <div class="logo-container mr-2">
            <img
              src="../assets/ekool-logo.svg"
              alt="eKool logo"
            >
            <img
              class="logo-container__small-logo"
              src="../assets/shape.svg"
              alt="attention logo"
            >
          </div>
          <div class="arrow-icons">
            <v-icon
              size="20"
              class="arrow-icons__arrow-left"
            >
              {{ $vuetify.theme.global.name == 'dark' ? 'icon-arrow-left text-grayscale600' : 'icon-arrow-left text-grayscale400' }}
            </v-icon>
            <v-icon
              size="20"
              class="arrow-icons__arrow-right"
            >
              {{ $vuetify.theme.global.name == 'dark' ? 'icon-arrow-right text-grayscale600' : 'icon-arrow-right text-grayscale400' }}
            </v-icon>
          </div>
          <div class="logo-container">
            <img
              style="width: 84px"
              src="../assets/identityIllustration.png"
              alt="identity illustration"
            >
          </div>
        </div>
        <div class="verify-data__header">
          <h1
            class="headline-1 text-graybase mb-1 text-center"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('verify_data.title') }}
          </h1>
          <p
            class="body-3 text-center mb-0"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
          >
            {{ $t('verify_data.description') }}
          </p>
        </div>
        <div class="verify-data__boxes">
          <div class="verify-data__body">
            <span
              class="subhead-2 mb-3"
              :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
            >
              {{ $t('verify_data.body_title_1') }}
            </span>
            <div class="verify-data__box_items">
              <div class="display-flex">
                <i18n-t
                  keypath="verify_data.body_item_1_1"
                  tag="span"
                  class="body-3"
                  :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
                >
                  <template #link>
                    <a
                      :href="computedLink"
                      class="body-3"
                      target="_blank"
                      :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
                    >
                      {{ $t('verify_data.body_item_1_link') }}</a>
                  </template>
                </i18n-t>
              </div>
              <span
                class="body-3"
                :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
              >
                {{ $t('verify_data.body_item_2') }}
              </span>
            </div>
          </div>
          <div class="verify-data__body">
            <span
              class="subhead-2 mb-3"
              :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
            >
              {{ $t('verify_data.body_title_2') }}
            </span>
            <div class="verify-data__box_items">
              <div
                class="body-3"
                :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
                v-html="$t('verify_data.body_item_3')"
              />
              <div
                class="body-3"
                :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
                v-html="$t('verify_data.body_item_4')"
              />
            </div>
          </div>
        </div>
        <div class="verify-data__notes">
          <img
            :src="require(`../assets/info.svg`)"
            alt="info logo"
          >
          <span
            class="verify-data__notes_description"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
          >
            {{ $t('verify_data.note') }}
          </span>
        </div>
        <div class="verify-data__footer">
          <e-btn
            variant="primary"
            :block="true"
            size="large"
            @click="goToProfile()"
          >
            {{ $t('verify_data.update_profile') }}
          </e-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useVerifyDataStore, useLayoutStore, usePasswordStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      bodyItems: [
        { icon: 'icon-letter', text: 'verify_data.body_item_1' },
        { img: 'key-icon', text: 'verify_data.body_item_2' },
        { icon: 'icon-bell', text: 'verify_data.body_item_3' },
        { icon: 'icon-paper-pages', text: 'verify_data.body_item_4' },
      ],
      logoutTimeout: null,
      prevShowIlustration: false,
    }
  },
  computed: {
    ...mapState(useVerifyDataStore, ['getRedirectBusy', 'getRedirectSuccess']),
    ...mapState(useLayoutStore, ['showIlustration']),
    computedLink() {
      return this.$i18n.locale == 'en'
        ? 'https://help.ekool.eu/en_US/two-factor-authentication-2fa'
        : 'https://help.ekool.eu/et_ET/kaheastmeline-autentimine-2fa'
    },
  },
  mounted() {
    this.logout()
    this.prevShowIlustration = this.showIlustration
    this.setShowIlustration(false) //hide ilustration
  },
  beforeUnmount() {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout)
    }
    this.prevShowIlustration ? this.setShowIlustration(true) : null //show ilustration
  },
  methods: {
    ...mapActions(useVerifyDataStore, ['getRedirect' ]),
    ...mapActions(useLayoutStore, ['setShowIlustration']),
    ...mapActions(usePasswordStore, ['setUser']),
    goToProfile() {
      const target = 'profile'
      this.getRedirect(target).then((response) => {
        if (this.getRedirectSuccess) {
          if (response.url) {
            window.top.location.href = response.url
          } else {
            this.setUser({ url: response.url })
          }
        }
      })
    },
    logout() {
      //logout after 5 minutes
      this.logoutTimeout = setTimeout(() => {
        window.top.location.href = getEnv('VUE_APP_FRONTEND_API_URL') + '/logout'
      }, 300000)
    },
  },
}
</script>
