import { defineStore } from 'pinia'
import * as api from '../api'

export const useForgotPasswordStore = defineStore('forgotPassword', {
  state: () => ({
    forgotBusy: false,
    forgotError: false,
    forgotSuccess: false,
    forgotErrors: undefined,
  }),

  actions: {
    async forgotPassword({ username, token }) {
      this.forgotBusy = true
      this.forgotError = false
      this.forgotSuccess = false
      this.forgotErrors = undefined

      try {
        const response = await api.forgotRequest(username, token)
        this.forgotBusy = false
        this.forgotSuccess = response.success
        return response
      } catch (error) {
        this.forgotBusy = false
        this.forgotError = true
        this.forgotErrors = error.errors
        return error
      }
    },

    resetForgotState() {
      this.forgotBusy = false
      this.forgotError = false
      this.forgotSuccess = false
      this.forgotErrors = undefined
    },
  },
})