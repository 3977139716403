import { defineStore } from 'pinia';
import { useGlobalStore } from './global';
import * as api from '../api';
import router from '../router';

export const usePasswordStore = defineStore('password', {
  state: () => ({
    passwordLoginBusy: false,
    passwordError: false,
    passwordLoggedIn: false,
    passwordShowTwoFactor: false,
    passwordSuccess: false,
    passwordErrors: false,
    twoFactorBusy: false,
    twoFactorError: false,
    twoFactorErrors: undefined,
    twoFactorSuccess: false,
    twoFactorAvailableMethods: undefined,
    twoFactorRequestBusy: false,
    twoFactorRequestSuccess: false,
    twoFactorRequestError: false,
    twoFactorRequestErrors: undefined,
    twoFactorRequestTimeout: 0,
    twoFactorRequestEmail: undefined,
    twoFactorRequestPhoneNumber: undefined,
    passwordRedirect: true,
    termsRequired: false,
    rememberRequestRequired: false,
    twoFactorRememberBusy: false,
    twoFactorRememberSuccess: false,
    twoFactorRememberError: false,
    twoFactorRememberErrors: undefined,
    getTwoFactorMethodsBusy: false,
    getTwoFactorMethodsSuccess: false,
    getTwoFactorMethodsErrors: undefined,
    pingTwoFactorBusy: false,
    pingTwoFactorSuccess: false,
    pingTwoFactorErrors: undefined,
    pingTwoFactorPending: true,
    twoFactorEmailVerifyBusy: false,
    twoFactorEmailVerifySuccess: false,
    twoFactorEmailVerifyErrors: undefined,
  }),

  actions: {
    // Login User
    async loginUser({ email, password, twoFactorCode, token }) {
      this.passwordLoginBusy = true;
      try {
        const response = await api.loginUser(email, password, twoFactorCode, token);
        this.loginUserSuccess(response);
        return response;
      } catch (error) {
        this.loginUserFail(error);
        return error;
      }
    },

    loginUserSuccess(response) {
      this.passwordLoggedIn = response.success;
      this.passwordShowTwoFactor = response.two_factor_required;
      this.twoFactorAvailableMethods = response.available_methods;
      this.passwordRedirect = response.redirect;
      this.termsRequired = response.terms_acception_required;
      const globalStore = useGlobalStore();
      
      if (
        !this.passwordShowTwoFactor &&
        this.passwordLoggedIn &&
        !this.termsRequired
      ) {
        if (globalStore.miniView) {
          globalStore.miniViewFunction('success');
          return
        } else if (response.redirect === 'false') {
          globalStore.miniViewFunction('success');
          return
        }
      }
    },

    loginUserFail(error) {
      this.passwordLoginBusy = false;
      this.passwordError = true;
      this.passwordErrors = error.errors;
      if (error.session_active === false) {
        this.passwordLoggedIn = false;
        this.passwordShowTwoFactor = false;
      }
    },

    // Send Two Factor
    async sendTwoFactor({ twoFactorCode, method }) {
      this.twoFactorBusy = true;
      try {
        const response = await api.twoFactor(twoFactorCode, method);
        this.sendTwoFactorSuccess(response);
        return response;
      } catch (error) {
        this.sendTwoFactorFail(error);
        return error;
      }
    },

    sendTwoFactorSuccess(response) {
      this.termsRequired = response.terms_acception_required;
      this.rememberRequestRequired = response.remember_request_required;
      if (response.success && response.remember_request_required) {
        this.twoFactorBusy = false;
      } else if (response.success) {
        this.twoFactorSuccess = true;
      } else {
        this.twoFactorSuccess = false;
        this.twoFactorBusy = false;
      }
    },

    sendTwoFactorFail(error) {
      this.twoFactorBusy = false;
      if (!error.success && error.errors) {
        this.twoFactorError = true;
        this.twoFactorErrors = error.errors;
      } else {
        this.twoFactorError = true;
      }
    },

    // Two Factor Request
    async twoFactorRequest({ method }) {
      this.twoFactorRequestBusy = true;
      try {
        const response = await api.twoFactorRequest(method);
        this.twoFactorRequestSuccess(response);
        return response;
      } catch (error) {
        this.twoFactorRequestFail(error);
        return error;
      }
    },

    twoFactorRequestSuccess(response) {
      this.twoFactorRequestBusy = true;
      if (response.success) {
        this.twoFactorRequestSuccess = true;
      } else {
        this.twoFactorRequestError = true;
      }
      if (response.timeout) {
        this.twoFactorRequestTimeout = response.timeout;
      }
      this.twoFactorRequestEmail = response.email || undefined;
      this.twoFactorRequestPhoneNumber = response.phone_number || undefined;
    },

    twoFactorRequestFail(error) {
      this.twoFactorRequestBusy = true;
      this.twoFactorRequestError = true;
      if (error.errors) {
        this.twoFactorRequestErrors = error.errors;
      }
      if (error.timeout) {
        this.twoFactorRequestTimeout = error.timeout;
      }
    },

    // Two Factor Remember
    async twoFactorRemember({ remember }) {
      this.twoFactorRememberBusy = true;
      try {
        const response = await api.twoFactorRemember(remember);
        this.twoFactorRememberSuccess(response);
        return response;
      } catch (error) {
        this.twoFactorRememberFail(error);
        return error;
      }
    },

    twoFactorRememberSuccess(response) {
      this.twoFactorRememberBusy = false;
      this.termsRequired = response.terms_acception_required;
      if (response.success) {
        this.twoFactorRememberSuccess = true;
      }
    },

    twoFactorRememberFail(error) {
      this.twoFactorRememberBusy = false;
      this.twoFactorRememberError = true;
      if (error.errors) {
        this.twoFactorRememberErrors = error.errors;
      }
    },

    // Get Two Factor Methods
    async getTwoFactorMethods() {
      this.getTwoFactorMethodsBusy = true;
      try {
        const response = await api.getTwoFactorMethods();
        this.getTwoFactorMethodsSuccess(response);
        return response;
      } catch (error) {
        this.getTwoFactorMethodsFail(error);
        return error;
      }
    },

    getTwoFactorMethodsSuccess(response) {
      this.getTwoFactorMethodsBusy = false;
      this.getTwoFactorMethodsSuccess = response.success;
      this.twoFactorAvailableMethods = response.methods;
    },

    getTwoFactorMethodsFail(error) {
      this.getTwoFactorMethodsSuccess = error.success;
      this.getTwoFactorMethodsErrors = error.error;
    },

    // Ping Two Factor
    async pingTwoFactor({ method }) {
      this.pingTwoFactorBusy = true;
      try {
        const response = await api.pingTwoFactor(method);
        this.pingTwoFactorSuccessAction(response);
        return response;
      } catch (error) {
        this.pingTwoFactorFail(error);
        return error;
      }
    },

    pingTwoFactorSuccessAction(response) {
      this.pingTwoFactorBusy = false;
      this.pingTwoFactorPending = false;
      this.pingTwoFactorErrors = undefined;
      this.termsRequired = response.terms_acception_required;
      this.rememberRequestRequired = response.remember_request_required;
      this.pingTwoFactorSuccess = response.success;
    },

    pingTwoFactorFail(error) {
      this.pingTwoFactorBusy = false;
      this.pingTwoFactorSuccess = error.success;
      this.pingTwoFactorPending = error.pending;
      this.pingTwoFactorErrors = error.error;
    },

    // Two Factor Email Verify
    async twoFactorEmailVerify(hash) {
      this.twoFactorEmailVerifyBusy = true;
      try {
        const response = await api.twoFactorEmailVerify(hash);
        this.twoFactorEmailVerifySuccess(response);
        return response;
      } catch (error) {
        this.twoFactorEmailVerifyFail(error);
        return error;
      }
    },

    twoFactorEmailVerifySuccess(response) {
      this.twoFactorEmailVerifyBusy = false;
      this.twoFactorEmailVerifySuccess = response.success;
    },

    twoFactorEmailVerifyFail(error) {
      this.twoFactorEmailVerifyBusy = false;
      this.twoFactorEmailVerifySuccess = error.success;
      this.twoFactorEmailVerifyErrors = error.error;
    },

    // Set User
    async setUser({ url = undefined, caller } = {}) {
      try {
        const response = await api.setUser(url, caller);
        this.setUserSuccess(response);
        return response;
      } catch (error) {
        this.setUserFail(error);
        return error;
      }
    },

    setUserSuccess(response) {
      if (response.success && response.redirect) {
        window.top.location.href = response.redirect;
        return;
      }
      if (Object.prototype.hasOwnProperty.call(response, 'verified') && !response.verified) {
        router.push({ name: 'notificationVerification' });
      }
    },

    setUserFail(error) {
      if (error.errors) {
        router.push({ name: 'accessDenied', query: { type: error.errors } });
      } else {
        router.push({ name: 'accessDenied' });
      }
    },

    // Set Caller
    async setCaller({ caller }) {
      try {
        return await api.setCaller(caller);
      } catch (error) {
        return error;
      }
    },

    // Init Session
    async initSession() {
      try {
        return await api.initSession();
      } catch (error) {
        return error;
      }
    },

    resetTwoFactorError() {
      this.twoFactorError = false
      this.twoFactorErrors = undefined
    }
  },
});