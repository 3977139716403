import { defineStore } from 'pinia'
import * as api from '../api'

export const useEmailValidationStore = defineStore('emailValidation', {
  state: () => ({
    getEmailVerifyBusy: false,
    getEmailVerifySuccess: false,
    emailVerifyStatus: undefined,
    getEmailUpdateBusy: false,
    getEmailUpdateSuccess: false,
    emailUpdateStatus: undefined,
  }),

  actions: {
    async getEmailVerifyStatus({ hash }) {
      this.getEmailVerifyBusy = true
      this.getEmailVerifySuccess = false
      this.emailVerifyStatus = undefined

      try {
        await api.emailVerifyLink(hash)
        this.getEmailVerifySuccess = true
        this.emailVerifyStatus = true
      } catch (error) {
        this.emailVerifyStatus = false
        return error
      } finally {
        this.getEmailVerifyBusy = false
      }
    },

    async getEmailUpdateStatus({ hash }) {
      this.getEmailUpdateBusy = true
      this.getEmailUpdateSuccess = false
      this.emailUpdateStatus = undefined

      try {
        await api.emailUpdateLink(hash)
        this.getEmailUpdateSuccess = true
        this.emailUpdateStatus = true
      } catch (error) {
        this.emailUpdateStatus = false
        return error
      } finally {
        this.getEmailUpdateBusy = false
      }
    },
  },
})