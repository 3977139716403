// ALL Vue Resource CONFIGURATION

import axios from '@/plugins/axios'
import { getEnv } from '../env'
import { max } from 'date-fns';

export function loginUser(email, password, twoFactorCode, token) {
  var options = {
    email,
    password,
    two_factor_code: twoFactorCode,
    token,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('login', params, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function registerUser(
  firstName,
  lastName,
  idCode,
  issuer,
  dateOfBirth,
  gender,
  email,
  emailConfirmation,
  password,
  passwordConfirmation,
  wishPartnerAds,
  countryCode,
  phoneNumber,
  country,
  county,
  city,
  street,
  postalIndex,
  thirdPartyHash,
  inviteType,
  inviteHash
) {
  var options = {
    firstName,
    lastName,
    idCode,
    issuer,
    dateOfBirth,
    gender,
    email,
    email_confirmation: emailConfirmation,
    password,
    password_confirmation: passwordConfirmation,
    wish_partner_ads: wishPartnerAds,
    ...(countryCode && countryCode != '' && { country_code: countryCode }), //send only if not empty string
    ...(phoneNumber && phoneNumber != '' && { phone_number: phoneNumber }), //send only if not undefined and !== ''
    country,
    county,
    city,
    street,
    postal_index: postalIndex,
    ...(thirdPartyHash ? { third_party_hash: thirdPartyHash } : {}),
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('create-account', params, {
      params: {
        inviteType,
        inviteHash,
      },
      emulateJSON: true,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function forgotRequest(username, token) {
  var options = {
    username,
    token,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('password/reset/request', params, {
      emulateJSON: true,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getReset() {
  return axios
    .get('password/reset/{token}', {
      emulateJSON: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function validateToken(token, type) {
  var options = {
    token,
    type,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('password/reset/validate', params, {
      emulateJSON: true,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function resetPassword(token, username, password, passwordConfirmation) {
  var options = {
    token,
    username,
    password,
    password_confirmation: passwordConfirmation,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('password/reset', params, {
      emulateJSON: true,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//mykoob internal password change
export function changePassword(token, username, oldPassword, password, passwordConfirmation) {
  var options = {
    token,
    username,
    old_password: oldPassword,
    password,
    password_confirmation: passwordConfirmation,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('password/change', params, {
      emulateJSON: true,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function adminChangePassword(token, username, password, passwordConfirmation) {
  var options = {
    token,
    username,
    password,
    password_confirmation: passwordConfirmation,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('password/admin-change', params, {
      emulateJSON: true,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function loginIdCard() {
  return axios
    .post(getEnv('VUE_APP_FRONTEND_IDCARD_URL') + 'id-card', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function loginCardSmart(country, personalCode) {
  var options = {
    country,
    personalCode,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('smart-id', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}
export function loginCardSmartCheck() {
  var options = {}

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('smart-id/check-status', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function loginCardMobile(personalCode, phoneNumber) {
  var options = {
    personalCode,
    phoneNumber,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('mobile-id', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}
export function loginCardMobileCheck() {
  var options = {}

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('mobile-id/check-status', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getCountry() {
  return axios
    .get('https://get.geojs.io/v1/ip/country.json', {
      emulateJSON: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function selectUser(id) {
  var options = {
    id,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('select-user', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function chooseUser() {
  var options = {}

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('har-id/allowed-users', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//start of register

export function registerCardMobile(personalCode, phoneNumber) {
  var options = {
    personalCode,
    phoneNumber,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('register/mobile-id', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}
export function registerCardMobileCheck() {
  return axios
    .post('register/mobile-id/check-status', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function registerCardSmart(country, personalCode) {
  var options = {
    country,
    personalCode,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('register/smart-id', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}
export function registerCardSmartCheck() {
  var options = {}

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('register/smart-id/check-status', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function registerIdCard() {
  return axios
    .post(getEnv('VUE_APP_FRONTEND_IDCARD_URL') + 'register/id-card', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getHarData() {
  return axios
    .post('register/har-id/user', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function registerEmailValidate(email) {
  var options = { email }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('register/email/validate', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function registerPhoneVerifyRequest(countryCode, phoneNumber, token) {
  var options = {
    country_code: countryCode,
    phone_number: phoneNumber,
    token,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('register/phone/verify/request', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function registerPhoneVerifyCode(countryCode, phoneNumber, code) {
  var options = {
    country_code: countryCode,
    phone_number: phoneNumber,
    code,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('register/phone/verify/code', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}
//end of register

export function createAccSameId(hash) {
  var options = {
    hash,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('create-account/proceed', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//school-register

export function schoolRegister(
  schoolName,
  registrationNumber,
  type,
  street,
  city,
  country,
  firstName,
  lastName,
  email,
  number
) {
  var options = {
    schoolName,
    registrationNumber,
    type,
    street,
    city,
    country,
    firstName,
    lastName,
    email,
    number,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('school-register', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//two-factor
export function twoFactor(twoFactorCode, method) {
  var options = {
    two_factor_code: twoFactorCode,
    method,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('two-factor', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getXidData() {
  return axios
    .post('register/x-id/user', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getThirdPartyData() {
  return axios
    .get('oauth/session', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function acceptThirdPartyRequest(state, clientId) {
  let options = {
    state,
    client_id: clientId,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('oauth/authorize', params, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function denyThirdPartyRequest(state, clientId) {
  let options = {
    state,
    client_id: clientId,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .delete('oauth/authorize', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getRoles() {
  return axios
    .post('oauth/roles', {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//terms
export function getServiceTerms() {
  return axios
    .get('terms/service-terms', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getCookiesPrivacyPolicyTerms() {
  return axios
    .get('terms/cookies-privacy-policy', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getPrivacyPolicyTerms() {
  return axios
    .get('terms/privacy-policy', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//dowload terms
export function downloadServiceTerms() {
  return axios
    .get('terms/service-terms/download', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function downloadCookiesPrivacyPolicyTerms() {
  return axios
    .get('terms/cookies-privacy-policy/download', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function downloadPrivacyPolicyTerms() {
  return axios
    .get('terms/privacy-policy/download', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function acceptTerms(termsAccepted) {
  var options = {
    terms_accepted: termsAccepted,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('terms/accept', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getServiceTermsRead() {
  return axios
    .get('terms/service-terms/read', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//two-factor request
export function twoFactorRequest(method) {
  var options = {
    method,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('two-factor/request', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//two-factor remeber device
export function twoFactorRemember(remember) {
  var options = {
    remember,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('two-factor/remember', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//verify user data
export function getRedirect(target) {
  let options = {
    target,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }
  
  return axios
    .post('goto', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//google registration
export function getGoogleRegisteringUser() {
  return axios
    .get('oauth/google/register/user', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//microsoft registration
export function getMicrosoftRegisteringUser() {
  return axios
    .get('oauth/microsoft/register/user', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//eliis registration
export function getEliisRegisteringUser() {
  return axios
    .get('oauth/eliis/register/user', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//get two factor methods
export function getTwoFactorMethods() {
  return axios
    .get('two-factor/methods', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//third party login (after declining linking)
export function thirdPartyLogin(method, hash) {
  let options = {
    hash,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post(`api/login/${method}`, params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//get teacher invite data
export function getTeacherInviteData(hash) {
  return axios
    .get(`register/teacher-invite-data`, {
      params: {
        hash,
      },
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//get passkeys login options
export function getPasskeysLoginOptions() {
  return axios
    .post('webauthn/login/options', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => {
        return response.data
      },
      (error) => {
        return error.response.data
      }
    )
}

//get passkeys verification status https://login.ekool.dev
export function getPasskeysVerification(passkeysAuthorizationResp) {
  let options = {
    passkeysAuthorizationResp,
  }

  return axios
    .post('webauthn/login/verify', options, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function getTeacherInviteInfo(hash) {
  return axios
    .get(`users/teacher/invite/info`, {
      params: {
        hash,
      },
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

export function sendTeacherInviteStatus(hash, status) {
  let options = {
    hash,
    status,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post(`users/teacher/invite`, params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//email validation via link
export function emailUpdateLink(hash) {
  let options = {
    hash,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post(`api/email/confirm`, params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}
export function emailVerifyLink(hash) {
  let options = {
    hash,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post(`api/email/verify/confirm`, params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//ping two-factor
export function pingTwoFactor(method) {
  var options = {
    method,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('two-factor', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}
//two-factor email verify
export function twoFactorEmailVerify(hash) {
  var options = {
    hash,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('two-factor/email/verify', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//set-user
export function setUser(url = 'set-user', caller, redirect = false) {
  // Prepare the params object conditionally
  const params = {
    caller,
    ...(url === 'set-user' && { redirect }), // Add `redirect` only if `url` is default
  }

  return axios
    .get(url, {
      params,
      maxRedirects: 0,
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
      (error) => Promise.reject(error.response.data)
    )
}

//set-caller
export function setCaller(caller) {
  var options = {
    caller,
  }

  // Convert the options object into URLSearchParams
  const params = new URLSearchParams();
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      params.append(key, options[key]);
    }
  }

  return axios
    .post('set-caller', params, {
      emulateJSON: true,
      credentials: true,
    })
    .then((response) => Promise.resolve(response.data))
}

//init-session
export function initSession() {
  return axios
    .post('init-session', {
      emulateJSON: true,
      credentials: true,
    })
    .then(
      (response) => Promise.resolve(response.data),
    )
}