import { createI18n } from 'vue-i18n'
import { getEnv } from '@/env'

const Locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

const messages = {}

Locales.keys().forEach((lang) => {
  // new babel translator fix
  const langEntries = Locales(lang)
  // converts file name into locale key (lv_LV and so on)
  // could not figure out how to do from-to search that works on safari,if you can remove slice
  const langName = lang.match(/(.*)(?=\.json)/g)[0].slice(2)
  messages[langName] = {
    ...langEntries,
  }
})

export default createI18n({
  allowComposition: true, // you need to specify that!
  locale: getEnv('VUE_APP_FRONTEND_I18N_LOCALE') || 'en',
  fallbackLocale: getEnv('VUE_APP_FRONTEND_I18N_FALLBACK_LOCALE') || 'en',
  silentFallbackWarn: getEnv('VUE_APP_FRONTEND_ENVIRONMENT') !== 'development',
  messages,
})
