<template>
  <div class="terms-view">
    <e-dialog
      v-model="dialog"
      persistent
      max-width="484"
      hide-default-header
    >
      <e-dialog-body>
        <e-dialog-content>
          <div class="d-flex flex-column align-center">
            <div class="w-100 pa-8">
              <div class="d-flex mb-8 justify-center align-center">
                <div class="logo-container mr-2">
                  <img
                    src="../assets/ekool-logo.svg"
                    alt="eKool logo"
                  >
                  <img
                    class="logo-container__small-logo"
                    src="../assets/arrows-icon.svg"
                    alt="arrows icon"
                  >
                </div>
              </div>
              <div class="d-flex flex-column align-center">
                <span class="headline-3 mb-1 text-center">{{
                  $t('third_party_login.register_title', { method: methodNameCapitalize })
                }}</span>
                <span
                  class="body-2 text-center"
                  :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
                >
                  {{ $t('third_party_login.register_description', { method: methodNameCapitalize }) }}</span>
              </div>
            </div>
            <e-dialog-actions>
              <e-btn
                :block="false"
                variant="tertiary"
                @click="cancel"
              >
                {{ $t('terms.cancel') }}
              </e-btn>
              <e-btn
                :block="false"
                variant="primary"
                @click="getRegisteringUserData"
              >
                {{ $t('third_party_login.register_account') }}
              </e-btn>
            </e-dialog-actions>
          </div>
        </e-dialog-content>
      </e-dialog-body>
    </e-dialog>
  </div>
</template>
<script>
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { EDialog, EDialogBody, EDialogContent, EDialogActions } from "@k12tech/ekool-vue-framework/src/components/EDialog"
import { useToast } from "@k12tech/ekool-vue-framework/src/plugins/toast";
export default {
  components: {
    EBtn,
    EDialog,
    EDialogBody,
    EDialogContent,
    EDialogActions,
  },
  props: {
    method: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { toast } = useToast()
    return { toast }
  },
  data() {
    return {
      dialog: true,
    }
  },
  computed: {
    methodNameCapitalize() {
      return this.method.charAt(0).toUpperCase() + this.method.slice(1)
    },
  },
  mounted() {
    this.dialog = true
  },
  methods: {
    close() {
      this.dialog = false
    },
    cancel() {
      this.toast(this.$i18n.t(`messages.check_login_details`))
      this.close()
    },
    getRegisteringUserData() {
      this.$router.push({
        name: 'validation',
        query: {
          type: `${this.method}-register`,
        },
      })
      this.close()
    },
  },
}
</script>
