<template>
  <div class="view-content__wrapper">
    <div class="view-content view-content--wide d-block d-sm-flex">
      <div class="verify-data mx-auto">
        <div
          v-if="emailUpdated"
          class="invite-teacher__header"
        >
          <img
            v-if="$vuetify.theme.global.name == 'dark'"
            class="invite-teacher__logo"
            src="../assets/email_updated_dark.svg"
            alt="eKool logo"
          >
          <img
            v-else
            class="invite-teacher__logo"
            src="../assets/email_updated_light.svg"
            alt="eKool logo"
          >
          <h1
            class="headline-1 text-graybase mb-1 text-center"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('email_updated_title') }}✅
          </h1>
          <p
            class="body-3 text-center mb-0"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
          >
            {{ $t('email_updated_desc') }}
          </p>
        </div>
        <div
          v-else
          class="invite-teacher__header"
        >
          <img
            v-if="$vuetify.theme.global.name == 'dark'"
            class="invite-teacher__logo"
            src="../assets/email_not_updated_dark.svg"
            alt="eKool logo"
          >
          <img
            v-else
            class="invite-teacher__logo"
            src="../assets/email_not_updated_light.svg"
            alt="eKool logo"
          >
          <h1
            class="headline-1 text-graybase mb-1 text-center"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('email_not_updated_title') }}❌
          </h1>
          <p
            class="body-3 text-center mb-0"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
          >
            {{ $t('email_not_updated_desc_1') }}
          </p>
          <div
            class="body-3 text-center mb-0"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
            v-html="$t('email_not_updated_desc_2')"
          />
        </div>
        <div class="invite-teacher__footer">
          <e-btn
            variant="primary"
            :block="true"
            size="large"
            @click="goToEkool()"
          >
            {{ $t('go_to_ekool') }}
          </e-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnv } from '../env'
import { mapState, mapActions } from 'pinia'
import { useEmailValidationStore } from '@/store'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      emailUpdated: false,
    }
  },
  computed: {
    ...mapState(useEmailValidationStore, ['getEmailUpdateBusy', 'getEmailUpdateSuccess', 'emailUpdateStatus']),
  },
  mounted() {
    this.getEmailUpdateStatus({ hash: this.$route.query.hash }).then(() => {
      if (this.emailUpdateStatus) {
        this.emailUpdated = true
      }
    })
  },
  methods: {
    ...mapActions(useEmailValidationStore, ['getEmailUpdateStatus']),
    goToEkool() {
      window.top.location.href = getEnv('VUE_APP_FRONTEND_APP_URL')
    },
  },
}
</script>
