import { defineStore } from 'pinia'
import * as api from '../api'

export const useXidStore = defineStore('xid', {
  state: () => ({
    xidValidationBusy: false,
    xidValidationErrors: undefined,
    xidValid: false,
  }),

  actions: {
    xidValidationSuccess(response) {
      this.xidValidationBusy = false
      if (response.success && response.user) {
        this.xidValid = true
      } else {
        this.xidValid = false
      }
    },

    xidValidationFail(error) {
      this.xidValidationBusy = false
      this.xidValid = false
      if (error.errors) {
        this.xidValidationErrors = error.errors
      }
    },

    async getXidValidation() {
      this.xidValidationBusy = true
      try {
        const response = await api.getXidData()
        this.xidValidationSuccess(response)
        return response
      } catch (error) {
        this.xidValidationFail(error)
        return error
      }
    },
  },
})