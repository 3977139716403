<template>
  <div class="view-content__wrapper">
    <div class="view-content">
      <h2 class="headline-1 mb-2">
        {{ $t('forgot_password.heading') }}
      </h2>
      <h6 class="subhead-1 mb-6">
        {{ $t('forgot_password.intro') }}
      </h6>
      <Alert v-if="getError" />
      <v-form
        ref="form"
        validate-on="submit lazy"
        @submit.prevent="
          () => {
            forgotBusy ? null : handleForgot()
          }
        "
      >
        <div class="py-4">
          <e-text-field
            v-model="username"
            size="large"
            :type="usernameType"
            :rules="[rules.required($i18n.t('fields.email'))]"
            hide-details="auto"
            name="email"
            :label="$i18n.t('forgot_password.label')"
          />
        </div>
        <e-btn
          class="mt-2"
          :block="true"
          type="submit"
          :loading="forgotBusy"
          :disabled="disableButton"
          variant="primary"
          size="large"
        >
          {{ $t('forgot_password.help_me') }}
        </e-btn>
      </v-form>
      <div class="text-center mt-4">
        <e-btn
          variant="link"
          size="small"
          :block="false"
          @click="redirect()"
        >
          {{ $t('setup_account.back_to_frontpage') }}
        </e-btn>
      </div>
      <div
        v-show="showTurnstileWidget"
        ref="turnstileRef"
        class="turnstile-widget mt-4"
      />
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue'
import { mapState, mapActions, mapGetters } from 'pinia'
import { useForgotPasswordStore, useCFTurnstileStore, useGlobalStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ETextField } from '@k12tech/ekool-vue-framework/src/components/ETextField'
import { validationRules } from '@/validationRules.js'
import { turnstileMixin } from "@/utils/turnstileMixin";
export default {
  name: 'Forgot',
  components: {
    Alert,
    EBtn,
    ETextField,
  },
  mixins: [turnstileMixin],
  data() {
    return {
      username: '',
      usernameType: 'text',
      rules: validationRules,
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    ...mapGetters(useGlobalStore, [
      'getError',
    ]),
    ...mapState(useForgotPasswordStore, [
      'forgotBusy',
      'forgotError',
      'forgotSuccess',
      'forgotErrors',
    ]),
    ...mapState(useCFTurnstileStore, [
      'disableButton',
    ]),
  },
  mounted() {
    if (getEnv('VUE_APP_FRONTEND_PROJECT_NAME') == 'Mykoob') {
      this.usernameType = 'email'
    }
    this.$nextTick(() => {
      if (this.$refs.turnstileRef) {
        this.initializeTurnstile(this.$refs.turnstileRef); // Initialize Turnstile after DOM is updated
      }
    });
  },
  beforeUnmount() {
    this.removeTurnstile()
  },
  methods: {
    ...mapActions(useForgotPasswordStore, [
      'forgotPassword', 'resetForgotState',
    ]),
    ...mapActions(useCFTurnstileStore, [
      'setDisableButton',
    ]),
    ...mapActions(useGlobalStore, ['showError', 'changeError']),
    handleForgot() {
      this.$refs.form.validate().then((valid) => {
        if (valid.valid) {
          this.forgot()
        }
      })
    },
    forgot() {
      var username = this.username
      var token = this.turnstileToken
      this.resetForgotState() //reset state
      this.forgotPassword({ username, token }).then(() => {
        /* eslint-disable no-undef */
        this.resetTurnstile()
        if (this.forgotError) {
          this.setDisableButton(true)
          if (this.forgotErrors !== undefined) {
            if (this.$te('forgot_password.' + this.forgotErrors)) {
              this.changeError('forgot_password.' + this.forgotErrors)
              this.showError(true)
            } else {
              this.changeError('repeating_errors.' + this.forgotErrors)
              this.showError(true)
            }
          } else {
            this.changeError('forgot_password.user_not_found')
            this.showError(true)
          }
        } else if (this.forgotSuccess) {
          this.$router.push({
            name: 'success',
            state: {
              email: this.username,
              type: 'resetRequest',
            },
          })
        }
      })
    },
    redirect() {
      this.resetForgotState()
      this.$router.push('/' + this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
