import i18n from './i18n'

export const validationRules = {
  required: (name) => (value) => {
    return (value !== null && value !== undefined && value !== '') || i18n.global.t('validation.required', {field: name})
  },
  alpha: (value) => /^[a-zA-Z]*$/.test(value) || 'validation.alpha',
  alphaSpaces: (value) => /^[a-zA-Z\s]*$/.test(value) || 'validation.alpha_spaces',
  alphaDash: (value) => /^[a-zA-Z0-9-_]*$/.test(value) || 'validation.alpha_dash',
  regex: (name) => (value, regex) => {
    return regex.test(value) || i18n.global.t('validation.regex', {field: name})
  },
  email: (name) => (value) => { 
    return /.+@.+\..+/.test(value) || i18n.global.t('validation.email', {field: name})
  },
  min: (name) => (value, length) => {
    return value?.length >= length || i18n.global.t('validation.min', {field: name, length: length})
  },
  max: (name) => (value, length) => {
    return value?.length <= length || i18n.global.t('validation.max', {field: name, length: length})
  },
  numeric: (name) => (value) => /^\d+$/.test(value) || i18n.global.t('validation.numeric', {field: name}),
}

validationRules.handleIDCode = (value) => {
  if (value) {
    var idCodeArr = Array.from(value).map(Number)
    if (idCodeArr.length !== 11) {
      return i18n.global.t('validation.handleIDCode')
    }
    var wages1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1]
    var sum1 = 0
    for (var i = 0; i !== 10; i++) {
      sum1 += idCodeArr[i] * wages1[i]
    }
    var module1 = sum1 % 11
    if (module1 !== 10) {
      return module1 === idCodeArr[10] || i18n.global.t('validation.handleIDCode')
    }
    var wages2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3]
    var sum2 = 0
    for (var j = 0; j !== 10; j++) {
      sum2 += idCodeArr[j] * wages2[j]
    }
    var module2 = sum2 % 11
    if (module2 !== 10) {
      return module2 === idCodeArr[10]
    }
    return idCodeArr[10] === 0 || i18n.global.t('validation.handleIDCode')
  }
}

validationRules.checkGender = (value, idCode, issuer) => {
  if (!value) return true
  if (issuer !== 'EE') return true

  const gender = Array.from(idCode)[0] % 2 === 0 ? 1 : 0
  return value == gender || i18n.global.t('validation.checkGender')
}

validationRules.password = (value, target) => {
  return value === target || i18n.global.t('validation.password')
}

validationRules.passwordStrength = (value) => {
  if (!value) return true
  const hasLower = /[a-z]/.test(value)
  const hasUpper = /[A-Z]/.test(value)
  const hasNumber = /\d/.test(value)
  const hasSpecial = /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(value)
  return hasLower + hasUpper + hasNumber + hasSpecial >= 3 || i18n.global.t('validation.passwordStrength')
}

validationRules.dateOfBirth = (value) => {
  if (!value) return true
  const dateFormat = /^\d{1,2}\.\d{1,2}\.\d{4}$/
  if (!dateFormat.test(value)) return i18n.global.t('validation.dateOfBirth')

  const [day, month, year] = value.split('.').map(Number)
  const isValidDate = !isNaN(new Date(year, month - 1, day).getTime())
  return isValidDate || i18n.global.t('validation.dateOfBirth')
}

//check date of birth if valid EE id code
validationRules.checkDate = (value, idCode, issuer) => {
  if (value) {
    if (issuer == 'EE') {
      var idCodeArr = Array.from(idCode).map(Number)
      var year
      if (idCodeArr[0] === 1 || idCodeArr[0] === 2) {
        year = '18'
      } else if (idCodeArr[0] === 3 || idCodeArr[0] === 4) {
        year = '19'
      } else if (idCodeArr[0] === 5 || idCodeArr[0] === 6) {
        year = '20'
      }
      var correctYear =
        idCodeArr[5] +
        '' +
        idCodeArr[6] +
        '.' +
        idCodeArr[3] +
        '' +
        idCodeArr[4] +
        '.' +
        year +
        '' +
        idCodeArr[1] +
        '' +
        idCodeArr[2]
      return value == correctYear || i18n.global.t('validation.checkDate')
    } else {
      return true
    }
  }
}