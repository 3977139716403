import 'vuetify/styles'
import '@/assets/styles.scss'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { config } from '@k12tech/ekool-vue-framework/src/main.js'

const vuetify = createVuetify({
  components,
  directives,
  aliases: {},
  ...config,
})

export default vuetify
