import { defineStore } from 'pinia'
import * as api from '../api'

export const useChangePasswordStore = defineStore('changePassword', {
  state: () => ({
    changePasswordBusy: false,
    changePasswordError: false,
    changePasswordSuccess: false,
    changePasswordErrors: undefined,
  }),

  getters: {
    changePasswordBusy: (state) => state.changePasswordBusy,
    changePasswordError: (state) => state.changePasswordError,
    changePasswordSuccess: (state) => state.changePasswordSuccess,
    changePasswordErrors: (state) => state.changePasswordErrors,
  },

  actions: {
    async changePassword({ token, username, oldPassword, password, passwordConfirmation }) {
      this.changePasswordBusy = true
      this.changePasswordError = false
      this.changePasswordSuccess = false
      this.changePasswordErrors = undefined

      try {
        const response = await api.changePassword(token, username, oldPassword, password, passwordConfirmation)
        if (response.success) {
          this.changePasswordSuccess = true
        }
        return response
      } catch (error) {
        this.changePasswordError = true
        if (error.errors) {
          this.changePasswordErrors = error.errors
        }
        return error
      } finally {
        this.changePasswordBusy = false
      }
    },
  },
})