<template>
  <div class="view-content__wrapper">
    <div class="view-content view-content--turnstile-height mx-auto">
      <div
        v-if="activeItem == 'password' && !showFullPasswordForm"
        class="mb-6 headline-1"
      >
        {{ $t('menu-items.select_login_method') }}
      </div>
      <div
        v-else
        class="mb-6 headline-1"
      >
        {{ $t(`menu-items.login_with_${activeItem}`) }}
      </div>
      <Alert v-if="getError" />
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="activeItem == 'password'"
        >
          <ThirdParty
            v-if="
              thirdPartyAllowedMethods &&
                thirdPartyAllowedMethods.length > 0 &&
                (mobileView ? thirdPartyAllowedForMobile : true) &&
                !showFullPasswordForm
            "
          />
          <Password
            :show-full-password-form="showFullPasswordForm"
            @show-full-form="showFullForm"
          />
        </div>
        <Mobile
          v-else-if="activeItem == 'mobile_id'"
        />
        <Smart
          v-else-if="activeItem == 'smart_id'"
        />
        <Har
          v-else-if="activeItem == 'har_id'"
        />
        <XId
          v-else-if="activeItem == 'x_id'"
        />
        <Card
          v-else-if="activeItem == 'id_card'"
        />
        <EParaksts
          v-else-if="activeItem == 'e_paraksts'"
        />
      </transition>
      <div v-if="activeItem == '' || (activeItem == 'password' && !showFullPasswordForm)">
        <div class="other-methods">
          <span class="caption-1 caption-1-plus text-grayscale500">{{ $t('menu-items.other_methods') }}</span>
          <span
            v-for="loginMethod in otherLoginMethods"
            :key="loginMethod"
            class="caption-1 caption-1-plus primary-text-link cursor-pointer"
            @click="navigate(loginMethod)"
          >
            {{ $t('menu-items.' + loginMethod) }}
          </span>
        </div>
        <div
          v-if="project == 'eKool'"
          class="separator mt-4 mb-8"
        />
        <div
          v-if="
            !miniView && project == 'eKool' && !mobileCodeSent && !smartCodeSent && !smartLoggedIn && !mobileLoggedIn
          "
          class="mt-4 text-center d-block d-sm-flex align-center justify-center"
        >
          <span class="subhead-2 greybase-text mr-2">{{ $t('menu-items.new_to_ekool') }}</span>
          <e-btn
            variant="link"
            size="small"
            :to="'/' + $i18n.locale + '/register'"
            exact
          >
            {{ $t('menu-items.create_account') }}
          </e-btn>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-center mt-12"
      >
        <e-btn
          variant="link"
          size="small"
          @click="backToLogin"
        >
          {{ $t('menu-items.back_to_login') }}
        </e-btn>
      </div>
    </div>
    <ThirdPartyLinkModal
      v-if="showThirdPartyLinkModal"
      :method="$route.query.type"
      :hash="$route.query.hash"
    />
    <ThirdPartyRegisterLinkModal
      v-if="showThirdPartyRegisterLinkModal"
      :method="$route.query.type"
    />
  </div>
</template>

<script>
// @ is an alias to /src

import Password from '@/components/Password.vue'
import Mobile from '@/components/Mobile.vue'
import Smart from '@/components/Smart.vue'
import Har from '@/components/Har.vue'
import Card from '@/components/Card.vue'
import XId from '@/components/XId.vue'
import EParaksts from '@/components/eParaksts.vue'
import ThirdParty from '@/components/ThirdParty.vue'
import ThirdPartyLinkModal from '@/components/ThirdPartyLinkModal.vue'
import ThirdPartyRegisterLinkModal from '@/components/ThirdPartyRegisterLinkModal.vue'
import Alert from '@/components/Alert.vue'

import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'

import { mapState, mapActions, mapGetters } from 'pinia'
import { useMobileStore, useSmartStore, useCFTurnstileStore, useGlobalStore } from '@/store'
import { getEnv } from '../env'
import { useToast } from "@k12tech/ekool-vue-framework/src/plugins/toast"
import { removeRouteStateItem } from "@/utils/globalMethods";
export default {
  name: 'Login',
  components: {
    Password,
    Mobile,
    Smart,
    Har,
    Card,
    Alert,
    XId,
    EParaksts,
    ThirdParty,
    ThirdPartyLinkModal,
    ThirdPartyRegisterLinkModal,
    EBtn,
  },
  setup() {
    const { toast } = useToast()
    return { toast }
  },
  data() {
    return {
      activeItem: 'password',
      project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
      primaryLoginMethods: getEnv('VUE_APP_FRONTEND_PRIMARY_LOGIN_METHODS').split(', '),
      secondaryLoginMethods: getEnv('VUE_APP_FRONTEND_SECONDARY_LOGIN_METHODS').split(', '),
      thirdPartyAllowedMethods: getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS')
        ? getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS').split(', ')
        : null,
      showThirdPartyLinkModal: false,
      thirdPartyAllowedForMobile: getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_FOR_MOBILE'),
      showFullPasswordForm: false,
      showThirdPartyRegisterLinkModal: false,
    }
  },
  computed: {
    ...mapGetters(useGlobalStore, ['getError']),
    ...mapState(useSmartStore, ['smartCodeSent', 'smartLoggedIn']),
    ...mapState(useMobileStore, ['mobileCodeSent', 'mobileLoggedIn']),
    ...mapState(useGlobalStore, ['miniView', 'mobileView']),
    otherLoginMethods() {
      let allMethods = this.primaryLoginMethods.concat(this.secondaryLoginMethods)
      return allMethods.filter((e) => e !== this.activeItem)
    },
  },
  mounted() {
    if (this.$route.name == 'login-mini' || this.$route.name == 'thirdParty') {
      this.setMiniView(true)
      this.setMiniViewFunction(this.postLoginMessage)
    } else if (this.$route.name == 'mobile') {
      this.setMobileView(true)
    }

    if (window.history.state.error) {
      if (this.$i18n.te(window.history.state.error)) {
        this.changeError(window.history.state.error)
        this.showError(true)
      } else {
        this.changeError('repeating_errors.' + window.history.state.error)
        this.showError(true)
      }
    } else if (this.$route.query.error) {
      if (this.$i18n.te(this.$route.query.error)) {
        this.changeError(this.$route.query.error)
        this.showError(true)
      } else {
        this.changeError('repeating_errors.' + this.$route.query.error)
        this.showError(true)
      }
    }

    if (this.$route.name == 'mobile') {
      //remove id-card login method in mobile view
      this.primaryLoginMethods = this.primaryLoginMethods.filter((e) => e !== 'id_card')
    } else {
      //if not mobile view, take login methods from env
      this.primaryLoginMethods = getEnv('VUE_APP_FRONTEND_PRIMARY_LOGIN_METHODS').split(', ')
    }
    //message route query param
    if (this.$route.query.message) {
      if (this.$route.query.type) {
        this.$route.query.message == 'register_account'
          ? (this.showThirdPartyRegisterLinkModal = true)
          : (this.showThirdPartyLinkModal = true)
      } else {
        this.toast(this.$i18n.t(`messages.${this.$route.query.message}`))
      }
    }
  },
  beforeUnmount() {
    removeRouteStateItem(['error'])
  },
  methods: {
    ...mapActions(useGlobalStore, ['showError', 'changeError', 'setMiniView', 'setMobileView', 'setMiniViewFunction']),
    navigate(item) {
      this.activeItem = item
      this.showError(false)
    },
    showFullForm(val) {
      this.showFullPasswordForm = val
    },
    backToLogin() {
      this.activeItem = 'password'
      this.showFullPasswordForm = false
    },
  },
}
</script>
