export const countries = [
  {
      "Name": "Afghanistan",
      "Code": "AF",
      "MobileCode": "+93"
  },
  {
      "Name": "Åland Islands",
      "Code": "AX",
      "MobileCode": "+358-18"
  },
  {
      "Name": "Albania",
      "Code": "AL",
      "MobileCode": "+355"
  },
  {
      "Name": "Algeria",
      "Code": "DZ",
      "MobileCode": "+213"
  },
  {
      "Name": "American Samoa",
      "Code": "AS",
      "MobileCode": "+1-684"
  },
  {
      "Name": "Andorra",
      "Code": "AD",
      "MobileCode": "+376"
  },
  {
      "Name": "Angola",
      "Code": "AO",
      "MobileCode": "+244"
  },
  {
      "Name": "Anguilla",
      "Code": "AI",
      "MobileCode": "+1-264"
  },
  {
      "Name": "Antigua and Barbuda",
      "Code": "AG",
      "MobileCode": "+1-268"
  },
  {
      "Name": "Argentina",
      "Code": "AR",
      "MobileCode": "+54"
  },
  {
      "Name": "Armenia",
      "Code": "AM",
      "MobileCode": "+374"
  },
  {
      "Name": "Aruba",
      "Code": "AW",
      "MobileCode": "+297"
  },
  {
      "Name": "Australia",
      "Code": "AU",
      "MobileCode": "+61"
  },
  {
      "Name": "Austria",
      "Code": "AT",
      "MobileCode": "+43"
  },
  {
      "Name": "Azerbaijan",
      "Code": "AZ",
      "MobileCode": "+994"
  },
  {
      "Name": "Bahamas, The",
      "Code": "BS",
      "MobileCode": "+1-242"
  },
  {
      "Name": "Bahrain",
      "Code": "BH",
      "MobileCode": "+973"
  },
  {
      "Name": "Bangladesh",
      "Code": "BD",
      "MobileCode": "+880"
  },
  {
      "Name": "Barbados",
      "Code": "BB",
      "MobileCode": "+1-246"
  },
  {
      "Name": "Belarus",
      "Code": "BY",
      "MobileCode": "+375"
  },
  {
      "Name": "Belgium",
      "Code": "BE",
      "MobileCode": "+32"
  },
  {
      "Name": "Belize",
      "Code": "BZ",
      "MobileCode": "+501"
  },
  {
      "Name": "Benin",
      "Code": "BJ",
      "MobileCode": "+229"
  },
  {
      "Name": "Bermuda",
      "Code": "BM",
      "MobileCode": "+1-441"
  },
  {
      "Name": "Bhutan",
      "Code": "BT",
      "MobileCode": "+975"
  },
  {
      "Name": "Bolivarian Republic of Venezuela",
      "Code": "VE",
      "MobileCode": "+58"
  },
  {
      "Name": "Bolivia",
      "Code": "BO",
      "MobileCode": "+591"
  },
  {
      "Name": "Bosnia and Herzegovina",
      "Code": "BA",
      "MobileCode": "+387"
  },
  {
      "Name": "Botswana",
      "Code": "BW",
      "MobileCode": "+267"
  },
  {
      "Name": "Brazil",
      "Code": "BR",
      "MobileCode": "+55"
  },
  {
      "Name": "British Indian Ocean Territory",
      "Code": "IO",
      "MobileCode": "+246"
  },
  {
      "Name": "Brunei",
      "Code": "BN",
      "MobileCode": "+673"
  },
  {
      "Name": "Bulgaria",
      "Code": "BG",
      "MobileCode": "+359"
  },
  {
      "Name": "Burkina Faso",
      "Code": "BF",
      "MobileCode": "+226"
  },
  {
      "Name": "Burundi",
      "Code": "BI",
      "MobileCode": "+257"
  },
  {
      "Name": "Cabo Verde",
      "Code": "CV",
      "MobileCode": "+238"
  },
  {
      "Name": "Cambodia",
      "Code": "KH",
      "MobileCode": "+855"
  },
  {
      "Name": "Cameroon",
      "Code": "CM",
      "MobileCode": "+237"
  },
  {
      "Name": "Cayman Islands",
      "Code": "KY",
      "MobileCode": "+1-345"
  },
  {
      "Name": "Central African Republic",
      "Code": "CF",
      "MobileCode": "+236"
  },
  {
      "Name": "Chad",
      "Code": "TD",
      "MobileCode": "+235"
  },
  {
      "Name": "Chile",
      "Code": "CL",
      "MobileCode": "+56"
  },
  {
      "Name": "China",
      "Code": "CN",
      "MobileCode": "+86"
  },
  {
      "Name": "Colombia",
      "Code": "CO",
      "MobileCode": "+57"
  },
  {
      "Name": "Comoros",
      "Code": "KM",
      "MobileCode": "+269"
  },
  {
      "Name": "Congo",
      "Code": "CG",
      "MobileCode": "+242"
  },
  {
      "Name": "Congo (DRC)",
      "Code": "CD",
      "MobileCode": "+243"
  },
  {
      "Name": "Cook Islands",
      "Code": "CK",
      "MobileCode": "+682"
  },
  {
      "Name": "Costa Rica",
      "Code": "CR",
      "MobileCode": "+506"
  },
  {
      "Name": "Côte d'Ivoire",
      "Code": "CI",
      "MobileCode": "+225"
  },
  {
      "Name": "Croatia",
      "Code": "HR",
      "MobileCode": "+385"
  },
  {
      "Name": "Cuba",
      "Code": "CU",
      "MobileCode": "+53"
  },
  {
      "Name": "Cyprus",
      "Code": "CY",
      "MobileCode": "+357"
  },
  {
      "Name": "Czech Republic",
      "Code": "CZ",
      "MobileCode": "+420"
  },
  {
      "Name": "Democratic Republic of Timor-Leste",
      "Code": "TL",
      "MobileCode": "+670"
  },
  {
      "Name": "Denmark",
      "Code": "DK",
      "MobileCode": "+45"
  },
  {
      "Name": "Djibouti",
      "Code": "DJ",
      "MobileCode": "+253"
  },
  {
      "Name": "Dominica",
      "Code": "DM",
      "MobileCode": "+1-767"
  },
  {
      "Name": "Dominican Republic",
      "Code": "DO",
      "MobileCode": "+1-809"
  },
  {
      "Name": "Ecuador",
      "Code": "EC",
      "MobileCode": "+593"
  },
  {
      "Name": "Egypt",
      "Code": "EG",
      "MobileCode": "+20"
  },
  {
      "Name": "El Salvador",
      "Code": "SV",
      "MobileCode": "+503"
  },
  {
      "Name": "Equatorial Guinea",
      "Code": "GQ",
      "MobileCode": "+240"
  },
  {
      "Name": "Eritrea",
      "Code": "ER",
      "MobileCode": "+291"
  },
  {
      "Name": "Estonia",
      "Code": "EE",
      "MobileCode": "+372"
  },
  {
      "Name": "Ethiopia",
      "Code": "ET",
      "MobileCode": "+251"
  },
  {
      "Name": "Falkland Islands (Islas Malvinas)",
      "Code": "FK",
      "MobileCode": "+500"
  },
  {
      "Name": "Faroe Islands",
      "Code": "FO",
      "MobileCode": "+298"
  },
  {
      "Name": "Fiji Islands",
      "Code": "FJ",
      "MobileCode": "+679"
  },
  {
      "Name": "Finland",
      "Code": "FI",
      "MobileCode": "+358"
  },
  {
      "Name": "France",
      "Code": "FR",
      "MobileCode": "+33"
  },
  {
      "Name": "French Guiana",
      "Code": "GF",
      "MobileCode": "+594"
  },
  {
      "Name": "French Polynesia",
      "Code": "PF",
      "MobileCode": "+689"
  },
  {
      "Name": "Gabon",
      "Code": "GA",
      "MobileCode": "+241"
  },
  {
      "Name": "Gambia, The",
      "Code": "GM",
      "MobileCode": "+220"
  },
  {
      "Name": "Georgia",
      "Code": "GE",
      "MobileCode": "+995"
  },
  {
      "Name": "Germany",
      "Code": "DE",
      "MobileCode": "+49"
  },
  {
      "Name": "Ghana",
      "Code": "GH",
      "MobileCode": "+233"
  },
  {
      "Name": "Gibraltar",
      "Code": "GI",
      "MobileCode": "+350"
  },
  {
      "Name": "Greece",
      "Code": "GR",
      "MobileCode": "+30"
  },
  {
      "Name": "Greenland",
      "Code": "GL",
      "MobileCode": "+299"
  },
  {
      "Name": "Grenada",
      "Code": "GD",
      "MobileCode": "+1-473"
  },
  {
      "Name": "Guadeloupe",
      "Code": "GP",
      "MobileCode": "+590"
  },
  {
      "Name": "Guam",
      "Code": "GU",
      "MobileCode": "+1-671"
  },
  {
      "Name": "Guatemala",
      "Code": "GT",
      "MobileCode": "+502"
  },
  {
      "Name": "Guernsey",
      "Code": "GG",
      "MobileCode": "+44-1481"
  },
  {
      "Name": "Guinea",
      "Code": "GN",
      "MobileCode": "+224"
  },
  {
      "Name": "Guinea-Bissau",
      "Code": "GW",
      "MobileCode": "+245"
  },
  {
      "Name": "Guyana",
      "Code": "GY",
      "MobileCode": "+592"
  },
  {
      "Name": "Haiti",
      "Code": "HT",
      "MobileCode": "+509"
  },
  {
      "Name": "Heard Island and McDonald Islands",
      "Code": "HM",
      "MobileCode": "+ "
  },
  {
      "Name": "Honduras",
      "Code": "HN",
      "MobileCode": "+504"
  },
  {
      "Name": "Hong Kong SAR",
      "Code": "HK",
      "MobileCode": "+852"
  },
  {
      "Name": "Hungary",
      "Code": "HU",
      "MobileCode": "+36"
  },
  {
      "Name": "Iceland",
      "Code": "IS",
      "MobileCode": "+354"
  },
  {
      "Name": "India",
      "Code": "IN",
      "MobileCode": "+91"
  },
  {
      "Name": "Indonesia",
      "Code": "ID",
      "MobileCode": "+62"
  },
  {
      "Name": "Iran",
      "Code": "IR",
      "MobileCode": "+98"
  },
  {
      "Name": "Iraq",
      "Code": "IQ",
      "MobileCode": "+964"
  },
  {
      "Name": "Ireland",
      "Code": "IE",
      "MobileCode": "+353"
  },
  {
      "Name": "Israel",
      "Code": "IL",
      "MobileCode": "+972"
  },
  {
      "Name": "Italy",
      "Code": "IT",
      "MobileCode": "+39"
  },
  {
      "Name": "Jamaica",
      "Code": "JM",
      "MobileCode": "+1-876"
  },
  {
      "Name": "Japan",
      "Code": "JP",
      "MobileCode": "+81"
  },
  {
      "Name": "Jersey",
      "Code": "JE",
      "MobileCode": "+44-1534"
  },
  {
      "Name": "Jordan",
      "Code": "JO",
      "MobileCode": "+962"
  },
  {
      "Name": "Kazakhstan",
      "Code": "KZ",
      "MobileCode": "+7"
  },
  {
      "Name": "Kenya",
      "Code": "KE",
      "MobileCode": "+254"
  },
  {
      "Name": "Kiribati",
      "Code": "KI",
      "MobileCode": "+686"
  },
  {
      "Name": "Korea",
      "Code": "KR",
      "MobileCode": "+82"
  },
  {
      "Name": "Kuwait",
      "Code": "KW",
      "MobileCode": "+965"
  },
  {
      "Name": "Kyrgyzstan",
      "Code": "KG",
      "MobileCode": "+996"
  },
  {
      "Name": "Laos",
      "Code": "LA",
      "MobileCode": "+856"
  },
  {
      "Name": "Latvia",
      "Code": "LV",
      "MobileCode": "+371"
  },
  {
      "Name": "Lebanon",
      "Code": "LB",
      "MobileCode": "+961"
  },
  {
      "Name": "Lesotho",
      "Code": "LS",
      "MobileCode": "+266"
  },
  {
      "Name": "Liberia",
      "Code": "LR",
      "MobileCode": "+231"
  },
  {
      "Name": "Libya",
      "Code": "LY",
      "MobileCode": "+218"
  },
  {
      "Name": "Liechtenstein",
      "Code": "LI",
      "MobileCode": "+423"
  },
  {
      "Name": "Lithuania",
      "Code": "LT",
      "MobileCode": "+370"
  },
  {
      "Name": "Luxembourg",
      "Code": "LU",
      "MobileCode": "+352"
  },
  {
      "Name": "Macao SAR",
      "Code": "MO",
      "MobileCode": "+853"
  },
  {
      "Name": "Macedonia, Former Yugoslav Republic of",
      "Code": "MK",
      "MobileCode": "+389"
  },
  {
      "Name": "Madagascar",
      "Code": "MG",
      "MobileCode": "+261"
  },
  {
      "Name": "Malawi",
      "Code": "MW",
      "MobileCode": "+265"
  },
  {
      "Name": "Malaysia",
      "Code": "MY",
      "MobileCode": "+60"
  },
  {
      "Name": "Maldives",
      "Code": "MV",
      "MobileCode": "+960"
  },
  {
      "Name": "Mali",
      "Code": "ML",
      "MobileCode": "+223"
  },
  {
      "Name": "Malta",
      "Code": "MT",
      "MobileCode": "+356"
  },
  {
      "Name": "Man, Isle of",
      "Code": "IM",
      "MobileCode": "+44-1624"
  },
  {
      "Name": "Marshall Islands",
      "Code": "MH",
      "MobileCode": "+692"
  },
  {
      "Name": "Martinique",
      "Code": "MQ",
      "MobileCode": "+596"
  },
  {
      "Name": "Mauritania",
      "Code": "MR",
      "MobileCode": "+222"
  },
  {
      "Name": "Mauritius",
      "Code": "MU",
      "MobileCode": "+230"
  },
  {
      "Name": "Mayotte",
      "Code": "YT",
      "MobileCode": "+262"
  },
  {
      "Name": "Mexico",
      "Code": "MX",
      "MobileCode": "+52"
  },
  {
      "Name": "Micronesia",
      "Code": "FM",
      "MobileCode": "+691"
  },
  {
      "Name": "Moldova",
      "Code": "MD",
      "MobileCode": "+373"
  },
  {
      "Name": "Monaco",
      "Code": "MC",
      "MobileCode": "+377"
  },
  {
      "Name": "Mongolia",
      "Code": "MN",
      "MobileCode": "+976"
  },
  {
      "Name": "Montenegro",
      "Code": "ME",
      "MobileCode": "+382"
  },
  {
      "Name": "Montserrat",
      "Code": "MS",
      "MobileCode": "+1-664"
  },
  {
      "Name": "Morocco",
      "Code": "MA",
      "MobileCode": "+212"
  },
  {
      "Name": "Mozambique",
      "Code": "MZ",
      "MobileCode": "+258"
  },
  {
      "Name": "Myanmar",
      "Code": "MM",
      "MobileCode": "+95"
  },
  {
      "Name": "Namibia",
      "Code": "NA",
      "MobileCode": "+264"
  },
  {
      "Name": "Nauru",
      "Code": "NR",
      "MobileCode": "+674"
  },
  {
      "Name": "Nepal",
      "Code": "NP",
      "MobileCode": "+977"
  },
  {
      "Name": "Netherlands",
      "Code": "NL",
      "MobileCode": "+31"
  },
  {
      "Name": "New Caledonia",
      "Code": "NC",
      "MobileCode": "+687"
  },
  {
      "Name": "New Zealand",
      "Code": "NZ",
      "MobileCode": "+64"
  },
  {
      "Name": "Nicaragua",
      "Code": "NI",
      "MobileCode": "+505"
  },
  {
      "Name": "Niger",
      "Code": "NE",
      "MobileCode": "+227"
  },
  {
      "Name": "Nigeria",
      "Code": "NG",
      "MobileCode": "+234"
  },
  {
      "Name": "Niue",
      "Code": "NU",
      "MobileCode": "+683"
  },
  {
      "Name": "Norfolk Island",
      "Code": "NF",
      "MobileCode": "+672"
  },
  {
      "Name": "North Korea",
      "Code": "KP",
      "MobileCode": "+850"
  },
  {
      "Name": "Northern Mariana Islands",
      "Code": "MP",
      "MobileCode": "+1-670"
  },
  {
      "Name": "Norway",
      "Code": "NO",
      "MobileCode": "+47"
  },
  {
      "Name": "Oman",
      "Code": "OM",
      "MobileCode": "+968"
  },
  {
      "Name": "Pakistan",
      "Code": "PK",
      "MobileCode": "+92"
  },
  {
      "Name": "Palau",
      "Code": "PW",
      "MobileCode": "+680"
  },
  {
      "Name": "Palestinian Authority",
      "Code": "PS",
      "MobileCode": "+970"
  },
  {
      "Name": "Panama",
      "Code": "PA",
      "MobileCode": "+507"
  },
  {
      "Name": "Papua New Guinea",
      "Code": "PG",
      "MobileCode": "+675"
  },
  {
      "Name": "Paraguay",
      "Code": "PY",
      "MobileCode": "+595"
  },
  {
      "Name": "Peru",
      "Code": "PE",
      "MobileCode": "+51"
  },
  {
      "Name": "Philippines",
      "Code": "PH",
      "MobileCode": "+63"
  },
  {
      "Name": "Pitcairn Islands",
      "Code": "PN",
      "MobileCode": "+870"
  },
  {
      "Name": "Poland",
      "Code": "PL",
      "MobileCode": "+48"
  },
  {
      "Name": "Portugal",
      "Code": "PT",
      "MobileCode": "+351"
  },
  {
      "Name": "Puerto Rico",
      "Code": "PR",
      "MobileCode": "+1-787"
  },
  {
      "Name": "Qatar",
      "Code": "QA",
      "MobileCode": "+974"
  },
  {
      "Name": "Romania",
      "Code": "RO",
      "MobileCode": "+40"
  },
  {
      "Name": "Rwanda",
      "Code": "RW",
      "MobileCode": "+250"
  },
  {
      "Name": "Saint Helena, Ascension and Tristan da Cunha",
      "Code": "SH",
      "MobileCode": "+290"
  },
  {
      "Name": "Saint Kitts and Nevis",
      "Code": "KN",
      "MobileCode": "+1-869"
  },
  {
      "Name": "Saint Lucia",
      "Code": "LC",
      "MobileCode": "+1-758"
  },
  {
      "Name": "Saint Pierre and Miquelon",
      "Code": "PM",
      "MobileCode": "+508"
  },
  {
      "Name": "Saint Vincent and the Grenadines",
      "Code": "VC",
      "MobileCode": "+1-784"
  },
  {
      "Name": "Samoa",
      "Code": "WS",
      "MobileCode": "+685"
  },
  {
      "Name": "San Marino",
      "Code": "SM",
      "MobileCode": "+378"
  },
  {
      "Name": "São Tomé and Príncipe",
      "Code": "ST",
      "MobileCode": "+239"
  },
  {
      "Name": "Saudi Arabia",
      "Code": "SA",
      "MobileCode": "+966"
  },
  {
      "Name": "Senegal",
      "Code": "SN",
      "MobileCode": "+221"
  },
  {
      "Name": "Serbia",
      "Code": "RS",
      "MobileCode": "+381"
  },
  {
      "Name": "Seychelles",
      "Code": "SC",
      "MobileCode": "+248"
  },
  {
      "Name": "Sierra Leone",
      "Code": "SL",
      "MobileCode": "+232"
  },
  {
      "Name": "Singapore",
      "Code": "SG",
      "MobileCode": "+65"
  },
  {
      "Name": "Slovakia",
      "Code": "SK",
      "MobileCode": "+421"
  },
  {
      "Name": "Slovenia",
      "Code": "SI",
      "MobileCode": "+386"
  },
  {
      "Name": "Solomon Islands",
      "Code": "SB",
      "MobileCode": "+677"
  },
  {
      "Name": "Somalia",
      "Code": "SO",
      "MobileCode": "+252"
  },
  {
      "Name": "South Africa",
      "Code": "ZA",
      "MobileCode": "+27"
  },
  {
      "Name": "South Sudan",
      "Code": "SS",
      "MobileCode": "+211"
  },
  {
      "Name": "Spain",
      "Code": "ES",
      "MobileCode": "+34"
  },
  {
      "Name": "Sri Lanka",
      "Code": "LK",
      "MobileCode": "+94"
  },
  {
      "Name": "Sudan",
      "Code": "SD",
      "MobileCode": "+249"
  },
  {
      "Name": "Suriname",
      "Code": "SR",
      "MobileCode": "+597"
  },
  {
      "Name": "Swaziland",
      "Code": "SZ",
      "MobileCode": "+268"
  },
  {
      "Name": "Sweden",
      "Code": "SE",
      "MobileCode": "+46"
  },
  {
      "Name": "Switzerland",
      "Code": "CH",
      "MobileCode": "+41"
  },
  {
      "Name": "Syria",
      "Code": "SY",
      "MobileCode": "+963"
  },
  {
      "Name": "Taiwan",
      "Code": "TW",
      "MobileCode": "+886"
  },
  {
      "Name": "Tajikistan",
      "Code": "TJ",
      "MobileCode": "+992"
  },
  {
      "Name": "Tanzania",
      "Code": "TZ",
      "MobileCode": "+255"
  },
  {
      "Name": "Thailand",
      "Code": "TH",
      "MobileCode": "+66"
  },
  {
      "Name": "Togo",
      "Code": "TG",
      "MobileCode": "+228"
  },
  {
      "Name": "Tokelau",
      "Code": "TK",
      "MobileCode": "+690"
  },
  {
      "Name": "Tonga",
      "Code": "TO",
      "MobileCode": "+676"
  },
  {
      "Name": "Trinidad and Tobago",
      "Code": "TT",
      "MobileCode": "+1-868"
  },
  {
      "Name": "Tunisia",
      "Code": "TN",
      "MobileCode": "+216"
  },
  {
      "Name": "Turkey",
      "Code": "TR",
      "MobileCode": "+90"
  },
  {
      "Name": "Turkmenistan",
      "Code": "TM",
      "MobileCode": "+993"
  },
  {
      "Name": "Turks and Caicos Islands",
      "Code": "TC",
      "MobileCode": "+1-649"
  },
  {
      "Name": "Tuvalu",
      "Code": "TV",
      "MobileCode": "+688"
  },
  {
      "Name": "Uganda",
      "Code": "UG",
      "MobileCode": "+256"
  },
  {
      "Name": "Ukraine",
      "Code": "UA",
      "MobileCode": "+380"
  },
  {
      "Name": "United Arab Emirates",
      "Code": "AE",
      "MobileCode": "+971"
  },
  {
      "Name": "United Kingdom",
      "Code": "GB",
      "MobileCode": "+44"
  },
  {
      "Name": "United States",
      "Code": "US",
      "MobileCode": "+1"
  },
  {
      "Name": "Uruguay",
      "Code": "UY",
      "MobileCode": "+598"
  },
  {
      "Name": "Uzbekistan",
      "Code": "UZ",
      "MobileCode": "+998"
  },
  {
      "Name": "Vanuatu",
      "Code": "VU",
      "MobileCode": "+678"
  },
  {
      "Name": "Vatican City",
      "Code": "VA",
      "MobileCode": "+379"
  },
  {
      "Name": "Vietnam",
      "Code": "VN",
      "MobileCode": "+84"
  },
  {
      "Name": "Virgin Islands, U.S.",
      "Code": "VI",
      "MobileCode": "+1-340"
  },
  {
      "Name": "Virgin Islands, British",
      "Code": "VG",
      "MobileCode": "+1-284"
  },
  {
      "Name": "Wallis and Futuna",
      "Code": "WF",
      "MobileCode": "+681"
  },
  {
      "Name": "Yemen",
      "Code": "YE",
      "MobileCode": "+967"
  },
  {
      "Name": "Zambia",
      "Code": "ZM",
      "MobileCode": "+260"
  },
  {
      "Name": "Zimbabwe",
      "Code": "ZW",
      "MobileCode": "+263"
  }
]
