<template>
  <div class="view-content__wrapper">
    <div class="view-content d-block d-sm-flex flex-column justify-center">
      <h1 class="headline-1 text-center mb-6">
        {{ $t('maintenance.heading') }}
      </h1>
      <p class="subhead-1 mx-auto text-center">
        {{ $t('maintenance.text_1') }}
        <a
          href="#"
          class="text-decoration-none text-primary"
        >{{ $t('maintenance.link') }}</a>
        {{ $t('maintenance.text_2') }}
      </p>
    </div>
  </div>
</template>
