import { createApp } from 'vue'
import App from '@/App.vue'

//plugins
import router from '@/router'
import i18n from '@/i18n'
import vuetify from '@/plugins/vuetify'
import pinia from '@/plugins/pinia'
import Toast, { defaultOptions } from '@k12tech/ekool-vue-framework/src/plugins/toast'

import { postLoginMessage } from '@/utils/globalMethods'
import VueCookies from 'vue-cookies'

import { createMetaManager } from 'vue-meta'

const app = createApp(App)

app.use(router)
app.use(vuetify)
app.use(i18n)
app.use(pinia)
app.use(VueCookies)
app.use(createMetaManager())

app.mixin({
  methods: {
    postLoginMessage,
  },
})

app.use(Toast, defaultOptions)

router.isReady().then(() => {
  app.mount('#app')
});