import { defineStore } from 'pinia'
import * as api from '../api'

export const useAdminChangePasswordStore = defineStore('adminChangePassword', {
  state: () => ({
    adminChangePasswordBusy: false,
    adminChangePasswordError: false,
    adminChangePasswordSuccess: false,
    adminChangePasswordErrors: undefined,
  }),

  getters: {
    adminChangePasswordBusy: (state) => state.adminChangePasswordBusy,
    adminChangePasswordError: (state) => state.adminChangePasswordError,
    adminChangePasswordSuccess: (state) => state.adminChangePasswordSuccess,
    adminChangePasswordErrors: (state) => state.adminChangePasswordErrors,
  },

  actions: {
    async adminChangePassword({ token, username, password, passwordConfirmation }) {
      this.adminChangePasswordBusy = true
      this.adminChangePasswordError = false
      this.adminChangePasswordSuccess = false
      this.adminChangePasswordErrors = undefined

      try {
        const response = await api.adminChangePassword(token, username, password, passwordConfirmation)
        if (response.success) {
          this.adminChangePasswordSuccess = true
        }
        return response
      } catch (error) {
        this.adminChangePasswordError = true
        if (error.errors) {
          this.adminChangePasswordErrors = error.errors
        }
        return error
      } finally {
        this.adminChangePasswordBusy = false
      }
    },
  },
})