<template>
  <div class="view-content__wrapper">
    <div
      v-if="status == 'validating'"
      class="d-block d-sm-flex align-center mx-auto"
    >
      <div>
        <e-circular-loading
          value
          :full-page="false"
          :size="42"
        />
        <p class="headline-4 mt-10 mb-6">
          {{ $t('password_recovery.validating_token') }}
        </p>
      </div>
    </div>
    <div
      v-if="status == 'valid'"
      class="view-content"
    >
      <p class="headline-1 mb-6">
        {{ $t('password_recovery.heading') }}
      </p>
      <Alert v-if="getError" />
      <v-form
        ref="form"
        validate-on="submit lazy"
        @submit.prevent="newPasswordBusy || changePasswordBusy || adminChangePasswordBusy ? '' : reset()"
      >
        <div
          v-if="$route.name == 'changePassword'"
          class="py-4"
        >
          <e-text-field
            v-model="oldPassword"
            :rules="[rules.required($i18n.t('fields.oldPassword'))]"
            hide-details="auto"
            size="large"
            :label="$i18n.t('setup_account.old_password')"
            :type="showPassword1 ? 'text' : 'password'"
            :append-inner-icon="showPassword1 ? 'icon-eye-cut' : 'icon-eye'"
            @click:append-inner="showPassword1 = !showPassword1"
          />
        </div>
        <div class="pt-4">
          <e-text-field
            v-model="password"
            :rules="[rules.required($i18n.t('fields.password')), rules.passwordStrength, rules.min($i18n.t('fields.password'))(password, 9)]"
            hide-details="auto"
            size="large"
            :label="$i18n.t('setup_account.password')"
            :type="showPassword2 ? 'text' : 'password'"
            :append-inner-icon="showPassword2 ? 'icon-eye-cut' : 'icon-eye'"
            @click:append-inner="showPassword2 = !showPassword2"
          />
        </div>
        <div class="pt-4">
          <e-text-field
            v-model="repeatPassword"
            :rules="[rules.required($i18n.t('fields.repeatPassword')), confirmPasswordRules]"
            hide-details="auto"
            size="large"
            :label="$i18n.t('setup_account.repeat_password')"
            :type="showPassword3 ? 'text' : 'password'"
            :append-inner-icon="showPassword3 ? 'icon-eye-cut' : 'icon-eye'"
            @click:append-inner="showPassword3 = !showPassword3"
          />
        </div>
        <e-btn
          class="mt-6 callout-3"
          :block="true"
          type="submit"
          :loading="newPasswordBusy || changePasswordBusy || adminChangePasswordBusy"
          variant="primary"
          size="large"
          large
        >
          {{ $t('password_recovery.help_me') }}
        </e-btn>
      </v-form>
      <div class="text-center mt-4">
        <e-btn
          variant="link"
          size="small"
          :block="false"
          :href="homeUrl"
        >
          {{ $t('setup_account.back_to_frontpage') }}
        </e-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useGlobalStore ,useChangePasswordStore,  useAdminChangePasswordStore, useNewPasswordStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ETextField } from '@k12tech/ekool-vue-framework/src/components/ETextField'
import { ECircularLoading } from "@k12tech/ekool-vue-framework/src/components/ECircularLoading"
import { validationRules } from '@/validationRules.js'
export default {
  components: {
    Alert,
    EBtn,
    ETextField,
    ECircularLoading,
  },
  data() {
    return {
      status: 'valid', //need to change back to 'validating'
      username: '',
      oldPassword: '',
      password: '',
      repeatPassword: '',
      type: undefined,
      token: '',
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
      homeUrl: getEnv('VUE_APP_FRONTEND_APP_URL') + '/#/' + this.$i18n.locale,
      rules: validationRules,
    }
  },
  computed: {
    ...mapState(useNewPasswordStore, [
      'newPasswordBusy',
      'newPasswordError',
      'newPasswordSuccess',
      'newPasswordErrors',
      'newPasswordValidToken',
      'newPasswordValidationBusy',
    ]),
    ...mapState(useChangePasswordStore, ['changePasswordBusy', 'changePasswordError', 'changePasswordSuccess', 'changePasswordErrors']),
    ...mapState(useAdminChangePasswordStore, ['adminChangePasswordBusy', 'adminChangePasswordError', 'adminChangePasswordSuccess', 'adminChangePasswordErrors']),
    ...mapGetters(useGlobalStore, ['getError']),
    confirmPasswordRules() {
      return this.rules.password(this.repeatPassword, this.password)
    },
  },
  mounted() {
    if (this.$route.params.token) {
      this.token = this.$route.params.token
    }
    if (this.$route.name == 'adminChangePassword') {
      this.type = 'admin_change'
    }
    if (this.$route.name == 'changePassword') {
      this.type = 'change'
    }
    if (this.$route.name == 'setNewPassword') {
      this.type = 'reset'
    }
    //this.validate()
  },
  methods: {
    ...mapActions(useNewPasswordStore, ['setNewPassword', 'validateToken']),
    ...mapActions(useGlobalStore, ['changeError', 'showError']),
    ...mapActions(useChangePasswordStore, ['changePassword']),
    ...mapActions(useAdminChangePasswordStore, ['adminChangePassword']),
    validate() {
      var token = this.token
      var type = this.type
      this.validateToken({ token, type }).then(() => {
        if (this.newPasswordValidToken) {
          this.status = 'valid'
        } else if (!this.newPasswordValidToken) {
          this.$router.replace({
            name: 'login',
            state: {
              error: 'token_validation_failed',
            },
          })
        }
      })
    },
    reset() {
      this.$refs.form.validate().then((valid) => {
        if(valid.valid){
          var token = this.token
          var username = this.username
          var oldPassword = this.oldPassword
          var password = this.password
          var passwordConfirmation = this.repeatPassword
          if (this.$route.name == 'setNewPassword') {
            this.setNewPassword({ token, username, password, passwordConfirmation }).then(() => {
              if (this.newPasswordSuccess) {
                this.$router.push({
                  name: 'success',
                  state: {
                    email: this.username,
                    type: 'reset',
                  },
                })
              } else if (this.newPasswordError) {
                if (this.$te('password_recovery.' + this.newPasswordErrors)) {
                  this.changeError('password_recovery.' + this.newPasswordErrors)
                  this.showError(true)
                } else {
                  this.changeError('repeating_errors.' + this.newPasswordErrors)
                  this.showError(true)
                }
              }
            })
          } else if (this.$route.name == 'changePassword') {
            this.changePassword({ token, username, oldPassword, password, passwordConfirmation })
              .then(() => {
                if (this.changePasswordSuccess) {
                  this.$router.push({
                    name: 'success',
                    state: {
                      email: this.username,
                      type: 'mykoobReset',
                    },
                  })
                } else if (this.changePasswordError) {
                  if (this.$te('password_recovery.' + this.changePasswordErrors)) {
                    this.changeError('password_recovery.' + this.changePasswordErrors)
                    this.showError(true)
                  } else {
                    this.changeError('repeating_errors.' + this.changePasswordErrors)
                    this.showError(true)
                  }
                }
              })
          } else if (this.$route.name == 'adminChangePassword') {
            this.adminChangePassword({ token, username, password, passwordConfirmation }).then(() => {
              if (this.adminChangePasswordSuccess) {
                this.$router.push({
                  name: 'success',
                  state: {
                    email: this.username,
                    type: 'mykoobAdminReset',
                  },
                })
              } else if (this.adminChangePasswordError) {
                if (this.$te('password_recovery.' + this.adminChangePasswordErrors)) {
                  this.changeError('password_recovery.' + this.adminChangePasswordErrors)
                  this.showError(true)
                } else {
                  this.changeError('repeating_errors.' + this.adminChangePasswordErrors)
                  this.showError(true)
                }
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
