<template>
  <div class="mb-0">
    <div class="text-center py-4 px-10 headline-4">
      {{ $t('har_id_login.har_id_text') }}
    </div>
    <e-btn
      class="mt-4"
      :block="true"
      :href="link"
      :target="iframeView ? '_parent' : '_self'"
      variant="primary"
      size="large"
    >
      {{ $t('password_login.login') }}
    </e-btn>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useLayoutStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  props: {
    register: Boolean,
  },
  data() {
    return {
      link: '',
    }
  },
  computed: {
    ...mapState(useLayoutStore, ['iframeView']),
  },
  mounted() {
    if (this.register) {
      this.link = `${getEnv('VUE_APP_FRONTEND_API_URL')}/register/har-id`
    } else {
      this.link = '/har-id'
    }
  },
}
</script>
