<template>
  <div class="mb-0">
    <div class="text-center py-4 px-10 headline-4">
      {{ $t('e_paraksts_login.title') }}
    </div>
    <e-btn
      class="mt-4"
      :block="true"
      :href="apiUrl + '/e-paraksts'"
      variant="primary"
      size="large"
    >
      {{ $t('password_login.login') }}
    </e-btn>
  </div>
</template>

<script>
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      apiUrl: getEnv('VUE_APP_FRONTEND_API_URL'),
    }
  },
}
</script>
