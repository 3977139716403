<template>
  <div class="terms-container">
    <div v-html="privacyPolicyTerms" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useTermsStore, useLayoutStore } from '@/store'
export default {
  data() {
    return {
      dialog: true,
      prevShowIlustration: false,
      prevShowFooter: false,
    }
  },
  computed: {
    ...mapState(useTermsStore, ['privacyPolicyTermsBusy', 'privacyPolicyTerms', 'showIlustration', 'showFooter']),
  },
  mounted() {
    //HTML body height fix for dark mode
    document.body.style.height = 'auto'

    this.prevShowIlustration = this.showIlustration
    this.prevShowFooter = this.showFooter

    this.setShowIlustration(false) //hide ilustration
    this.setShowFooter(false) //hide footer
    this.setShowLanguages(false) //hide Languages
    this.getServiceTerms()
  },
  beforeUnmount() {
    //HTML body height fix for dark mode
    document.body.style.height = '100%'
    this.prevShowIlustration ? this.setShowIlustration(true) : null //show ilustration
    this.prevShowFooter ? this.setShowFooter(true) : null //show footer
    this.setShowLanguages(true) //show Languages
  },
  methods: {
    ...mapActions(useTermsStore, ['getPrivacyPolicyTerms']),
    ...mapActions(useLayoutStore, ['setShowIlustration', 'setShowFooter', 'setShowLanguages']),
    getServiceTerms() {
      this.getPrivacyPolicyTerms()
    },
  },
}
</script>
