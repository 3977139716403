<template>
  <div
    class="welcome-view d-flex flex-column mx-auto"
    style="max-width: 53.8rem"
  >
    <img
      class="mb-8 mx-auto fluid-img"
      src="../assets/welcome.svg"
      alt="welcome"
      style="max-width: 38.4rem"
    >
    <div class="d-flex flex-column align-center mb-8">
      <span class="headline-1 text-center mb-1">
        {{ $t('welcome.title') }}
      </span>
      <span
        class="body-2 px-4 text-center"
        :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
      >
        {{ $t('welcome.description_1') }}
        <br>
        {{ $t('welcome.description_2') }}
      </span>
    </div>
    <div class="mx-auto">
      <e-btn
        block="false"
        variant="primary"
        size="large"
        :href="`${appUrl}/#/${$i18n.locale}`"
      >
        {{ $t('welcome.go_to_login') }}
      </e-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useLayoutStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      prevShowIlustration: false,
      appUrl: getEnv('VUE_APP_FRONTEND_APP_URL'),
    }
  },
  computed: {
    ...mapState(useLayoutStore, ['showIlustration']),
  },
  mounted() {
    this.prevShowIlustration = this.showIlustration
    this.setShowIlustration(false) //hide ilustration
  },
  beforeUnmount() {
    this.prevShowIlustration ? this.setShowIlustration(true) : null //show ilustration
  },
  methods: {
    ...mapActions(useLayoutStore, ['setShowIlustration']),
  }
}
</script>
