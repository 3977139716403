<template>
  <div
    class="access-denied-view d-flex flex-column mx-auto"
    style="max-width: 53.8rem"
  >
    <div class="logo-container logo-container--danger mx-auto mb-6">
      <img
        src="../assets/ekool-logo.svg"
        alt="eKool logo"
      >
      <img
        class="logo-container__small-logo"
        src="../assets/lock-locked-logo.svg"
        alt="shield logo"
      >
    </div>
    <div class="d-flex flex-column align-center mb-7">
      <span class="headline-1 text-center mb-1">
        {{ $t('access_denied.title') }}
      </span>
      <span
        v-if="type"
        class="body-2 px-4 text-center"
        :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
      >
        {{ $te(`access_denied.${type}`) ? $t(`access_denied.${type}`) : $t(`repeating_errors.${type}`) }}
      </span>
    </div>
    <div class="mx-auto">
      <e-btn
        :block="false"
        variant="primary"
        :href="`${appUrl}/#/${$i18n.locale}`"
        size="large"
      >
        {{ $t('welcome.go_to_login') }}
      </e-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useLayoutStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      prevShowIlustration: false,
      appUrl: getEnv('VUE_APP_FRONTEND_APP_URL'),
      type: null,
    }
  },
  computed: {
    ...mapState(useLayoutStore, ['showIlustration']),
  },
  mounted() {
    this.prevShowIlustration = this.showIlustration
    this.setShowIlustration(false) //hide ilustration
    this.type = this.$route.query.type
  },
  beforeUnmount() {
    this.prevShowIlustration ? this.setShowIlustration(true) : null //show ilustration
  },
  methods: {
    ...mapActions(useLayoutStore, ['setShowIlustration']),
  }
}
</script>
