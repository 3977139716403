<template>
  <div class="mb-0 d-flex flex-column align-center">
    <div
      class="d-flex mb-6"
      style="width: 100%"
    >
      <e-tooltip
        v-for="(method, index) in thirdPartyMethods"
        :key="index"
        :disabled="display.mobile"
        location="top"
      >
        <template #activator="{ props }">
          <e-btn
            v-if="method.name == 'passkey'"
            style="min-width: 0 !important; flex-basis: 0; flex-grow: 1"
            class="elevation-1 flex-grow-1"
            :class="{ 'mr-2': index + 1 < thirdPartyMethods.length }"
            variant="tertiary"
            size="large"
            v-bind="props"
            @click="passkeyLogin"
          >
            <img
              :src="
                method.name == 'passkey' && $vuetify.theme.global.name == 'dark'
                  ? require(`../assets/${method.logo}-white.svg`)
                  : require(`../assets/${method.logo}.svg`)
              "
              alt="logo"
              height="19px"
            >
          </e-btn>
          <e-btn
            v-else
            style="min-width: 0 !important; flex-basis: 0; flex-grow: 1"
            class="elevation-1 flex-grow-1"
            :class="{ 'mr-2': index + 1 < thirdPartyMethods.length }"
            :href="register ? `${apiUrl}${method.registerUrl}` : `${apiUrl}${method.loginUrl}`"
            :target="iframeView ? '_parent' : '_self'"
            variant="tertiary"
            size="large"
            v-bind="props"
          >
            <img
              :src="
                method.name == 'passkey' && $vuetify.theme.global.name == 'dark'
                  ? require(`../assets/${method.logo}-white.svg`)
                  : require(`../assets/${method.logo}.svg`)
              "
              alt="logo"
              height="19px"
            >
          </e-btn>
        </template>
        {{ method.displayName }}
      </e-tooltip>
    </div>
    <div
      class="d-flex align-center"
      style="width: 100%"
    >
      <div class="line" />
      <span
        class="body-3 mx-3"
        style="font-weight: 500 !important"
        :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
      >
        {{ $t('third_party_login.or') }}
      </span>
      <div class="line" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useLayoutStore, usePasswordStore, useThirdPartyStore } from '@/store'
import { getEnv } from '../env'
import { useDisplay } from 'vuetify'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ETooltip } from '@k12tech/ekool-vue-framework/src/components/ETooltip'
import { useToast } from "@k12tech/ekool-vue-framework/src/plugins/toast";
export default {
  components: {
    EBtn,
    ETooltip,
  },
  props: {
    register: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { toast } = useToast()
    return { toast }
  },
  data() {
    return {
      availableMethods: [
        {
          name: 'passkey',
          displayName: 'Passkey',
          logo: 'passkey-logo',
          registerUrl: '/oauth/passkey/register/redirect',
        },
        {
          name: 'google',
          displayName: 'Google',
          logo: 'google-logo',
          loginUrl: '/oauth/google/redirect',
          registerUrl: '/oauth/google/register/redirect',
        },
        {
          name: 'microsoft',
          displayName: 'Microsoft',
          logo: 'microsoft-logo',
          loginUrl: '/oauth/microsoft/redirect',
          registerUrl: '/oauth/microsoft/register/redirect',
        },
        {
          name: 'eliis',
          displayName: 'ELIIS',
          logo: 'eliis-logo',
          loginUrl: '/oauth/eliis/redirect',
          registerUrl: '/oauth/eliis/register/redirect',
        },
      ],
      allowedMethods: getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS').split(', '),
      thirdPartyMethods: [],
      apiUrl: getEnv('VUE_APP_FRONTEND_API_URL'),
      display: useDisplay(),
    }
  },
  computed: {
    ...mapState(useLayoutStore, ['iframeView']),
    ...mapState(useThirdPartyStore, ['passkeysLoginOptionsBusy', 'passkeysLoginOptionsSuccess', 'passkeysLoginOptionsResponse']),
  },
  mounted() {
    this.handleAvailableMethods()
  },
  methods: {
    ...mapActions(useThirdPartyStore, ['getPasskeysLoginOptions', 'getPasskeysVerification']),
    ...mapActions(usePasswordStore, ['setUser']),
    async handleAvailableMethods() {
      this.thirdPartyMethods = this.availableMethods.filter((method) => this.allowedMethods.includes(method.name))
      //hide passkey for registration & case when browser does not support webauthn
      const browserSupportsWebAuthn = await window.SimpleWebAuthnBrowser.browserSupportsWebAuthn()
      if (this.register || !browserSupportsWebAuthn) {
        this.thirdPartyMethods = this.thirdPartyMethods.filter((method) => method.name !== 'passkey')
      }
    },
    async passkeyLogin() {
      const resp = await this.getPasskeysLoginOptions()

      let asseResp
      try {
        if (resp && resp.error) {
          const errorMessage = resp.error
          this.toast.error(this.$i18n.t(`passkey_errors.${errorMessage}`))
          return
        } else {
          asseResp = await window.SimpleWebAuthnBrowser.startAuthentication(await resp)
        }
      } catch (error) {
        this.toast.error(this.$i18n.t('third_party_login.passkey_authentication_failed'))
        throw error
      }

      const verificationResp = await this.getPasskeysVerification(asseResp)
      try {
        if (verificationResp && verificationResp.success) {
          if (verificationResp.redirect) {
            window.top.location.href = verificationResp.url
          } else {
            this.setUser({ url: verificationResp.url })
          }
        } else if (verificationResp && verificationResp.error) {
          const errorMessage = verificationResp.error
          this.toast.error(this.$i18n.t(`passkey_errors.${errorMessage}`))
          return
        }
      } catch (error) {
        this.toast.error(this.$i18n.t('third_party_login.passkey_verification_failed'))
        throw error
      }
    },
  },
}
</script>
