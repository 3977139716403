import { defineStore } from 'pinia';
import * as api from '../api';

export const useSchoolRegisterStore = defineStore('schoolRegister', {
  state: () => ({
    schoolRegisterBusy: false,
    schoolRegisterError: false,
    schoolRegisterSuccess: false,
    schoolRegisterErrors: undefined,
  }),

  actions: {
    async schoolRegister({ schoolName, registrationNumber, type, street, city, country, firstName, lastName, email, number }) {
      this.schoolRegisterBusy = true;
      try {
        const response = await api.schoolRegister(schoolName, registrationNumber, type, street, city, country, firstName, lastName, email, number);
        this.schoolRegisterSuccess = response.success;
      } catch (error) {
        this.schoolRegisterError = true;
        this.schoolRegisterErrors = error.errors;
      } finally {
        this.schoolRegisterBusy = false;
      }
    },
  },
});
