import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Forgot from '../views/Forgot.vue'
import SetupAccount from '../views/SetupAccount.vue'
import SetNewPassword from '../views/SetNewPassword.vue'
import Maintenance from '../views/Maintenance.vue'
import ChooseUser from '../views/ChooseUser.vue'
import Success from '../views/Success.vue'
import Failed from '../views/Failed.vue'
import Message from '../views/Message.vue'
import Register from '../views/Register.vue'
import SchoolRegister from '../views/SchoolRegister.vue'
import Validation from '../views/Validation.vue'
import Permissions from '../views/Permissions.vue'
import Roles from '../views/Roles.vue'
import AgreeToTerms from '../views/AgreeToTerms.vue'
import Terms from '../views/Terms.vue'
import ServiceTerms from '../views/ServiceTerms.vue'
import PrivacyPolicyTerms from '../views/PrivacyPolicyTerms.vue'
import CookiesPrivacyPolicyTerms from '../views/CookiesPrivacyPolicyTerms.vue'
import Welcome from '../views/Welcome.vue'
import TwoFactorOptions from '../views/TwoFactorOptions.vue'
import VerifyData from '../views/VerifyData.vue'
import TeacherInvite from '../views/TeacherInvite.vue'
import TeacherInviteAccept from '../views/TeacherInviteAccept.vue'
import TeacherInviteDecline from '../views/TeacherInviteDecline.vue'
import TeacherInviteError from '../views/TeacherInviteError.vue'
import UpdateEmail from '../views/UpdateEmail.vue'
import VerifyEmail from '../views/VerifyEmail.vue'
import TwoFactorLink from '../views/TwoFactorLink.vue'
import AccessDenied from '../views/AccessDenied.vue'
import i18n from '../i18n'
import { useGlobalStore, usePasswordStore } from '@/store'

import VueCookies from 'vue-cookies'

import { getEnv } from '../env'

const routes = [
  {
    path: '/:lang',
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        //props: true,
      },
      {
        path: 'mini',
        name: 'login-mini',
        component: Login,
        props: true,
      },
      {
        path: 'mobile',
        name: 'mobile',
        component: Login,
        props: true,
      },
      {
        path: 'iframe',
        name: 'iframe',
        component: Login,
        props: true,
      },
      {
        path: 'third-party',
        name: 'thirdParty',
        component: Login,
        props: true,
      },
      {
        path: 'register',
        name: 'register',
        component: Register,
        props: true,
      },
      {
        path: 'setNewPassword/:token',
        name: 'setNewPassword',
        component: SetNewPassword,
        params: {
          token: true,
        },
      },
      //start of Mykoob internal password change
      {
        path: 'changePassword/:token',
        name: 'changePassword',
        component: SetNewPassword,
        params: {
          token: true,
        },
      },
      {
        path: 'adminChangePassword/:token',
        name: 'adminChangePassword',
        component: SetNewPassword,
        params: {
          token: true,
        },
      },
      //end of Mykoob internal password change
      {
        path: 'forgotPassword',
        name: 'forgot',
        component: Forgot,
      },
      {
        path: 'setupAccount',
        name: 'setupAccount',
        component: SetupAccount,
      },
      {
        path: 'maintenance',
        name: 'maintenance',
        component: Maintenance,
      },
      {
        path: 'choose-user',
        name: 'chooseUser',
        component: ChooseUser,
        params: {
          users: true,
        },
      },
      {
        path: 'success',
        name: 'success',
        component: Success,
        params: {
          email: true,
          type: true,
          message: true,
        },
        props: true,
      },
      {
        path: 'failed',
        name: 'failed',
        component: Failed,
        params: {
          errors: true,
          type: true,
          error: true,
        },
        props: true,
      },
      {
        path: 'message',
        name: 'message',
        component: Message,
        props: true,
      },
      {
        path: 'school-register',
        name: 'schoolRegister',
        component: SchoolRegister,
      },
      {
        path: 'validation',
        name: 'validation',
        component: Validation,
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: Permissions,
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
      },
      {
        path: 'agree-to-terms',
        name: 'agreeToTerms',
        component: AgreeToTerms,
      },
      {
        path: 'terms',
        name: 'terms',
        component: Terms,
        children: [
          {
            path: 'service',
            name: 'serviceTerms',
            component: ServiceTerms,
          },
          {
            path: 'privacy-policy',
            name: 'privacyPolicyTerms',
            component: PrivacyPolicyTerms,
          },
          {
            path: 'cookies-privacy-policy',
            name: 'cookiesPrivacyPolicyTerms',
            component: CookiesPrivacyPolicyTerms,
          },
        ],
      },
      {
        path: 'welcome',
        name: 'welcome',
        component: Welcome,
      },
      {
        path: 'two-factor-options',
        name: 'twoFactorOptions',
        component: TwoFactorOptions,
      },
      {
        path: 'verify-data',
        name: 'verifyData',
        component: VerifyData,
      },
      {
        path: 'two-factor',
        name: 'twoFactor',
        component: Validation,
      },
      {
        path: 'teacher-invite',
        name: 'teacherinvite',
        component: TeacherInvite,
      },
      {
        path: 'teacher-invite/accept',
        name: 'teacherinviteaccept',
        component: TeacherInviteAccept,
      },
      {
        path: 'teacher-invite/decline',
        name: 'teacherinvitedecline',
        component: TeacherInviteDecline,
      },
      {
        path: 'teacher-invite/error',
        name: 'teacherinviteerror',
        component: TeacherInviteError,
      },
      {
        path: 'update-email',
        name: 'updateEmail',
        component: UpdateEmail,
      },
      {
        path: 'verify-email',
        name: 'VerifyEmail',
        component: VerifyEmail,
      },
      {
        path: '2fa-link',
        name: 'twoFactorLink',
        component: TwoFactorLink,
      },
      {
        path: 'access-denied',
        name: 'accessDenied',
        component: AccessDenied,
      },
      {
        path: 'notification/verification',
        name: 'notificationVerification',
        component: VerifyData,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
})

let initialLoad = true
router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  const allowedLangs = ['et', 'en', 'ru', 'de', 'fr', 'ja', 'lv']
  if (!allowedLangs.includes(lang)) {
    VueCookies.config('7d', '', '', false)
    VueCookies.set('locale', getEnv('VUE_APP_FRONTEND_I18N_LOCALE'))
    return next(getEnv('VUE_APP_FRONTEND_I18N_LOCALE'))
  } else {
    VueCookies.set('locale', lang)
  }

  if (document.location.href.includes('oauth/authorize') && initialLoad) {
    initialLoad = false
    return next(`/${allowedLangs.includes(lang) ? lang : getEnv('VUE_APP_FRONTEND_I18N_LOCALE')}/permissions`)
  }

  /*
  if (document.location.href.includes('notification/verification') && initialLoad) {
    initialLoad = false
    return next(`/${allowedLangs.includes(lang) ? lang : getEnv('VUE_APP_FRONTEND_I18N_LOCALE')}/verify-data`)
  }
  */
  if (i18n.global.locale !== lang) {
    i18n.global.locale = lang
    //set locale cookie form url
    VueCookies.config('7d', '', '', false)
    VueCookies.set('locale', lang)
  }
  //hide errors on navigation
  const globalStore = useGlobalStore()
  globalStore.showError(false)

  if (from && from.name == 'mobile') {
    globalStore.setMobileView(true)
  }
  //unset login busy state on redirect
  /*
  if (to.name == 'login') {
    store.commit('setPasswordLoginBusy', false)
  }
  */
  //caller handling
  const passwordStore = usePasswordStore()
  if (document.location.href.includes('caller') && initialLoad) {
    initialLoad = false
    const params = new URLSearchParams(document.location.search)
    if (params.has('caller') && VueCookies.get('logged_in')) {
      passwordStore.setUser({ caller: params.get('caller') })
    } else {
      passwordStore.setCaller({ caller: params.get('caller') })
    }
    return next()
  }
  //check logged_in cookie, make set user request
  if (
    VueCookies.get('logged_in') &&
    to.name !== 'accessDenied' &&
    to.name !== 'notificationVerification' &&
    to.name !== 'twoFactorLink' &&
    to.name !== 'VerifyEmail' &&
    to.name !== 'updateEmail' &&
    to.name !== 'teacherinvite' &&
    to.name !== 'teacherinviteerror' &&
    to.name !== 'teacherinviteaccept' &&
    to.name !== 'teacherinvitedecline' &&
    to.name !== 'register' &&
    to.name !== 'setupAccount' &&
    to.name !== 'success' &&
    to.name !== 'failed' &&
    to.name !== 'message' &&
    to.name !== 'permissions' &&
    to.name !== 'changePassword' &&
    to.name !== 'adminChangePassword' &&
    !to.query.caller
  ) {
    passwordStore.setUser()
  }
  return next()
})

export default router
