<template>
  <div class="view-content d-block d-md-flex align-center justify-center mx-auto">
    <e-circular-loading
      value
      :full-page="false"
      :size="42"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usePasswordStore, useHarStore, useXidStore, useThirdPartyStore } from '@/store'
import { ECircularLoading } from "@k12tech/ekool-vue-framework/src/components/ECircularLoading"
export default {
  components: {
    ECircularLoading,
  },
  data() {
    return {
      type: '',
      user: {},
    }
  },
  computed: {
    ...mapState(useHarStore, [
      'harValidationBusy',
      'harValidationErrors',
      'harValid',
      'harUser',
      'harMultipleAccounts',
      'harAccounts',
    ]),
    ...mapState(useXidStore, [
      'xidValidationBusy',
      'xidValidationErrors',
      'xidValid',
    ]),
    ...mapState(useThirdPartyStore, [
      'getGoogleRegisteringUserBusy',
      'getGoogleRegisteringUserSuccess',
      'getGoogleRegisteringUserErrors',
      'googleRegisteringUser',
      'getMicrosoftRegisteringUserBusy',
      'getMicrosoftRegisteringUserSuccess',
      'getMicrosoftRegisteringUserErrors',
      'microsoftRegisteringUser',
      'getEliisRegisteringUserBusy',
      'getEliisRegisteringUserSuccess',
      'getEliisRegisteringUserErrors',
      'eliisRegisteringUser',
    ]),
    ...mapState(usePasswordStore, [
      'getTwoFactorMethodsBusy',
      'getTwoFactorMethodsSuccess',
      'getTwoFactorMethodsErrors',
    ]),
  },
  mounted() {
    if (
      this.$route.query.type == 'har' ||
      this.$route.query.type == 'har-register' ||
      this.$route.query.type == 'xid' ||
      this.$route.query.type == 'google-register' ||
      this.$route.query.type == 'microsoft-register' ||
      this.$route.query.type == 'eliis-register'
    ) {
      this.type = this.$route.query.type
      this.validateUserData()
    } else if (this.$route.query.type == 'methods') {
      this.getTwoFactorMethodsMethod()
    } else {
      this.$router.replace({
        name: 'login',
      })
    }
  },
  methods: {
    ...mapActions(useHarStore, ['harValidation', 'harChooseUser']),
    ...mapActions(useXidStore, ['xidValidation']),
    ...mapActions(useThirdPartyStore, ['getGoogleRegisteringUser', 'getMicrosoftRegisteringUser', 'getEliisRegisteringUser']),
    ...mapActions(usePasswordStore, ['getTwoFactorMethods']),
    validateUserData() {
      //get har data and redirect to setup account with disabled fields
      if (this.type == 'har-register') {
        this.harValidation().then(() => {
          //if error
          if (this.harValid == false && this.harValidationErrors) {
            this.$router.replace({
              name: 'login',
              state: {
                error: 'repeating_errors.' + this.harValidationErrors,
              },
            })
          }
          //if success
          else if (this.harValid) {
            this.user = this.harUser
            this.$router.push({
              name: 'setupAccount',
              state: {
                user: JSON.parse(JSON.stringify(this.user)),
              },
            })
          }
        })
      }
      //har multiple accounts
      else if (this.type == 'har') {
        this.harChooseUser().then(() => {
          //if success
          if (this.harMultipleAccounts) {
            this.$router
              .push({
                name: 'chooseUser',
                state: {
                  users: this.harAccounts,
                },
              })
              .catch(() => {})
          }
          //if error
          else {
            this.$router.replace({
              name: 'login',
              state: {
                error: 'Error with harID',
              },
            })
          }
        })
      }
      //get xid data and redirect to setup account with disabled fields
      else if (this.type == 'xid') {
        this.xidValidation().then(() => {
          //if error
          if (!this.harValid) {
            this.$router.replace({
              name: 'login',
              state: {
                error: 'Error with X-ID',
              },
            })
          }
          //if success
          else if (this.harValid) {
            this.$router.push({
              name: 'setupAccount',
              state: {
                user: JSON.parse(JSON.stringify(this.user)),
              },
            })
          }
        })
      }
      //get google user data and redirect to setup account with prefilled fields
      else if (this.type == 'google-register') {
        this.getGoogleRegisteringUser().then(() => {
          //if error
          if (!this.getGoogleRegisteringUserSuccess) {
            this.$router.replace({
              name: 'login',
              state: {
                error: 'Error with Google',
              },
            })
          }
          //if success
          else if (this.getGoogleRegisteringUserSuccess) {
            this.user = this.googleRegisteringUser
            this.$router.push({
              name: 'setupAccount',
              state: {
                user: JSON.parse(JSON.stringify(this.user)),
              },
            })
          }
        })
      }
      //get microsoft user data and redirect to setup account with prefilled fields
      else if (this.type == 'microsoft-register') {
        this.getMicrosoftRegisteringUser().then(() => {
          //if error
          if (!this.getMicrosoftRegisteringUserSuccess) {
            this.$router.replace({
              name: 'login',
              state: {
                error: 'Error with Microsoft',
              },
            })
          }
          //if success
          else if (this.getMicrosoftRegisteringUserSuccess) {
            this.user = this.microsoftRegisteringUser
            this.$router.push({
              name: 'setupAccount',
              state: {
                user: JSON.parse(JSON.stringify(this.user)),
              },
            })
          }
        })
      }
      //get eliis user data and redirect to setup account with prefilled fields
      else if (this.type == 'eliis-register') {
        this.getEliisRegisteringUser().then(() => {
          //if error
          if (!this.getEliisRegisteringUserSuccess) {
            this.$router.replace({
              name: 'login',
              state: {
                error: 'Error with ELIIS',
              },
            })
          }
          //if success
          else if (this.getEliisRegisteringUserSuccess) {
            this.user = this.eliisRegisteringUser
            this.$router.push({
              name: 'setupAccount',
              state: {
                user: JSON.parse(JSON.stringify(this.user)),
              },
            })
          }
        })
      }
    },
    getTwoFactorMethodsMethod() {
      this.getTwoFactorMethods().then(() => {
        if (this.getTwoFactorMethodsSuccess) {
          this.$router.push({
            name: 'twoFactorOptions',
          })
        } else {
          this.$router.replace({
            name: 'login',
            state: {
              error: this.getTwoFactorMethodsErrors,
            },
          })
        }
      })
    },
  },
}
</script>
