import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    error: {
        showError: false,
        errorContent: '',
    },
    miniView: false,
    mobileView: false,
    miniViewFunction: () => {},
  }),

  getters: {
    getError: (state) => {
        return state.error.showError
    },
    getErrorContent: (state) => {
        return state.error.errorContent
    },
  },

  actions: {
    showError(value) {
        this.error.showError = value
    },
    changeError(message) {
        this.error.errorContent = message
    },
    setMiniView(value) {
        this.miniView = value
      },
    setMobileView(value) {
        this.mobileView = value
    },
    setMiniViewFunction(value) {
        this.miniViewFunction = value
    },
  },
})