import { defineStore } from 'pinia'
import * as api from '../api'

export const useChooseUserStore = defineStore('chooseUser', {
  state: () => ({
    chooseUserBusy: false,
    chooseUserError: false,
    chooseUserSuccess: false,
    chooseUserErrors: undefined,
  }),

  actions: {
    async chooseUser({ id }) {
      this.chooseUserBusy = true
      this.chooseUserError = false
      this.chooseUserSuccess = false
      this.chooseUserErrors = undefined

      try {
        const response = await api.selectUser(id)
        if (response.success) {
          this.chooseUserSuccess = true
        }
        return response
      } catch (error) {
        this.chooseUserError = true
        if (error.errors) {
          this.chooseUserErrors = error.errors
        }
        return error
      } finally {
        this.chooseUserBusy = false
      }
    },
  },
})