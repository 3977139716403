<template>
  <div class="view-content__wrapper">
    <div class="view-content view-content--wide d-block d-sm-flex">
      <div class="verify-data mx-auto">
        <div class="invite-teacher__header">
          <img
            v-if="$vuetify.theme.global.name == 'dark'"
            class="invite-teacher__logo"
            src="../assets/Teacher_invite_declined_dark.svg"
            alt="eKool logo"
          >
          <img
            v-else
            class="invite-teacher__logo"
            src="../assets/Teacher_invite_declined.svg"
            alt="eKool logo"
          >
          <p
            class="body-2 text-center mb-1"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-danger400' : 'text-danger300'"
          >
            {{ $t('expired') }}
          </p>
          <h1
            class="headline-1 text-graybase mb-1 text-center"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('teacher_invite_error_title') }}
          </h1>
          <p
            class="body-3 text-center mb-0"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
          >
            {{ $t('teacher_invite_error_desc') }}
          </p>
        </div>
        <div class="invite-teacher__footer">
          <e-btn
            variant="primary"
            :block="true"
            size="large"
            @click="goToEkool()"
          >
            {{ $t('go_to_ekool') }}
          </e-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {}
  },
  methods: {
    goToEkool() {
      window.top.location.href = getEnv('VUE_APP_FRONTEND_APP_URL')
    },
  },
}
</script>
