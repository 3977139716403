import { getEnv } from '../env'

export async function postLoginMessage(status) {
  if (window.parent) {
    await fetch(getEnv('VUE_APP_FRONTEND_API_URL') + '/set-user?redirect=false')
    var dataObject = {
      action: 'login',
      type: status,
    }
    window.parent.postMessage(dataObject, '*', [])
  }
}

export async function removeRouteStateItem(keys) {
  const currentState = { ...window.history.state }; // Clone the current state
  let modified = false;
  keys.forEach((key) => {
      if (currentState && currentState[key] !== undefined) {
          delete currentState[key]; // Remove the specific item
          modified = true;
      }
  });
  if (modified) {
      window.history.replaceState(currentState, document.title, window.location.href);
  }
}