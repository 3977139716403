<template>
  <div class="view-content__wrapper">
    <div
      class="view-content mx-auto"
      :class="{ 'setup-container': currentStep.order == 1 }"
    >
      <div class="d-flex justify-space-between align-center mb-6">
        <div class="headline-1">
          {{ $t('setup_account.' + currentStep.name) }}
        </div>
        <div>
          <v-progress-circular
            class="stepper-indicator"
            :model-value="currentStep.progressValue"
            :width="1.8"
            :size="46"
          >
            <span
              :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
              class="caption-1"
            >{{ currentStep.order }} / {{ registrationSteps.length }}</span>
          </v-progress-circular>
        </div>
      </div>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="currentStep.order == 1"
          key="account"
          class="mx-auto"
        >
          <Alert v-if="getError" />
          <v-form
            ref="formA"
            @submit.prevent="forward"
          >
            <div class="pb-4">
              <e-text-field
                ref="emailInput"
                v-model="email"
                size="large"
                required
                :rules="[rules.required($i18n.t('fields.email')), rules.email($i18n.t('fields.email'))]"
                hide-details="auto"
                :success="registerEmailValidateSuccess"
                :disabled="disabledXid || disabledThirdParty || disabledTeacherInvite"
                :label="$i18n.t('setup_account.email')"
                :error="
                  (registerEmailValidateError && registerEmailValidateErrors !== '')
                "
                :error-messages="
                  registerEmailValidateErrors
                    ? $t(`setup_account.${registerEmailValidateErrors}`)
                    : ''
                "
                @input="handleEmailInput()"
              />
            </div>
            <div class="pb-4">
              <e-text-field
                v-model="password"
                size="large"
                required
                :rules="[rules.required($i18n.t('fields.password')), rules.passwordStrength, rules.min($i18n.t('fields.password'))(password, 9)]"
                hide-details="auto"
                :label="$i18n.t('password_login.password')"
                :type="showPassword1 ? 'text' : 'password'"
                :append-inner-icon="showPassword1 ? 'icon-eye-cut' : 'icon-eye'"
                @click:append-inner="showPassword1 = !showPassword1"
              />
            </div>
            <div class="pb-4">
              <e-text-field
                ref="repeatPassword"
                v-model="repeatPassword"
                size="large"
                required
                :rules="[rules.required($i18n.t('fields.repeatPassword')), confirmPasswordRules]"
                hide-details="auto"
                :label="$i18n.t('setup_account.repeat_password')"
                :type="showPassword2 ? 'text' : 'password'"
                :append-inner-icon="showPassword2 ? 'icon-eye-cut' : 'icon-eye'"
                @click:append-inner="showPassword2 = !showPassword2"
              />
              <p class="body-3 text-grayscale400 pt-2 mb-0">
                * {{ $t('setup_account.password_hint') }}
              </p>
            </div>
            <div>
              <e-checkbox
                v-model="offers"
                class="body-3"
                :label="$i18n.t('setup_account.recieve_offers')"
              />
            </div>
            <e-btn
              class="mt-4"
              :block="true"
              :disabled="registerEmailValidateError"
              type="submit"
              variant="primary"
              size="large"
            >
              {{ $t('setup_account.forward') }}
            </e-btn>
            <p class="body-3 text-center mt-2">
              <span class="text-grayscale400">{{ $t('setup_account.terms_text_1') + ' ' }} </span>
              <a
                class="text-decoration-none text-primary"
                :href="terms.main"
                target="_blank"
              >{{ $t('setup_account.terms_link_1') + ' ' }}
              </a>
              <span class="text-grayscale400"> {{ $t('setup_account.terms_text_2') + ' ' }} </span>
              <a
                class="text-decoration-none text-primary"
                :href="terms.data + $i18n.locale + '.html'"
                target="_blank"
              >{{ $t('setup_account.terms_link_2') }}</a>
            </p>
            <div
              v-show="showTurnstileWidget"
              ref="turnstileRef"
              class="turnstile-widget mt-4"
            />
          </v-form>
        </div>
        <!-- Second step -->
        <div
          v-else-if="currentStep.order == 2"
          key="personal"
          class="personal-data"
        >
          <v-form
            ref="formB"
            @submit.prevent="account"
          >
            <div class="pb-4">
              <e-text-field
                v-model="firstName"
                :rules="[rules.required($i18n.t('fields.firstName')), rules.max($i18n.t('fields.firstName'))(firstName, 255)]"
                hide-details="auto"
                size="large"
                required
                :disabled="disabled || disabledXid"
                :label="$i18n.t('setup_account.first_name')"
              />
            </div>
            <div class="pb-4">
              <e-text-field
                v-model="lastName"
                :rules="[rules.required($i18n.t('fields.lastName')), rules.max($i18n.t('fields.lastName'))(lastName, 255)]"
                hide-details="auto"
                size="large"
                required
                :disabled="disabled || disabledXid"
                :label="$i18n.t('setup_account.last_name')"
              />
            </div>
            <div class="pb-4">
              <e-text-field
                ref="idCode"
                v-model="idCode"
                :rules="[rules.required($i18n.t('fields.id-code')), issuer == 'EE' ? rules.handleIDCode(idCode) : true, rules.max($i18n.t('fields.id-code'))(idCode, 255)]"
                hide-details="auto"
                size="large"
                required
                :disabled="disabled"
                :label="$i18n.t('setup_account.id_code')"
              />
            </div>
            <div class="pb-4">
              <e-select
                v-model="issuer"
                :rules="[rules.required($i18n.t('fields.issuer'))]"
                hide-details="auto"
                size="large"
                required
                class="body-2"
                :disabled="disabled"
                :label="$i18n.t('setup_account.select_issuer')"
                :items="countries"
                :placeholder="$i18n.t('setup_account.select')"
                item-title="Name"
                item-value="Code"
                hide-selected
              />
            </div>
            <div class="pb-4">
              <v-menu
                ref="menu"
                :disabled="disabledXid"
                :value="formattedDate"
                max-width="294"
                nudge-top="365px"
                nudge-right="40px"
                :close-on-content-click="false"
              >
                <template #activator="menuProps">
                  <div
                    v-bind="menuProps.props"
                    class="flex-grow-1"
                  >
                    <e-text-field
                      ref="dateOfBirth"
                      v-model="formattedDate"
                      :rules="[
                        rules.required($i18n.t('fields.date')),
                        rules.regex($i18n.t('fields.date'))(formattedDate, /^\d{1,2}\.\d{1,2}\.\d{4}$/),
                        rules.dateOfBirth,
                        rules.checkDate(formattedDate, idCode, issuer),
                      ]"
                      hide-details="auto"
                      size="large"
                      required
                      append-inner-icon="icon-calendar"
                      :disabled="disabledXid"
                      :label="$i18n.t('setup_account.date_of_birth')"
                      :placeholder="$t('setup_account.date_placeholder')"
                      @input="dateTyped(formattedDate)"
                    />
                  </div>
                </template>
                <e-date-picker
                  :model-value="date"
                  model-type="yyyy-MM-dd"
                  :locale="$i18n.locale"
                  :show-current="false"
                  :max-date="maxDate"
                  width="294"
                  @update:model-value="datePicked"
                />
              </v-menu>
            </div>
            <div class="pb-4">
              <e-select
                ref="gender"
                v-model="gender"
                :rules="[rules.required($i18n.t('fields.gender')), rules.checkGender(gender, idCode, issuer)]"
                hide-details="auto"
                size="large"
                required
                class="body-2"
                :disabled="disabledXid"
                :label="$i18n.t('setup_account.gender')"
                :items="genders"
                :placeholder="$i18n.t('setup_account.select')"
                item-value="value"
                item-title="name"
              />
            </div>
            <div class="d-flex justify-space-between align-center mt-2">
              <e-btn
                size="small"
                variant="link"
                @click="back()"
              >
                {{ $i18n.t('setup_account.go_back') }}
              </e-btn>
              <e-btn
                type="submit"
                variant="primary"
                size="large"
              >
                {{ $i18n.t('setup_account.forward') }}
              </e-btn>
            </div>
          </v-form>
        </div>
        <!-- Third step -->
        <div
          v-else-if="currentStep.order == 3"
          key="address"
          class="personal-data"
        >
          <v-form
            ref="formC"
            @submit.prevent="addressValidate"
          >
            <div class="pb-4">
              <e-select
                v-model="country"
                :rules="[rules.required($i18n.t('fields.country'))]"
                hide-details="auto"
                size="large"
                :label="$i18n.t('setup_account.country')"
                required
                class="body-2"
                :value="country"
                :items="countries"
                item-title="Code"
                item-value="Code"
                hide-selected
                :menu-props="{
                  width: '340px'
                }"
              >
                <!-- modify selected item -->
                <template #selection="{ item }">
                  <country-flag-item
                    :country="item.raw.Code"
                    :country-name="item.raw.Name"
                  />
                </template>
                <!-- modify list items -->
                <template #item="{ props, item }">
                  <div
                    v-bind="props"
                    :key="item.raw.Code"
                  >
                    <!--<flag :squared="false" v-bind="props" :iso="item.Code" />-->
                    <v-list-item
                      v-bind="props"
                      :key="item.raw.Code"
                      class="e-list-item"
                      :value="item.raw.Code"
                    >
                      <template #title>
                        <country-flag-item
                          :country="item.raw.Code"
                          :country-name="item.raw.Name"
                        />
                      </template>
                    </v-list-item>
                  </div>
                </template>
              </e-select>
            </div>
            <div class="pb-4">
              <e-text-field
                v-model="county"
                :rules="[rules.max($i18n.t('fields.county'))(idCode, 255)]"
                hide-details="auto"
                size="large"
                :label="$i18n.t('school_registration.county')"
              />
            </div>
            <div class="pb-4">
              <e-text-field
                v-model="street"
                :rules="[rules.required($i18n.t('fields.street')), rules.max($i18n.t('fields.street'))(idCode, 255)]"
                hide-details="auto"
                size="large"
                required
                :disabled="disabledXid"
                :label="$i18n.t('setup_account.street')"
              />
            </div>
            <div class="city-postal-index-container">
              <div class="pb-4">
                <e-text-field
                  v-model="city"
                  :rules="[rules.required($i18n.t('fields.city')), rules.max($i18n.t('fields.city'))(idCode, 255)]"
                  hide-details="auto"
                  size="large"
                  required
                  :disabled="disabledXid"
                  :label="$i18n.t('setup_account.city')"
                />
              </div>
              <div class="pb-4">
                <e-text-field
                  v-model="postalIndex"
                  :rules="[rules.required($i18n.t('fields.postalIndex')), rules.max($i18n.t('fields.postalIndex'))(idCode, 255)]"
                  hide-details="auto"
                  size="large"
                  required
                  :label="$i18n.t('setup_account.postal_index')"
                />
              </div>
            </div>
            <div class="d-flex justify-space-between align-center mt-2">
              <e-btn
                size="small"
                variant="link"
                @click="back()"
              >
                {{ $t('setup_account.go_back') }}
              </e-btn>
              <e-btn
                type="submit"
                variant="primary"
                size="large"
              >
                {{ $t('setup_account.create_account') }}
              </e-btn>
            </div>
          </v-form>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import Alert from '@/components/Alert.vue'
import CountryFlagItem from '@/components/CountryFlagItem.vue'
import { countries } from '../utils/countries'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useGlobalStore , useSetupAccountStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ETextField } from '@k12tech/ekool-vue-framework/src/components/ETextField'
import { ESelect } from '@k12tech/ekool-vue-framework/src/components/ESelect'
import { EDatePicker } from "@k12tech/ekool-vue-framework/src/components/EDatePicker"
import { ECheckbox } from "@k12tech/ekool-vue-framework/src/components/ECheckbox"
import { validationRules } from '@/validationRules.js'
import { removeRouteStateItem } from "@/utils/globalMethods";
import { turnstileMixin } from "@/utils/turnstileMixin";
export default {
  components: {
    Alert,
    EBtn,
    ETextField,
    ESelect,
    EDatePicker,
    ECheckbox,
    CountryFlagItem,
  },
  mixins: [turnstileMixin],
  data() {
    return {
      firstName: '',
      lastName: '',
      disabled: false,
      disabledXid: false,
      idCode: '',
      issuer: 'EE',
      dateOfBirth: '',
      gender: undefined,
      email: '',
      repeatEmail: '',
      password: '',
      repeatPassword: '',
      offers: false,
      errors: {
        wrongDate: false,
        wrongIdCode: false,
        repeatEmail: false,
      },
      countries: countries,
      currentStep: { order: 1, name: 'personal_data', progressValue: 33.3 },
      registrationSteps: [
        { order: 1, name: 'personal_data', progressValue: 33.3 },
        { order: 2, name: 'personal_data', progressValue: 66.6 },
        { order: 3, name: 'address', progressValue: 100 },
      ],
      genders: [
        { value: 0, name: this.$i18n.t('setup_account.male') },
        { value: 1, name: this.$i18n.t('setup_account.female') },
      ],
      showPassword1: false,
      showPassword2: false,
      mobilePrefixes: ['+372', '+370', '+371'],
      number: undefined,
      code: '+372',
      revalidate: false,
      phoneNumberValidationError: '',
      timeLeft: 0,
      timeoutTimer: false,
      hidePhoneNumberError: false,
      formattedDate: '',
      date: '',
      maxDate: format(new Date(), 'yyyy-MM-dd'),
      token: '',
      country: 'EE',
      county: null,
      city: null,
      street: null,
      postalIndex: null,
      thirdPartyHash: null,
      disabledThirdParty: false,
      disabledTeacherInvite: false,
    }
  },
  computed: {
    terms() {
      return {
        main:
          this.$i18n.locale == 'et'
            ? 'https://www.ekool.eu/teenusetingimused'
            : 'https://www.ekool.eu/en/terms-of-service',
        data: getEnv('VUE_APP_FRONTEND_TERMS_DATA'),
      }
    },
    ...mapState(useSetupAccountStore, [
      'setupAccountBusy',
      'setupAccountSuccess',
      'setupAccountError',
      'setupAccountErrors',
      'setupAccountMessage',
      'setupAccountCountry',
      'setupAccountHash',
      'showPhoneNumberModal',
      'registerEmailValidateBusy',
      'registerEmailValidateSuccess',
      'registerEmailValidateError',
      'registerEmailValidateErrors',
      'registerPhoneVerifyRequestBusy',
      'registerPhoneVerifyRequestSuccess',
      'registerPhoneVerifyRequestError',
      'registerPhoneVerifyRequestErrors',
      'registerPhoneVerifyRequestTimeout',
      'registerPhoneVerifyCodeBusy',
      'registerPhoneVerifyCodeSuccess',
      'registerPhoneVerifyCodeError',
      'registerPhoneVerifyCodeErrors',
    ]),
    ...mapGetters(useGlobalStore, ['getError']),
    showModal: {
      get() {
        return this.showPhoneNumberModal
      },
      set(value) {
        this.setPhoneNumberModal(value)
      },
    },
    phoneNumberError() {
      return this.registerPhoneVerifyRequestError || this.registerPhoneVerifyCodeError
    },
    phoneNumberErrorMessage() {
      if (this.phoneNumberValidationError !== '' && this.phoneNumberValidationError !== undefined) {
        return this.phoneNumberValidationError
      } else if (this.registerPhoneVerifyRequestError) {
        return this.registerPhoneVerifyRequestErrors == 'too_many_requests'
          ? this.$t(`setup_account.${this.registerPhoneVerifyRequestErrors}`, { x: this.timeLeft })
          : this.$t(`setup_account.${this.registerPhoneVerifyRequestErrors}`)
      } else if (this.registerPhoneVerifyCodeError) {
        return this.$t(`setup_account.${this.registerPhoneVerifyCodeErrors}`)
      } else {
        return false
      }
    },
    confirmPasswordRules() {
      return this.rules.password(this.repeatPassword, this.password)
    },
    rules() {
      return validationRules
    },
  },
  watch: {
    idCode() {
      if (this.issuer == 'EE') {
        this.$refs.idCode.validate().then(() =>{
          if(this.$refs.idCode.isValid && this.idCode !== '') {
            this.fillGenderIdCode()
          }
        })
      } else {
        return false
      }
    },
    issuer(val) {
      //revalidate dependant fields
      if(val == 'EE'){
        this.$refs.idCode.resetValidation().then(() =>{
          if(this.idCode == '') return
          this.$refs.idCode.validate().then(() => {
            if(this.$refs.idCode.isValid){
              if (this.idCode !== '') {
                this.fillGenderIdCode()
              } else {
                return false
              }
            }
          })
        })
        this.$refs.dateOfBirth.resetValidation().then(() =>{
          if(this.formattedDate == '') return
          this.$refs.dateOfBirth.validate()
        })
      } else {
        this.$refs.idCode.resetValidation()
        this.$refs.dateOfBirth.resetValidation()
      }
    },
    password() {
      if (this.repeatPassword) {
        // Trigger validation
        this.$refs.repeatPassword.resetValidation().then(() =>{
          this.$refs.repeatPassword.validate()
        })
      }
    },
    email(newVal) {
      if(newVal == ''){
        this.setRegisterEmailValidateSuccess(false)
      }
    }
  },
  mounted() {
    if (window.history.state.user) {
      this.firstName = window.history.state.user.name
      this.lastName = window.history.state.user.surname
      if (window.history.state.user.personal_code) {
        this.idCode = window.history.state.user.personal_code
      }
      if (window.history.state.user.iso) {
        this.issuer = window.history.state.user.iso
      }
      //additional fields for x-id registration
      if (window.history.state.user.date_of_birth) {
        this.formattedDate = window.history.state.user.date_of_birth
      }
      if (window.history.state.user.gender) {
        this.gender = window.history.state.user.gender
      }
      this.email = window.history.state.user.email
      //check to disable har data fields or other 3rd party data fields
      if (window.history.state.user.third_party_hash) {
        this.thirdPartyHash = window.history.state.user.third_party_hash
        this.disabledThirdParty = true
      } else if (Object.keys(window.history.state.user).length == 7) {
        this.disabledXid = true
      } else if (Object.keys(window.history.state.user).length == 1) {
        this.disabledTeacherInvite = true
      } else {
        this.disabled = true
      }
    }
    this.$nextTick(() => {
      if (this.$refs.turnstileRef) {
        this.initializeTurnstile(this.$refs.turnstileRef); // Initialize Turnstile after DOM is updated
      }
    });
    if (window.history.state.allowEdit) {
      this.disabled = false
      this.disabledXid = false
      this.disabledThirdParty = false
      this.disabledTeacherInvite = false
    }
    if(this.email !== ''){
      this.validateEmail()
    }
  },
  beforeUnmount() {
    this.removeTurnstile()
    removeRouteStateItem(['user']) //clear route state
    this.setRegisterEmailValidateSuccess(false)
  },
  methods: {
    ...mapActions(useSetupAccountStore, [
      'setupAccount',
      'registerEmailValidate',
      'registerPhoneVerifyRequest',
      'registerPhoneVerifyCode',
      'setRegisterEmailValidateSuccess',
      'setPhoneNumberModal',
      'setRegisterPhoneVerifyCodeSuccess',
    ]),
    ...mapActions(useGlobalStore, ['changeError', 'showError']),
    forward() {
      this.$refs.formA.validate().then((valid) => {
        if(valid.valid){
          this.currentStep = this.registrationSteps.find((el) => el.order == this.currentStep.order + 1)
        }
      })
    },
    back() {
      this.currentStep = this.registrationSteps.find((el) => el.order == this.currentStep.order - 1)
    },
    account() {
      if (!this.$refs.formB.isValid) return
      this.currentStep = this.registrationSteps.find((el) => el.order == this.currentStep.order + 1)
    },
    address() {
      //this.$refs['thirdFormValidationObserver'].validate() //validate third form on submit
      var firstName = this.firstName
      var lastName = this.lastName
      var idCode = this.idCode
      var issuer = this.issuer
      var dateOfBirth = this.formattedDate
      var gender = this.gender
      var email = this.email
      var emailConfirmation = this.email
      var password = this.password
      var passwordConfirmation = this.repeatPassword
      var wishPartnerAds = this.offers
      var countryCode = this.number == undefined || this.number == '' ? '' : this.code // if no number, set country code to empty string
      var phoneNumber = this.number
      var country = this.country
      var county = this.county
      var city = this.city
      var street = this.street
      var postalIndex = this.postalIndex
      if (this.thirdPartyHash) {
        var thirdPartyHash = this.thirdPartyHash
      }
      //teacher invite extra params
      if (window.history.state.inviteType) {
        var inviteType = window.history.state.inviteType
      }
      if (window.history.state.inviteHash) {
        var inviteHash = window.history.state.inviteHash
      }
      this.setupAccount({
          firstName,
          lastName,
          idCode,
          issuer,
          dateOfBirth,
          gender,
          email,
          emailConfirmation,
          password,
          passwordConfirmation,
          wishPartnerAds,
          countryCode,
          phoneNumber,
          country,
          county,
          city,
          street,
          postalIndex,
          thirdPartyHash,
          inviteType,
          inviteHash,
        })
        .then(() => {
          if (
            this.setupAccountSuccess &&
            this.thirdPartyHash &&
            (this.setupAccountMessage == '' || !this.setupAccountMessage)
          ) {
            this.$router.push({
              name: 'welcome',
            })
          } else if (this.setupAccountSuccess && this.setupAccountMessage == 'user_id_code_already_exist') {
            this.$router.push({
              name: 'message',
              state: {
                thirdParty: this.thirdPartyHash ? true : false,
                type: 'registrationHash',
              },
            })
          } else if (this.setupAccountSuccess && this.setupAccountMessage) {
            this.$router.push({
              name: 'success',
              state: {
                //email: this.email,
                type: 'registrationMessage',
                message: this.setupAccountMessage,
              },
            })
          } else if (this.setupAccountSuccess) {
            this.$router.push({
              name: 'success',
              state: {
                email: this.email,
                type: 'registration',
              },
            })
          } else if (this.setupAccountError && this.setupAccountErrors) {
            if (this.$te('registration_failed.' + this.setupAccountErrors)) {
              this.changeError('registration_failed.' + this.setupAccountErrors)
              this.showError(true)
            } else {
              this.changeError('repeating_errors.' + this.setupAccountErrors)
              this.showError(true)
            }
          }
        })
    },
    addressValidate() {
      this.$refs.formC.validate().then((valid) => {
        if(valid.valid){
          this.address()
        }
      })
    },
    fillGenderIdCode() {
      if (this.issuer == 'EE') {
        //date of birth stuff
        var idCodeArr = Array.from(this.idCode).map(Number)
        var year
        if (idCodeArr[0] === 1 || idCodeArr[0] === 2) {
          year = '18'
        } else if (idCodeArr[0] === 3 || idCodeArr[0] === 4) {
          year = '19'
        } else if (idCodeArr[0] === 5 || idCodeArr[0] === 6) {
          year = '20'
        }
        var correctYear =
          idCodeArr[5] +
          '' +
          idCodeArr[6] +
          '.' +
          idCodeArr[3] +
          '' +
          idCodeArr[4] +
          '.' +
          year +
          '' +
          idCodeArr[1] +
          '' +
          idCodeArr[2]
        this.formattedDate = correctYear
        
        //gender stuff
        if (idCodeArr[0] % 2 === 0) {
          //Female
          this.gender = 1
        } else {
          //Male
          this.gender = 0
        }
      }
    },
    validateEmail() {
      let email = this.email
      this.registerEmailValidate(email)
    },
    async registerPhoneVerifyRequest() {
      //clear timeout
      clearInterval(this.timeoutTimer)
      this.hidePhoneNumberError = false
      let countryCode = this.code
      let phoneNumber = this.number
      let token = this.turnstileToken
      this.registerPhoneVerifyRequest({ countryCode, phoneNumber, token }).then(() => {
        /* eslint-disable no-undef */
        this.resetTurnstile()
        if (this.registerPhoneVerifyRequestSuccess) {
          this.showModal = true
        }
      })
    },
    handleEmailInput() {
      const validate = this.rules.email(this.$i18n.t('fields.email'));
      const valid = validate(this.email);
      if (valid === true) {
          this.validateEmail();
      }
    },
    handlePhoneNumberInput(value) {
      this.$refs['phoneNumberValidationProvider'].validate().then(() => {
        this.phoneNumberValidationError = this.$refs['phoneNumberValidationProvider'].errors[0]
      })

      if (value !== undefined && this.registerPhoneVerifyCodeSuccess) {
        this.setRegisterPhoneVerifyCodeSuccess(false)
      }
    },
    countdown() {
      clearInterval(this.timeoutTimer)
      this.timeLeft = this.registerPhoneVerifyRequestTimeout
      this.timeoutTimer = setInterval(() => {
        if (this.timeLeft == 0) {
          clearInterval(this.timeoutTimer)
        } else {
          this.timeLeft -= 1
        }
      }, 1000)
    },
    getCodeByMobileCode(mobileCode) {
      const foundCountry = this.countries.find((country) => country.MobileCode === mobileCode)
      return foundCountry ? foundCountry.Code : null
    },
    datePicked(date) {
      if (!date) return
      this.formattedDate = this.formatDateForInput(date)
    },
    formatDateForInput(date) {
      return format(parseISO(date), 'dd.MM.yyyy')
    },
    dateTyped(date) {
      if (!date || date.length !== 10) return
      this.date = this.formatDateForPicker(date)
    },
    formatDateForPicker(date) {
      const [day, month, year] = date.split('.')
      return `${year}-${month}-${day}`
    },
    validateOnInput() {
      return {
        on: ['input'],
      }
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
