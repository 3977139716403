import { defineStore } from 'pinia'
import * as api from '../api'

export const useHarStore = defineStore('har', {
  state: () => ({
    harValidationBusy: false,
    harValidationErrors: undefined,
    harValid: false,
    harUser: undefined,
    harMultipleAccounts: false,
    harAccounts: undefined,
  }),

  actions: {
    async harValidation() {
      this.harValidationBusy = true
      this.harValid = false
      this.harValidationErrors = undefined

      try {
        const response = await api.getHarData()
        if (response.success && response.user) {
          this.harUser = response.user
          this.harValid = true
        } else {
          this.harValid = false
        }
        return response
      } catch (error) {
        this.harValidationErrors = error.errors
        return error
      } finally {
        this.harValidationBusy = false
      }
    },

    async harChooseUser() {
      this.harValidationBusy = true

      try {
        const response = await api.chooseUser()
        this.harMultipleAccounts = response.multiple_accounts
        this.harAccounts = response.accounts
        return response
      } catch (error) {
        this.harValid = false
        this.harValidationErrors = error.errors
        return error
      } finally {
        this.harValidationBusy = false
      }
    },
  },
})