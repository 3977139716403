<template>
  <div class="view-content__wrapper">
    <div class="view-content d-block d-md-flex align-center justify-center mx-auto">
      <div>
        <div
          v-if="type == 'registration'"
          class="registration"
        >
          <div class="text-center mb-6">
            <v-icon size="70">
              {{ $vuetify.theme.global.name == 'dark' ? 'icon-paper-plane grayscale__text' : 'icon-paper-plane text-graybase' }}
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('registration_successful.activation_link_sent') }}
          </p>
          <p
            v-if="email !== undefined"
            class="body-3 text-center"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
          >
            <span> {{ $t('registration_successful.account_created') }} - </span>
            <a
              class="text-success"
              :href="'mailto:' + email"
            >{{ email }}</a>
          </p>
          <p
            v-if="email == undefined"
            class="body-3 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
          >
            {{ $t('registration_successful.account_created') }}
          </p>
        </div>
        <div
          v-if="type == 'registrationMessage'"
          class="registration"
        >
          <div class="text-center mb-6">
            <v-icon size="70">
              icon-paper-plane text-success
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('registration_successful.' + message) }}
          </p>
        </div>
        <div
          v-if="type == 'school'"
          class="registration"
        >
          <div class="text-center mb-6">
            <v-icon size="70">
              icon-success text-success
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('school_registration.school_registered') }}
          </p>
        </div>
        <div v-if="type == 'reset' || type == 'mykoobReset' || type == 'mykoobAdminReset'">
          <div class="text-center mb-6">
            <v-icon size="70">
              icon-success text-success
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('password_recovery.password_changed_successfuly') }}
          </p>
        </div>
        <div v-if="type == 'resetRequest'">
          <div class="text-center mb-6">
            <v-icon size="70">
              {{ $vuetify.theme.global.name == 'dark' ? 'icon-paper-plane text-grayscale100' : 'icon-paper-plane text-graybase' }}
            </v-icon>
          </div>

          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('password_recovery.link_sent') }}
          </p>
          <div class="text-center">
            <e-btn
              class="mb-2"
              variant="secondary"
              medium=""
              :to="'/' + $i18n.locale + '/forgotPassword'"
              :block="false"
            >
              {{ $t('forgot_password.resend_email') }}
            </e-btn>
          </div>
        </div>
        <div v-if="type == 'activation'">
          <div class="text-center mb-6">
            <v-icon size="70">
              icon-success text-success
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('registration_successful.account_activated') }}
          </p>
        </div>
        <div class="text-center mt-4">
          <e-btn
            v-if="type !== 'mykoobReset' && type !== 'mykoobAdminReset'"
            variant="link"
            size="small"
            :block="false"
            @click="toLogin()"
          >
            {{ $t('forgot_password.back_to_frontpage') }}
          </e-btn>
          <e-btn
            v-if="type == 'mykoobReset'"
            variant="link"
            size="small"
            :block="false"
            :href="mainUrl"
          >
            {{ $t('forgot_password.back_to_frontpage') }}
          </e-btn>
          <e-btn
            v-if="type == 'mykoobAdminReset'"
            variant="link"
            size="small"
            :block="false"
            @click="closeWindow()"
          >
            {{ $t('forgot_password.back_to_frontpage') }}
          </e-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      email: undefined,
      type: undefined,
      message: '',
      mainUrl: getEnv('VUE_APP_FRONTEND_APP_URL'),
    }
  },
  mounted() {
    this.email = window.history.state.email
    this.message = window.history.state.message

    if (window.history.state.type) {
      this.type = window.history.state.type
    } else if (this.$route.query.type) {
      this.type = this.$route.query.type
    }

    if (this.type == undefined) {
      this.$router.replace({
        name: 'login',
      })
    }
  },
  methods: {
    closeWindow() {
      window.open('', '_self')
      window.close()
    },
    toLogin() {
      this.$router.push('/' + this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
