<template>
  <div class="mb-6">
    <e-hint
      :title="$t(getErrorContent)"
      variant="danger"
      closable
      @click:close="closeError()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'pinia'
import { useGlobalStore } from '@/store'
import { EHint } from '@k12tech/ekool-vue-framework/src/components/EHint'
export default {
  components: {
    EHint,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(useGlobalStore, ['getErrorContent']),
  },
  methods: {
    ...mapActions(useGlobalStore, ['showError']),
    closeError() {
      this.showError(false)
    },
  },
}
</script>
