<template>
  <div class="view-content__wrapper">
    <div class="view-content">
      <div class="flex-grow-1">
        <div class="mb-6 headline-1">
          {{ $t('choose_user.choose_user') }}
        </div>
        <Alert
          v-if="getError"
          class="mt-3"
        />
        <div
          v-for="(email, id) in users"
          :key="id"
          class="mt-6 d-flex align-center justify-space-between user"
        >
          <e-avatar
            :letters="email.slice(0, 2).toUpperCase()"
            :title="email"
            :size="44"
          />
          <!-- TODO remove when avatar has title prop again -->
          <span class="callout-3 truncate ml-1">
            {{ email }}
          </span>
          <!-- -->
          <e-btn
            class="callout-3"
            :block="false"
            :loading="selected == id"
            variant="secondary"
            @click="choose(id)"
          >
            {{ $t('choose_user.choose') }}
          </e-btn>
        </div>
        <div class="text-center mt-7">
          <e-btn
            variant="link"
            size="small"
            :block="false"
            :to="'/' + $i18n.locale"
          >
            {{ $t('forgot_password.back_to_frontpage') }}
          </e-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useChooseUserStore, useGlobalStore, usePasswordStore } from '@/store'
import Alert from '@/components/Alert.vue'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { EAvatar } from "@k12tech/ekool-vue-framework/src/components/EAvatar"
export default {
  components: {
    Alert,
    EBtn,
    EAvatar,
  },
  data() {
    return {
      selected: -1,
      users: {},
      colorCache: {},
    }
  },
  computed: {
    ...mapState(useChooseUserStore, ['chooseUserBusy', 'chooseUserError', 'chooseUserSuccess', 'chooseUserErrors', 'getError']),
    ...mapState(useGlobalStore, ['miniView']),
  },
  mounted() {
    if (window.history.state.users) {
      this.users = window.history.state.users
    } else {
      this.$router.push({
        name: 'login',
      })
    }
  },
  methods: {
    ...mapActions(useChooseUserStore, ['chooseUser']),
    ...mapActions(useGlobalStore, ['changeError', 'showError']),
    ...mapActions(usePasswordStore, ['setUser']),
    choose(id) {
      this.selected = id
      this.chooseUser({ id }).then((response) => {
        if (this.chooseUserSuccess && response.url !== '') {
          if (this.miniView) {
            this.postLoginMessage('success')
            return
          }
          if (response.redirect) {
            window.top.location.href = response.url
          } else {
            this.setUser({ url: response.url })
          }
        } else if (this.chooseUserErrors == 'user_not_found') {
          this.changeError('forgot_password.user_not_found')
          this.showError(true)
          this.selected = -300
        } else if (this.chooseUserError && this.chooseUserErrors) {
          if (this.$te('choose_user.' + this.chooseUserErrors)) {
            this.changeError('choose_user.' + this.chooseUserErrors)
            this.showError(true)
          } else {
            this.changeError('repeating_errors.' + this.chooseUserErrors)
            this.showError(true)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
