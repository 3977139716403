<template>
  <div class="d-block d-md-flex align-center justify-center mx-auto">
    <e-circular-loading
      v-if="twoFactorEmailVerifyBusy"
      value
      :timeout="400"
      :full-page="false"
      :size="42"
    />
    <div
      v-else
      class="d-flex flex-column justify-center align-center"
    >
      <div class="logo-container mb-8">
        <img
          src="../assets/ekool-logo.svg"
          alt="eKool logo"
        >
        <img
          v-if="twoFactorEmailVerifySuccess"
          class="logo-container__small-logo"
          :src="
            $vuetify.theme.global.name == 'dark' ? require(`../assets/door-left-logo-dark.svg`) : require(`../assets/door-left-logo.svg`)
          "
          alt="door logo"
        >
        <img
          v-else
          class="logo-container__small-logo"
          :src="$vuetify.theme.global.name == 'dark' ? require(`../assets/danger-logo-dark.svg`) : require(`../assets/danger-logo.svg`)"
          alt="door logo"
        >
      </div>
      <div style="width: 100%; max-width: 500px">
        <div
          v-if="twoFactorEmailVerifySuccess"
          class="headline-1 text-center"
        >
          {{ $t('two_factor.email_link_success') }}
        </div>
        <div
          v-else
          class="headline-1 text-center"
        >
          {{ $t('two_factor.email_link_error') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usePasswordStore, useLayoutStore } from '@/store'
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState(usePasswordStore, ['twoFactorEmailVerifyBusy', 'twoFactorEmailVerifySuccess', 'twoFactorEmailVerifyErrors']),
  },
  mounted() {
    this.twoFactorVerify()
  },
  beforeUnmount() {
    this.setShowIlustration(true)
    this.setMobileAppPromo(true)
  },
  methods: {
    ...mapActions(usePasswordStore, ['twoFactorEmailVerify']),
    ...mapActions(useLayoutStore, ['setShowIlustration', 'setMobileAppPromo']),
    twoFactorVerify() {
      let hash = this.$route.query.hash
      this.twoFactorEmailVerify(hash)
    },
  },
}
</script>
