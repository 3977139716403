<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="view-content__wrapper">
    <div
      v-if="getRolesBusy"
      class="d-flex justify-center align-cente"
    >
      <e-circular-loading
        value
        :full-page="false"
        :size="42"
      />
    </div>
    <div v-if="!getRolesBusy && rolesData">
      <div class="view-content">
        <div class="mb-6 headline-1">
          {{ $t('choose_user.choose_user') }}
        </div>
        <div class="role__container">
          <div
            v-for="(role, id) in rolesData"
            :key="id"
            class="role"
          >
            <div>
              <div
                v-for="(roleValue, roleName, id) in role"
                v-if="roleName !== 'role'"
                :key="id + 'a'"
                class="role__data"
              >
                <span class="body-3 role__data__name"> {{ $t('roles.' + roleName) }}: </span>
                <span
                  v-if="$te('roles.' + roleValue)"
                  class="body-3 role__data__value"
                >
                  {{ $t('roles.' + roleValue) }}
                </span>
                <span
                  v-else
                  class="body-3 role__data__value"
                >
                  {{ roleValue }}
                </span>
              </div>
            </div>
            <e-btn
              class="role__button"
              :block="false"
              :loading="selected == id"
              variant="secondary"
              @click="chooseRole(id)"
            >
              {{ $t('choose_user.choose') }}
            </e-btn>
          </div>
        </div>
        <div class="text-center mt-7">
          <e-btn
            variant="link"
            size="small"
            :block="false"
            :to="'/' + $i18n.locale"
          >
            {{ $t('forgot_password.back_to_frontpage') }}
          </e-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { selectUser, } from "../api/index.js";
import { mapState, mapGetters, mapActions } from 'pinia'
import { useGlobalStore, useRoleStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ECircularLoading } from "@k12tech/ekool-vue-framework/src/components/ECircularLoading"
export default {
  components: {
    EBtn,
    ECircularLoading,
  },
  data() {
    return {
      selected: -300,
    }
  },
  computed: {
    ...mapGetters(useGlobalStore, ['getError']),
    ...mapState(useRoleStore, ['getRolesBusy', 'getRolesError', 'getRolesSuccess', 'getRolesErrors', 'rolesData']),
  },
  mounted() {
    this.getRoleList()
  },
  methods: {
    ...mapActions(useRoleStore, ['getRoles']),
    getRoleList() {
      this.getRoles().then(() => {
        //if error
        if (this.getRolesError) {
          this.$router.replace({
            name: 'login',
            state: {
              error: this.getRolesErrors,
            },
          })
        }
      })
    },
    chooseRole(id) {
      this.selected = id
      //redirect to third party
      window.location.href = getEnv('VUE_APP_FRONTEND_API_URL') + `/oauth/select-role?role_key=${id}`
    },
  },
}
</script>
