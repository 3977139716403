import { defineStore } from 'pinia';
import * as api from '../api';
import i18n from '../i18n';

export const useSmartStore = defineStore('smart', {
  state: () => ({
    smartLoginBusy: false,
    smartError: false,
    smartLoggedIn: false,
    smartSuccess: false,
    smartErrors: '',
    smartCodeSent: false,
    smartCode: '',
    smartAccounts: undefined,
    smartUser: undefined,
    smartPing: false,
    smartPingBusy: false,
    smartTermsRequired: false,
    smartUrl: null,
    smartRedirect: null,
  }),

  actions: {
    loginSmartUser() {
      this.smartLoginBusy = true;
    },
    registerSmartUser() {
      this.smartLoginBusy = true;
    },
    resetSmartState() {
      this.smartLoginBusy = false;
      this.smartError = false;
      this.smartLoggedIn = false;
      this.smartSuccess = false;
      this.smartErrors = '';
      this.smartCodeSent = false;
      this.smartCode = '';
      this.smartAccounts = undefined;
      this.smartUser = undefined;
      this.smartPing = true;
      this.smartUrl = null;
      this.smartRedirect = null;
    },
    loginSmartUserSuccess(response) {
      this.smartLoginBusy = false;
      this.smartSuccess = response.success;
      this.smartPing = true;
      this.smartError = response.error;
      this.smartErrors = response.errors;
      if (response.success) {
        this.smartCode = response.verification_code;
        this.smartCodeSent = true;
      }
    },
    registerSmartUserSuccess(response) {
      this.smartLoginBusy = false;
      this.smartSuccess = response.success;
      this.smartPing = true;
      if (response.success) {
        this.smartCode = response.verification_code;
        this.smartCodeSent = true;
      }
    },
    loginSmartUserFail(error) {
      this.smartLoginBusy = false;
      this.smartError = true;
      this.smartErrors = error.errors;
    },
    registerSmartUserFail(error) {
      this.smartLoginBusy = false;
      this.smartError = true;
      this.smartErrors = error.errors;
    },
    pingSmart() {
      this.smartPingBusy = true;
    },
    pingSmartSuccess(response) {
      this.smartPingBusy = false;
      this.smartTermsRequired = response.terms_acception_required;
      this.smartSuccess = response.success;
      if (this.smartSuccess && !this.smartTermsRequired) {
        this.smartPing = false;
        if (response.login_method === 'smart_id') {
          this.smartLoggedIn = true;
          this.smartCodeSent = false;
        }
      }
      if (this.smartTermsRequired) {
        this.smartPing = false;
      }
      this.smartLoginBusy = false;
      this.smartAccounts = response.accounts;
      this.smartUser = response.user;
      if (response.errors) {
        this.smartError = true;
        this.smartErrors = response.errors;
        this.smartCodeSent = false;
      }
      this.smartUrl = response.url;
      this.smartRedirect = response.redirect;
    },
    pingSmartFail(error) {
      this.smartPingBusy = false;
      this.smartError = true;
      this.smartErrors = error.errors;
      this.smartPing = false;
      this.smartLoginBusy = false;
      this.smartCodeSent = false;
    },
    cancelSmartPing() {
      this.smartCodeSent = false;
      this.smartPing = false;
    },

    // Actions calling API endpoints
    async loginCardSmart({ country, personalCode }) {
      this.loginSmartUser();
      try {
        const response = await api.loginCardSmart(country, personalCode);
        this.loginSmartUserSuccess(response);
        return response;
      } catch (error) {
        this.loginSmartUserFail(error);
        return error;
      }
    },
    async registerCardSmart({ country, personalCode }) {
      this.registerSmartUser();
      try {
        const response = await api.registerCardSmart(country, personalCode);
        this.registerSmartUserSuccess(response);
        return response;
      } catch (error) {
        this.registerSmartUserFail(error);
        return error;
      }
    },
    async loginPingSmart() {
      this.pingSmart();
      try {
        const response = await api.loginCardSmartCheck();
        this.pingSmartSuccess(response);
        return response;
      } catch (error) {
        this.pingSmartFail(error);
        return error;
      }
    },
    async registerPingSmart() {
      this.pingSmart();
      try {
        const response = await api.registerCardSmartCheck();
        this.pingSmartSuccess(response);
        return response;
      } catch (error) {
        this.pingSmartFail(error);
        return error;
      }
    },
  },
});