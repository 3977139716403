// axios
import axios from 'axios'
import { getEnv } from '@/env'

const axiosIns = axios.create({
  baseURL: getEnv('VUE_APP_FRONTEND_API_URL'),
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

export default axiosIns
