<template>
  <div class="mobile-id">
    <v-form
      v-if="!smartCodeSent && !smartLoggedIn"
      ref="form"
      validate-on="submit lazy"
      @submit.prevent="
        () => {
          smartLoginBusy ? null : login()
        }
      "
    >
      <div
        v-if="project == 'eKool'"
        class="pt-1 mb-4"
      >
        <e-select
          v-model="country"
          :rules="[rules.required($i18n.t('fields.country'))]"
          hide-details="auto"
          size="large"
          class="body-2"
          :label="$t('smart_id_login.choose_country')"
          :items="countries"
          :placeholder="$t('setup_account.select')"
          item-value="iso"
          item-title="name"
          name="country"
        />
      </div>
      <div class="pt-1 mb-4">
        <e-text-field
          v-model="idCode"
          :rules="[rules.required($i18n.t('fields.id-code'))]"
          hide-details="auto"
          size="large"
          :label="$t('mobile_id_login.id_code')"
          name="id-code"
        />
      </div>

      <e-btn
        class="mt-6 callout-3"
        :block="true"
        type="submit"
        :loading="smartLoginBusy"
        variant="primary"
        size="large"
      >
        {{ $t('password_login.login') }}
      </e-btn>
    </v-form>
    <div v-if="smartCodeSent && !smartLoggedIn">
      <p class="large-title text-center mb-3">
        {{ smartCode }}
      </p>
      <p class="subhead-1 text-center mb-0 px-10">
        {{ $t('smart_id_login.check_code') }}
      </p>
      <div class="text-center mt-6">
        <e-btn
          variant="link"
          size="small"
          @click="cancelPing()"
        >
          {{ $t('smart_id_login.cancel') }}
        </e-btn>
      </div>
    </div>
    <!-- To do -->
    <div
      v-if="smartLoggedIn"
      class="text-center"
    >
      <e-circular-loading
        class="pt-4"
        value
        :full-page="false"
        :size="42"
      />
      <p class="subhead-1 text-center mb-0 px-10 mt-10">
        {{ $t('smart_id_login.auth_successful') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useSmartStore, useGlobalStore, usePasswordStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ETextField } from '@k12tech/ekool-vue-framework/src/components/ETextField'
import { ESelect } from '@k12tech/ekool-vue-framework/src/components/ESelect'
import { ECircularLoading } from '@k12tech/ekool-vue-framework/src/components/ECircularLoading'
import { validationRules } from '@/validationRules.js'

export default {
  components: {
    EBtn,
    ETextField,
    ESelect,
    ECircularLoading,
  },
  props: {
    register: Boolean,
  },
  data() {
    return {
      project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
      country: undefined,
      idCode: '',
      accounts: {},
      user: {},
      code: '',
      ping: false,
      countries: [
        { iso: 'EE', name: 'Estonia' },
        { iso: 'LV', name: 'Latvia' },
        { iso: 'LT', name: 'Lithuania' },
      ],
      rules: validationRules,
    }
  },
  computed: {
    ...mapState(useSmartStore, [
      'smartLoginBusy',
      'smartError',
      'smartLoggedIn',
      'smartSuccess',
      'smartErrors',
      'smartCodeSent',
      'smartCode',
      'smartAccounts',
      'smartUser',
      'smartPing',
      'smartPingBusy',
      'smartTermsRequired',
      'smartUrl',
      'smartRedirect',
    ]),
  },
  mounted() {
    if (this.project == 'Mykoob') {
      this.country = 'LV'
    } else {
      this.country = 'EE'
    }
  },
  beforeUnmount() {
    this.resetSmartState()
  },
  methods: {
    ...mapActions(useSmartStore, ['loginCardSmart', 'loginPingSmart', 'cancelSmartPing', 'registerCardSmart', 'registerPingSmart', 'resetSmartState']),
    ...mapActions(useGlobalStore, ['showError', 'changeError']),
    ...mapActions(usePasswordStore, ['setUser']),
    login() {
      this.$refs.form.validate().then((valid) => {
        if (valid.valid) {
          if (this.register == false) {
            this.loginSmartId()
          } else {
            this.registerSmartId()
          }
        }
      })
    },

    //login
    loginSmartId() {
      var country = this.country
      var personalCode = this.idCode
      this.resetSmartState()
      this.loginCardSmart({ country, personalCode }).then(() => {
        if (this.smartError && this.smartErrors !== '') {
          if (this.$te('smart_id_login.' + this.smartErrors)) {
            this.changeError('smart_id_login.' + this.smartErrors)
            this.showError(true)
          } else {
            this.changeError('repeating_errors.' + this.smartErrors)
            this.showError(true)
          }
        }
        if (this.smartCodeSent) {
          this.loginPingSmartId()
        }
      })
    },
    //register
    registerSmartId() {
      var country = this.country
      var personalCode = this.idCode
      this.resetSmartState()
      this.registerCardSmart({ country, personalCode }).then(() => {
        //this.loginPingSmartId();
        if (this.smartError && this.smartErrors !== '') {
          this.changeError('smart_id_login.' + this.smartErrors)
          this.showError(true)
        } else if (this.smartError && this.smartErrors == '') {
          this.changeError('smart_id_login.' + this.smartErrors)
          this.showError(true)
        } else if (this.smartCodeSent) {
          this.registerPingSmartId()
        }
      })
    },
    loginPingSmartId() {
      this.ping = setInterval(() => {
        if (this.smartTermsRequired) {
          this.$router.push({
            name: 'agreeToTerms',
          })
        }
        if (this.smartPing && !this.smartPingBusy) {
          this.loginPingSmart()
        } else {
          if (!this.smartPingBusy) {
            clearInterval(this.ping)
            this.cancelPing()
          }
        }
        if (this.smartLoggedIn) {
          if (this.smartRedirect) {
            window.top.location.href = this.smartUrl
          } else {
            this.setUser({ url: this.smartUrl })
          }
        }
        if (this.smartAccounts) {
          this.$router.push({
            name: 'chooseUser',
            state: {
              users: this.smartAccounts,
            },
          })
        }
        if (this.smartError && this.smartErrors !== '') {
          if (this.$te('smart_id_login.' + this.smartErrors)) {
            this.changeError('smart_id_login.' + this.smartErrors)
            this.showError(true)
          } else {
            this.changeError('repeating_errors.' + this.smartErrors)
            this.showError(true)
          }
        }
      }, 3000)
    },
    cancelPing() {
      this.cancelSmartPing()
    },
    registerPingSmartId() {
      this.ping = setInterval(() => {
        if (this.smartPing && !this.smartPingBusy) {
          this.registerPingSmart()
        } else {
          if (!this.smartPingBusy) {
            clearInterval(this.ping)
            this.cancelPing()
          }
        }
        if (this.smartError && this.smartErrors !== '') {
          if (this.$te('smart_id_login.' + this.smartErrors)) {
            this.changeError('smart_id_login.' + this.smartErrors)
            this.showError(true)
          } else {
            this.changeError('repeating_errors.' + this.smartErrors)
            this.showError(true)
          }
        } else if (this.smartUser) {
          this.$router.push({
            name: 'setupAccount',
            state: {
              user: JSON.parse(JSON.stringify(this.smartUser)),
            },
          })
        }
      }, 3000)
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
