<template>
  <div>
    <nav class="navbar">
      <a
        v-if="project == 'eKool'"
        :href="appUrl + '/#/' + $i18n.locale"
      >
        <ekool-logo :dark="dark" />
      </a>
      <a
        v-else
        class="navbar__brand"
        :href="appUrl + '/#/' + $i18n.locale"
      >
        <img
          class="navbar__brand__img"
          :src="
            $vuetify.theme.global.name == 'dark' ? require(`../assets/mykoob-logo-light.png`) : require(`../assets/mykoob-logo-dark.png`)
          "
          alt="mykoob logo"
        >
      </a>
      <div>
        <e-select
          v-if="showLanguages"
          v-model="selectedLang"
          class="navbar__lang-switch body-2 text-uppercase"
          :prepend-inner-icon="$vuetify.theme.global.name == 'dark' ? 'icon-globe text-grayscale100' : 'icon-globe text-graybase'"
          item-title="name"
          item-value="value"
          :items="localeObjectArray"
          @update:model-value="changeLocale(selectedLang)"
        />
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useLayoutStore } from '@/store/layout'
import { getEnv } from '../env'
import { ESelect } from '@k12tech/ekool-vue-framework/src/components/ESelect'
import EkoolLogo from '@/components/EkoolLogo'
export default {
  components: {
    ESelect,
    EkoolLogo
  },
  data() {
    return {
      selectedLang: this.$i18n.locale,
      languageOptions: getEnv('VUE_APP_FRONTEND_LOCALES').split(', '),
      localeObjectArray: [],
      project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
      showGetApp: false,
      appLink: 'https://play.google.com/store/apps/details?id=eu.ekool.app&hl=en&gl=US',
      imageSrc: '',
      device: '',
      appUrl: getEnv('VUE_APP_FRONTEND_APP_URL'),
    }
  },
  computed: {
    ...mapState(useLayoutStore, ['showLanguages']),
    dark() {
      return this.$vuetify.theme.global.name == 'dark'
    },
    lang() {
      return this.$i18n.locale
    },
  },
  watch: {
    lang() {
      this.selectedLang = this.$i18n.locale
    },
  },
  mounted() {
    //initial locale cookie
    this.$cookies.config('7d', '', '', false)
    if (!this.$cookies.get('locale')){
      this.$cookies.set('locale', this.$i18n.locale)
      this.selectedLang = this.$i18n.locale
    } else {
      this.selectedLang = this.$cookies.get('locale')
    }
    this.convertLocales()
    this.initGetApp()
    
  },
  methods: {
    changeLocale(locale) {
      this.selectedLang = locale
      //this.$i18n.locale = locale
      this.$router.push({ params: { lang: locale } })
      this.$cookies.config('7d', '', '', false)
      this.$cookies.set('locale', locale)
    },
    convertLocales() {
      this.languageOptions.forEach((el) => this.localeObjectArray.push({ name: el.toUpperCase(), value: el }))
    },
    initGetApp() {
      if (this.project == 'eKool') {
        this.showGetApp = true
      }
      //detect users device
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (/android/i.test(userAgent)) {
        this.device = 'android'
        this.appLink = 'https://play.google.com/store/apps/details?id=eu.ekool.app&hl=en&gl=US'
        this.imageSrc = require(`../assets/google-play.png`)
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.device = 'ios'
        this.appLink = 'https://apps.apple.com/ee/app/mkool/id890813955'
        this.imageSrc = require(`../assets/app-store.png`)
      }
    },
  },
}
</script>
