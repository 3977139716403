import * as api from '../api'
import { defineStore } from 'pinia'

export const useThirdPartyPermissionsStore = defineStore('thirdPartyPermissions', {
  state: () => ({
    thirdPartyBusy: true,
    permissionData: {},
    thirdPartyRequestBusy: false,
  }),

  actions: {
    // Get third-party data
    async getThirdPartyData() {
      this.thirdPartyBusy = true
      try {
        const response = await api.getThirdPartyData()
        this.permissionData = response.data
      } catch (error) {
        // Handle error, if any
      } finally {
        this.thirdPartyBusy = false
      }
    },

    // Handle third-party request (accept)
    acceptThirdPartyRequest() {
      this.thirdPartyRequestBusy = true
    },

    // Handle third-party request (deny)
    denyThirdPartyRequest() {
      this.thirdPartyRequestBusy = true
    },
  },

  getters: {
    permissionDataEdited: (state) => {
      let editedPermissions = state.permissionData ? state.permissionData : []
      editedPermissions.scopes.map((scope) => {
        scope.icon = 'icon-hard-drive'
        return scope
      })
      return editedPermissions
    },
  },
})