import { defineStore } from 'pinia'

export const useCFTurnstileStore = defineStore('cfTurnstile', {
  state: () => ({
    CFTurnstileToken: null,
    CFTurnstileWidgetId: null,
    disableButton: true,
    CFTurnstileScriptLoaded: false,
  }),

  actions: {
    setCFTurnstileToken(value) {
      this.CFTurnstileToken = value
    },
    setCFTurnstileWidgetId(value) {
      this.CFTurnstileWidgetId = value
    },
    setDisableButton(value) {
      this.disableButton = value
    },
    setCFTurnstileScriptLoaded(value) {
      this.CFTurnstileScriptLoaded = value
    },
  },
})