import { defineStore } from 'pinia'
import * as api from '../api'

export const useCardStore = defineStore('card', {
  state: () => ({
    cardBusy: false,
    cardError: false,
    cardSuccess: false,
    cardErrors: undefined,
    cardMultipleAccounts: false,
    cardAccounts: undefined,
    cardUser: undefined,
  }),

  actions: {
    async cardLogin() {
      this.cardBusy = true
      this.cardError = false
      this.cardSuccess = false
      this.cardErrors = undefined
      this.cardMultipleAccounts = false
      this.cardAccounts = undefined
      this.cardUser = undefined

      try {
        const response = await api.loginIdCard()
        if (response.success) {
          this.cardSuccess = true
          if (response.multiple_accounts) {
            this.cardMultipleAccounts = true
            this.cardAccounts = response.accounts
          }
        }
        return response
      } catch (error) {
        this.cardError = true
        if(error && error.errors) {
          this.cardErrors = error.errors
        }
        return error
      } finally {
        this.cardBusy = false
      }
    },

    async cardRegister() {
      this.cardBusy = true

      try {
        const response = await api.registerIdCard()
        if (response.success) {
          this.cardSuccess = true
          if (response.multiple_accounts) {
            this.cardMultipleAccounts = true
            this.cardAccounts = response.accounts
          } else if (response.user) {
            this.cardUser = response.user
          }
        }
        return response
      } catch (error) {
        this.cardError = true
        if(error && error.errors) {
          this.cardErrors = error.errors
        }
        return error
      } finally {
        this.cardBusy = false
      }
    },
  },
})