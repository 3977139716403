<template>
  <div class="view-content__wrapper">
    <div class="view-content d-block d-md-flex align-center justify-center mx-auto">
      <div
        v-if="type == 'registrationHash'"
        class="registration"
      >
        <div class="text-center mb-8">
          <v-icon size="70">
            icon-exclamation text-warning
          </v-icon>
        </div>
        <p
          class="headline-4 text-center mx-10 mb-3"
          :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
        >
          {{ $t('registration_message.user_already_exists') }}
        </p>
        <p
          class="body-3 text-center mb-6"
          :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
        >
          {{ $t('registration_message.connect_with_user') }}
        </p>
        <div class="text-center">
          <e-btn
            variant="link"
            size="small"
            :block="false"
            class="mb-4"
            @click="proceed()"
          >
            {{ $t('registration_message.register_new_user') }}
          </e-btn>
        </div>
        <e-btn
          class="callout-3"
          :block="true"
          :to="'/' + $i18n.locale"
          variant="primary"
          size="large"
        >
          {{ $t('registration_message.login_existing_user') }}
        </e-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useSetupAccountStore } from '@/store'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      type: undefined,
      hash: undefined,
      message: '',
      thirdParty: false,
    }
  },
  computed: {
    ...mapState(useSetupAccountStore, ['proceedRegistrationErrors', 'proceedRegistrationSuccess', 'setupAccountHash']),
  },
  mounted() {
    if (this.$route.params.type) {
      this.type = this.$route.params.type
    } else if (this.$route.query.type) {
      this.type = this.$route.query.type
    } else if (window.history.state.type) {
      this.type = window.history.state.type
    }

    if (this.type == undefined) {
      this.$router.replace({
        name: 'login',
      })
    }

    if (this.$route.params.thirdParty) {
      this.thirdParty = this.$route.params.thirdParty
    } else if (this.$route.query.thirdParty) {
      this.thirdParty = this.$route.query.thirdParty
    } else if (window.history.state.thirdParty) {
      this.thirdParty = window.history.state.thirdParty
    }
  },
  methods: {
    ...mapActions(useSetupAccountStore, ['proceedRegistration']),
    proceed() {
      var hash = this.setupAccountHash
      this.proceedRegistration(hash).then(() => {
        if (this.proceedRegistrationSuccess) {
          if (this.thirdParty) {
            this.$router.push({
              name: 'welcome',
            })
          } else {
            this.$router.push({
              name: 'success',
              state: {
                email: this.email,
                type: 'registration',
              },
            })
          }
        } else if (!this.proceedRegistrationSuccess && this.proceedRegistrationErrors) {
          this.$router.replace({
            name: 'login',
            query: {
              error: 'repeating_errors.' + this.proceedRegistrationErrors,
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
