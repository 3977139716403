<template>
  <div class="d-flex flex-column align-center">
    <span
      class="body-2 text-center"
      :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
    >
      {{ $t('two_factor.trust_device_description') }}
    </span>
    <div
      class="d-flex align-center justify-content-center mt-8 g-4"
      style="width: 100%; gap: 16px"
    >
      <e-btn
        style="flex: 0 50%"
        variant="tertiary"
        size="large"
        @click="proceed(true)"
      >
        {{ $t('two_factor.trust_this_device') }}
      </e-btn>
      <e-btn
        style="flex: 0 50%"
        variant="primary"
        size="large"
        @click="proceed(false)"
      >
        {{ $t('two_factor.dont_trust') }}
      </e-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usePasswordStore, useGlobalStore } from '@/store'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { useToast } from "@k12tech/ekool-vue-framework/src/plugins/toast"
export default {
  components: {
    EBtn,
  },
  setup() {
    const { toast } = useToast()
    return { toast }
  },
  computed: {
    ...mapState(useGlobalStore, ['mobileView']),
    ...mapState(usePasswordStore, [
      'twoFactorRememberBusy',
      'twoFactorRememberSuccess',
      'twoFactorRememberError',
      'twoFactorRememberErrors',
      'rememberRequestRequired',
      'termsRequired',
    ]),
  },
  methods: {
    ...mapActions(usePasswordStore, ['twoFactorRemember', 'setUser']),
    proceed(val) {
      const remember = val
      this.twoFactorRemember({ remember }).then((response) => {
        if (this.termsRequired) {
          this.$router.push({
            name: 'agreeToTerms',
          })
        }
        if (this.twoFactorRememberSuccess && !this.termsRequired) {
          if (this.miniView) {
            this.postLoginMessage('success')
            return
          }
          if (response.redirect) {
            window.top.location.href = response.url
          } else {
            this.setUser({ url: response.url })
          }
        } else if (this.twoFactorRememberError && this.twoFactorRememberErrors == 'user_not_found') {
          this.$router.push({
            name: 'login',
          })
        } else if (this.twoFactorRememberError && this.twoFactorRememberErrors !== undefined) {
          this.toast.error(this.$i18n.t(`password_login.${this.twoFactorErrors}`))
        } else if (this.twoFactorRememberErrors) {
          this.toast.error(this.$i18n.t(`password_login.submit_error`))
        }
      })
    },
  },
}
</script>
