<template>
  <v-app>
    <v-container
      id="app"
      :fluid="true"
    >
      <metainfo>
        <template #title="{ content }">
          {{ content ? `${content}` : pageTitle }}
        </template>
      </metainfo>
      <v-container
        :fluid="true"
        class="page__container flex"
        :class="{ 'iframe-padding': iframeView }"
      >
        <v-row
          class="page__content"
          :class="[{ 'align-content-center': !showFooter && !showNav }, { 'justify-center': showIlustration }]"
        >
          <v-col
            v-if="showNav"
            class="nav-container pa-0"
            cols="12"
          >
            <Navbar />
          </v-col>
          <div class="page__content__centered">
            <v-col
              class="align-center justify-center"
              :class="showIlustration ? 'd-none d-lg-flex' : 'd-none'"
              cols="12"
              lg="6"
            >
              <div class="d-flex align-content-center">
                <img
                  v-if="$route.name == 'maintenance'"
                  class="ilustration"
                  src="./assets/father_and_son_fixing_bike@2x.png"
                  alt="father and son fixing bike"
                >
                <img
                  v-else-if="!$vuetify.theme.dark"
                  class="ilustration"
                  src="./assets/illustration.svg"
                  alt="ilustration"
                >
                <img
                  v-else-if="$vuetify.theme.dark"
                  class="ilustration"
                  src="./assets/illustration-dark.svg"
                  alt="ilustration"
                >
              </div>
            </v-col>
            <v-col
              cols="12"
              class="px-4"
              :lg="showIlustration ? 6 : 12"
            >
              <router-view />
            </v-col>
          </div>

          <v-container
            v-if="showFooter"
            class="px-0 pt-0 pt-sm-3 pb-0"
            :fluid="true"
          >
            <Footer />
          </v-container>
        </v-row>
      </v-container>
    </v-container>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { mapState, mapActions } from 'pinia'
import { useGlobalStore } from '@/store/global'
import { useLayoutStore } from '@/store/layout'
import { useCFTurnstileStore } from '@/store/cfTurnstile'
import { getEnv } from './env'
import { useMeta } from 'vue-meta'

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      iframe: false,
      permissions: false,
      project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
      usePasswordStore: null, //Store variable
    }
  },
  computed: {
    ...mapState(useGlobalStore, ['miniView', 'mobileView']),
    ...mapState(useLayoutStore, ['showFooter', 'showNav', 'showIlustration', 'iframeView']),
    ...mapState(useCFTurnstileStore, ['CFTurnstileWidgetId', 'disableButton']),
    pageTitle() {
      return this.project == 'eKool' ? this.$i18n.t('browser_title.ekool_title') : this.$i18n.t('browser_title.mykoob_title')
    },
  },
  watch: {
    disableButton(val) {
      if (val) {
        if (this.mobileView) {
          setTimeout(() => {
            this.setDisableButton(false)
          }, 5000)
        }
      }
    }
  },
  created() {
    window.onloadTurnstileCallback = () => {
      this.setCFTurnstileScriptLoaded(true)
    }
  },
  mounted() {
    this.initTheme()
    if (this.$route.name == 'login-mini' || this.$route.name == 'thirdParty') {
      this.setShowFooter(false)
      this.setShowNav(false)
      this.setShowIlustration(false)
      this.setMiniView(true)
      this.setMiniViewFunction(this.postLoginMessage)
    } else if (this.$route.name == 'iframe') {
      this.setIframeView(true)
      this.setShowFooter(false)
      this.setShowNav(false)
    } else if (this.$route.name == 'mobile') {
      this.setMobileView(true)
      this.setShowFooter(false)
      this.setShowNav(false)
      this.setShowIlustration(false)
      setTimeout(() => {
        this.setDisableButton(false)
      }, 5000)
    } else if (this.$route.name == 'verifyData' || this.$route.name == 'twoFactorLink') {
      this.setShowIlustration(false)
    }
    if (this.$route.name == 'permissions') {
      this.permissions = true
    }
    useMeta({
      title: '',
    })
    //redirect parent if app in iframe
    if (window !== window.parent) {
      window.parent.location.href = getEnv('VUE_APP_FRONTEND_APP_URL')
      window.location.href = getEnv('VUE_APP_FRONTEND_APP_URL')
    }
    //workaround for importing usePasswordStore error
    import('@/store/password').then((module) => {
      this.usePasswordStore = module.usePasswordStore;

      // Now that the store is loaded, you can call its actions
      const passwordStore = this.usePasswordStore();

      // Example of calling an action after store is loaded
      passwordStore.initSession()
    });
  },
  methods: {
    ...mapActions(useCFTurnstileStore, ['setDisableButton', 'setCFTurnstileScriptLoaded']),
    ...mapActions(useLayoutStore, ['setShowFooter', 'setShowNav', 'setShowIlustration', 'setIframeView']),
    ...mapActions(useGlobalStore, ['setMiniView', 'setMiniViewFunction', 'setMobileView']),
    //...mapActions(usePasswordStore, ['initSession']),
    setDarkMode(val, saveLocalStorage) {
      this.$vuetify.theme.dark = val
      this.$vuetify.theme.global.name = val ? 'dark' : 'light'
      if (saveLocalStorage) {
        localStorage.setItem('theme', val == true ? 'dark' : 'light')
      }
    },
    initTheme() {
      let queryTheme = this.$route.query.theme || undefined
      let localStorageTheme = localStorage.getItem('theme')
      if (queryTheme) {
        this.setDarkMode(queryTheme == 'dark', true)
      } else if (localStorageTheme) {
        this.setDarkMode(localStorageTheme == 'dark', false)
      } else if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          return this.setDarkMode(true, false)
        } else {
          return this.setDarkMode(false, false)
        }
      }
    },
  },
}
</script>

<style lang="scss">
$font-size-base: 1.6rem;
$spacer: 1.6rem;

#app {
  margin: 0;
  padding: 0;
  border: 0;
  min-height: inherit;
  width: 100%;

  //@media (min-width: map-get($grid-breakpoints, md)) {
  display: flex;
  flex-flow: column;
  //}

  //justify-content: stretch;
  //background-color: $bg-color;
  //IE 11 image fix
  -ms-interpolation-mode: bicubic;
}

body {
  margin: 0;
  min-height: inherit;
}
</style>
