import { defineStore } from 'pinia'
import * as api from '../api'

export const useVerifyDataStore = defineStore('verifyData', {
  state: () => ({
    getRedirectBusy: false,
    getRedirectSuccess: false,
  }),

  actions: {
    // Action to get redirect
    async getRedirect(target) {
      this.getRedirectBusy = true
      try {
        const response = await api.getRedirect(target)
        this.getRedirectSuccess = response.success
        return response
      } catch (error) {
        this.getRedirectBusy = false
        return error
      }
    },
  },
})