import { getEnv } from '../env'
import { mapState, mapActions } from 'pinia'
import { useCFTurnstileStore, useGlobalStore } from '../store'
export const turnstileMixin = {
    data() {
      return {
        turnstileToken: null, // Store the CAPTCHA token
        turnstileWidgetId: null, // Store the widget ID
        showTurnstileWidget: false, // Control whether the widget is shown
        timeoutId: null, // Store the timeout ID for managing retries
        resetIntervalId: null, // Store the interval ID for resetting the Turnstile widget
      };
    },
    computed: {
      ...mapState(useGlobalStore, ['mobileView']),
    },
    beforeUnmount() {
        this.removeTurnstile() // Clean up the widget and timers
        this.resetTimeout() // Clear any pending timeouts
        this.stopAutoReset() // Clear the auto-reset interval
    },
    methods: {
      ...mapActions(useCFTurnstileStore, ['setDisableButton']),
      initializeTurnstile(widgetRef, sitekey = getEnv('VUE_APP_FRONTEND_CF_TURNSTILE_SITE_KEY')) {
          this.setupTurnstile(widgetRef, sitekey)
      },
      setupTurnstile(widgetRef, sitekey) {
        this.setDisableButton(true)
        this.turnstileWidgetId = window.turnstile.render(widgetRef, {
          sitekey,
          callback: this.onTurnstileSuccess,
          "expired-callback": this.onTurnstileExpired,
          "error-callback": this.onTurnstileError,
        });
        this.startAutoReset() // Start the auto-reset timer
      },
      onTurnstileSuccess(token) {
        this.turnstileToken = token
        console.log('token', token)
        this.setDisableButton(false)
      },
      onTurnstileExpired() {
        this.turnstileToken = null;
        this.resetTurnstile()
      },
      onTurnstileError() {
        if (this.mobileView) {
          this.setDisableButton(false)
        }
        this.setTimeoutForRetry() // Retry displaying the widget
      },
      resetTurnstile() {
        if (this.turnstileWidgetId) {
          window.turnstile.reset(this.turnstileWidgetId)
        }
      },
      removeTurnstile() {
        if (this.turnstileWidgetId) {
          window.turnstile.remove(this.turnstileWidgetId) // Remove the widget
          this.turnstileWidgetId = null // Clear the widget ID
          this.turnstileToken = null // Clear the token
        }
      },
      setTimeoutForRetry() {
        // Set a 5-second timeout to retry showing the widget
        this.resetTimeout() // Clear any existing timeout
        this.timeoutId = setTimeout(() => {
          this.showTurnstileWidget = true // Retry showing the widget
        }, 5000)
      },
      resetTimeout() {
        // Clear any existing timeout
        if (this.timeoutId) {
          clearTimeout(this.timeoutId)
          this.timeoutId = null;
        }
      },
      startAutoReset() {
        // Reset the Turnstile widget every 295 seconds (4 minutes, 55 seconds)
        this.stopAutoReset() // Ensure no duplicate intervals
        this.resetIntervalId = setInterval(() => {
          this.resetTurnstile()
        }, 295000); // 295 seconds
      },
      stopAutoReset() {
        // Clear the auto-reset interval
        if (this.resetIntervalId) {
          clearInterval(this.resetIntervalId)
          this.resetIntervalId = null
        }
      },
    },
  };