<template>
  <div class="mb-0">
    <div class="text-center py-4 px-10 headline-4">
      {{ $t('x_id.x_id_text') }}
    </div>
    <e-btn
      class="mt-4"
      :block="true"
      :href="link"
      variant="primary"
      size="large"
    >
      {{ $t('password_login.login') }}
    </e-btn>
  </div>
</template>

<script>
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  props: {
    register: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    link() {
      if (this.register) {
        return 'register/x-id'
      } else {
        return '/x-id'
      }
    },
  },
}
</script>
