import { defineStore } from 'pinia'
import * as api from '../api'
import i18n from '../i18n'

export const useMobileStore = defineStore('mobile', {
  state: () => ({
    mobileLoginBusy: false,
    mobileError: false,
    mobileLoggedIn: false,
    mobileSuccess: false,
    mobileErrors: '',
    mobileCodeSent: false,
    mobileCode: '',
    mobileAccounts: undefined,
    mobileUser: undefined,
    mobilePing: true,
    mobilePingBusy: false,
    mobileUrl: null,
    mobileRedirect: null,
  }),

  actions: {
    loginMobileUser() {
      this.mobileLoginBusy = true
    },

    registerMobileUser() {
      this.mobileLoginBusy = true
    },

    resetMobileState() {
      this.mobileLoginBusy = false
      this.mobileError = false
      this.mobileLoggedIn = false
      this.mobileSuccess = false
      this.mobileErrors = ''
      this.mobileCodeSent = false
      this.mobileCode = ''
      this.mobileAccounts = undefined
      this.mobileUser = undefined
      this.mobilePing = true
      this.mobileUrl = null
      this.mobileRedirect = null
    },

    loginMobileUserSuccess(response) {
      this.mobileLoginBusy = false
      this.mobileSuccess = response.success
      this.mobilePing = true

      if (response.success) {
        this.mobileCode = response.verification_code
        this.mobileCodeSent = true
      }
    },

    registerMobileUserSuccess(response) {
      this.mobileLoginBusy = false
      this.mobileSuccess = response.success
      this.mobilePing = true
      if (response.success) {
        this.mobileCode = response.verification_code
        this.mobileCodeSent = true
      }
    },

    loginMobileUserFail(error) {
      this.mobileLoginBusy = false
      this.mobileError = true
      this.mobileErrors = error.errors
    },

    registerMobileUserFail(error) {
      this.mobileLoginBusy = false
      this.mobileLoginBusy = false
      this.mobileError = true
      this.mobileErrors = error.errors
    },

    pingMobile() {
      this.mobilePingBusy = true
    },

    pingMobileSuccess(response) {
      this.mobilePingBusy = false
      this.mobileSuccess = response.success
      if (this.mobileSuccess) {
        this.mobilePing = false
        if (response.login_method == 'mobile_id') {
          this.mobileLoggedIn = true
          this.mobileCodeSent = false
        }
      }
      this.mobileLoginBusy = false
      this.mobileAccounts = response.accounts
      this.mobileUser = response.user
      if (response.errors) {
        this.mobileError = true
        this.mobileErrors = response.errors
        store.commit('changeError', i18n.t(this.mobileErrors))
        store.commit('showError')
        this.mobileCodeSent = false
      }
      this.mobileUrl = response.url
      this.mobileRedirect = response.redirect
    },

    pingMobileFail(error) {
      this.mobilePingBusy = false
      this.mobilePing = false
      this.mobileLoginBusy = false
      this.mobileCodeSent = false
      this.mobileError = true
      this.mobileErrors = error.errors
    },

    cancelMobilePing() {
      this.mobileCodeSent = false
      this.mobilePing = false
    },

    async loginCardMobile({ personalCode, phoneNumber }) {
      this.loginMobileUser()
      try {
        const response = await api.loginCardMobile(personalCode, phoneNumber)
        this.loginMobileUserSuccess(response)
        return response
      } catch (error) {
        this.loginMobileUserFail(error)
        return error
      }
    },

    async registerCardMobile({ personalCode, phoneNumber }) {
      this.registerMobileUser()
      try {
        const response = await api.registerCardMobile(personalCode, phoneNumber)
        this.registerMobileUserSuccess(response)
        return response
      } catch (error) {
        this.registerMobileUserFail(error)
        return error
      }
    },

    async loginPingMobile() {
      this.pingMobile()
      try {
        const response = await api.loginCardMobileCheck()
        this.pingMobileSuccess(response)
        return response
      } catch (error) {
        this.pingMobileFail(error)
        return error
      }
    },

    async registerPingMobile() {
      this.pingMobile()
      try {
        const response = await api.registerCardMobileCheck()
        this.pingMobileSuccess(response)
        return response
      } catch (error) {
        this.pingMobileFail(error)
        return error
      }
    },
  },
})