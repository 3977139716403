<template>
  <div class="d-flex flex-column align-center">
    <div class="d-flex flex-column align-center">
      <span
        v-if="method == 'otp'"
        class="body-2 text-center"
        :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
      >
        {{ $t('two_factor.otp_description') }}
      </span>
      <i18n-t
        v-else-if="method == 'email'"
        keypath="two_factor.email_description"
        tag="span"
        class="body-2 text-center"
        :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
      >
        <template #email>
          <span :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale200' : 'text-grayscale800'">{{
            twoFactorRequestEmail
          }}</span>
        </template>
        <template #x>
          {{ 30 }}
        </template>
      </i18n-t>

      <i18n-t
        v-else-if="method == 'sms'"
        keypath="setup_account.code_description"
        tag="span"
        class="body-2 text-center"
        :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
      >
        <template #phone>
          <span :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale200' : 'text-grayscale800'">{{
            twoFactorRequestPhoneNumber
          }}</span>
        </template>
        <template #x>
          {{ 30 }}
        </template>
      </i18n-t>

      <span
        v-else-if="method == 'recovery'"
        class="body-2 text-center"
        :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
      >
        {{ $t('two_factor.recovery_description') }}
      </span>
    </div>
    <div
      class="d-flex flex-column align-center my-8"
      style="width: 100%"
    >
      <div
        id="otp-container"
        class="d-flex"
        :class="{ 'mb-3': method !== 'otp' && method !== 'recovery' }"
      >
        <e-verification-code-input
          v-model="code"
          :disabled="twoFactorBusy"
          :input-mode="method === 'recovery' ? 'text': 'numeric'"
          :allowed-chars="method === 'recovery' ? /[^a-zA-Z0-9]/g: /\D/g"
          @filled="send"
        />
      </div>
      <div
        v-if="twoFactorBusy"
        class="code-validation-loader mt-3"
      >
        <e-circular-loading
          size="16"
          :width="2"
        />
        <span class="caption-1 ml-2 text-primary">{{ $t('two_factor.verifying_code') }}</span>
      </div>
      <div
        v-if="twoFactorError"
        class="code-validation-loader mt-3"
      >
        <e-icon size="14">
          icon-warning-hint-filled text-danger400
        </e-icon>
        <span class="caption-1 ml-1 text-danger400">{{ $t('two_factor.code_not_valid') }}</span>
      </div>
      <template v-if=" method !== 'otp' &&method !== 'recovery' && !twoFactorBusy && !twoFactorError">
        <div
          v-if="requestNew"
          class="d-flex justify-content-center mt-3"
        >
          <span class="caption-1 text-grayscale400">{{ $t('setup_account.request_new_code', { x: timeLeft }) }}</span>
        </div>
        <div
          v-else
          class="d-flex justify-content-center mt-3"
        >
          <span class="caption-1 text-grayscale400 mr-1">{{ $t('setup_account.didnt_get_code') }}</span>
          <span
            class="caption-1 text-primary"
            style="cursor: pointer"
            @click="resendCode()"
          >{{
            $t('setup_account.resend_code')
          }}</span>
        </div>
      </template>
    </div>
    <e-btn
      variant="tertiary"
      @click="cancel"
    >
      {{ $t('terms.cancel') }}
    </e-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useLayoutStore, usePasswordStore } from '@/store'
import { getEnv } from '../env'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { EVerificationCodeInput } from "@k12tech/ekool-vue-framework/src/components/EVerificationCodeInput"
import { ECircularLoading } from "@k12tech/ekool-vue-framework/src/components/ECircularLoading"
import { EIcon } from "@k12tech/ekool-vue-framework/src/components/EIcon"
import { useToast } from "@k12tech/ekool-vue-framework/src/plugins/toast"
export default {
  components: {
    EBtn,
    EVerificationCodeInput,
    ECircularLoading,
    EIcon,
  },
  props: {
    method: String,
  },
  setup() {
    const { toast } = useToast()
    return { toast }
  },
  data() {
    return {
      code: '',
      requestNew: false,
      timeLeft: 0,
      timeoutTimer: false,
      ping: false,
    }
  },
  computed: {
    ...mapState(useLayoutStore, [
      'iframeView',
    ]),
    ...mapState(usePasswordStore, [
      'twoFactorAvailableMethods',
      'twoFactorRequestBusy',
      'twoFactorRequestSuccess',
      'twoFactorRequestError',
      'twoFactorRequestErrors',
      'twoFactorRequestTimeout',
      'twoFactorRequestEmail',
      'twoFactorRequestPhoneNumber',
      'twoFactorBusy',
      'twoFactorError',
      'twoFactorErrors',
      'twoFactorSuccess',
      'termsRequired',
      'rememberRequestRequired',
      'pingTwoFactorBusy',
      'pingTwoFactorSuccess',
      'pingTwoFactorErrors',
      'pingTwoFactorPending',
    ]),
  },
  watch: {
    twoFactorRequestTimeout() {
      this.countdown()
    },
    twoFactorError(val) {
      if (val) {
        setTimeout(() => {
          this.resetTwoFactorError()
        }, 3000)
      }
    },
  },
  mounted() {
    if (this.method == 'email' || (this.method == 'sms' && this.twoFactorAvailableMethods.length == 1)) {
      this.resendCode()
    }
    this.requestNew = false
    this.countdown()
  },
  beforeUnmount() {
    clearInterval(this.ping)
  },
  methods: {
    ...mapActions(usePasswordStore, ['resetTwoFactorError','twoFactorRequest', 'sendTwoFactor', 'pingTwoFactor', 'setUser']),
    resendCode() {
      let method = this.method
      this.twoFactorRequest({ method }).then(() => {
        if (method == 'email') {
          this.pingMethod()
        }
      })
    },
    countdown() {
      clearInterval(this.timeoutTimer)
      this.timeLeft = this.twoFactorRequestTimeout
      if (this.timeLeft > 0) {
        this.requestNew = true
      }
      this.timeoutTimer = setInterval(() => {
        if (this.timeLeft == 0) {
          clearInterval(this.timeoutTimer)
          this.requestNew = false
        } else {
          this.timeLeft -= 1
        }
      }, 1000)
    },
    send() {
      let twoFactorCode = this.code
      let method = this.method
      this.sendTwoFactor({ twoFactorCode, method }).then((response) => {
        if (this.termsRequired) {
          this.$router.push({
            name: 'agreeToTerms',
          })
        }
        if (this.twoFactorSuccess && !this.termsRequired && !this.rememberRequestRequired) {
          if (this.miniView) {
            this.postLoginMessage('success')
            return
          }
          if (response.redirect) {
            window.top.location.href = response.url
          } else {
            this.setUser({ url: response.url })
          }
        } 
        this.code = ''
      })
    },
    cancel() {
      window.top.location.href = getEnv('VUE_APP_FRONTEND_API_URL') + '/logout'
    },
    pingMethod() {
      let method = this.method
      this.ping = setInterval(() => {
        if (this.pingTwoFactorPending && !this.pingTwoFactorBusy) {
          this.pingTwoFactor({ method }).then((response) => {
            if (this.termsRequired) {
              this.$router.push({
                name: 'agreeToTerms',
              })
            }
            if (this.pingTwoFactorSuccess && !this.termsRequired && !this.rememberRequestRequired) {
              if (this.miniView) {
                this.postLoginMessage('success')
                return
              }
              if (response.redirect) {
                window.top.location.href = response.url
              } else {
                this.setUser({ url: response.url })
              }
            }
          })
        } else {
          if (!this.pingTwoFactorSuccess && this.pingTwoFactorErrors) {
            this.toast.error(this.$i18n.t(`password_login.${this.pingTwoFactorErrors}`))
            clearInterval(this.ping)
          }
        }
      }, 5000)
    },
  },
}
</script>
