<template>
  <div class="view-content__wrapper">
    <div class="view-content view-content--wide d-block d-sm-flex">
      <div class="verify-data mx-auto">
        <div class="invite-teacher__header">
          <img
            v-if="$vuetify.theme.global.name == 'dark'"
            class="invite-teacher__logo"
            src="../assets/Teacher_invite_dark.svg"
            alt="eKool logo"
          >
          <img
            v-else
            class="invite-teacher__logo"
            src="../assets/Teacher_invite.svg"
            alt="eKool logo"
          >
          <h1
            v-if="teacherInviteInfo && teacherInviteInfo.school_name"
            class="headline-1 text-graybase mb-1 text-center"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('teacher_invite_title', { school: teacherInviteInfo.school_name }) }}
          </h1>
          <p
            v-if="teacherInviteInfo && teacherInviteInfo.school_name"
            class="body-3 text-center mb-0"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
          >
            {{
              $t('teacher_invite_desc_1', { email: teacherInviteInfo.creator, school: teacherInviteInfo.school_name })
            }}
          </p>
          <p
            class="body-3 text-center mb-0"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale600'"
          >
            {{ $t('teacher_invite_desc_2') }}
          </p>
        </div>
        <div class="invite-teacher__footer">
          <e-btn
            variant="primary"
            :block="true"
            size="large"
            @click="acceptInvitation()"
          >
            {{ $t('teacher_invite_accept') }}
          </e-btn>
          <e-btn
            variant="ghost"
            :block="true"
            size="large"
            @click="declineInvitation()"
          >
            {{ $t('teacher_invite_decline') }}
          </e-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useTeacherInviteStore } from '@/store'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { useToast } from "@k12tech/ekool-vue-framework/src/plugins/toast"
export default {
  components: {
    EBtn,
  },
  setup() {
    const { toast } = useToast()
    return { toast }
  },
  data() {
    return {
      teacherInviteInfoFail: false,
      inviteHash: null,
    }
  },
  computed: {
    ...mapState(useTeacherInviteStore, [
      'getTeacherInviteInfoBusy',
      'getTeacherInviteInfoSuccess',
      'teacherInviteInfo',
      'sendTeacherInviteStatusBusy',
      'sendTeacherInviteStatusSuccess',
      'teacherInviteStatus',
    ]),
  },
  mounted() {
    this.getInviteInfo({ hash: this.$route.query.hash }).then(() => {
      if (!this.teacherInviteInfo) {
        this.$router.push({
          name: 'teacherinviteerror',
        })
      }
      this.inviteHash = this.$route.query.hash
    })
  },
  methods: {
    ...mapActions(useTeacherInviteStore, ['sendInviteStatus', 'getInviteInfo']),
    acceptInvitation() {
      this.sendInviteStatus({ hash: this.inviteHash, status: 1 }).then(() => {
        if (this.teacherInviteStatus) {
          this.$router.push({
            name: 'teacherinviteaccept',
          })
        } else {
          this.toast(this.$i18n.t(`teacher_invite_error_toast`))
        }
      })
    },
    declineInvitation() {
      this.sendInviteStatus({ hash: this.inviteHash, status: 0 }).then(() => {
        if (this.teacherInviteStatus) {
          this.$router.push({
            name: 'teacherinvitedecline',
          })
        } else {
          this.toast(this.$i18n.t(`teacher_invite_error_toast`))
        }
      })
    },
  },
}
</script>
