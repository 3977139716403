import { defineStore } from 'pinia'
import * as api from '../api'

export const useTeacherInviteStore = defineStore('teacherInvite', {
  state: () => ({
    getTeacherInviteInfoBusy: false,
    getTeacherInviteInfoSuccess: false,
    teacherInviteInfo: undefined,
    sendTeacherInviteStatusBusy: false,
    sendTeacherInviteStatusSuccess: false,
    teacherInviteStatus: undefined,
  }),

  actions: {
    // get invite info
    async getInviteInfo({ hash }) {
      this.getTeacherInviteInfoBusy = true
      try {
        const response = await api.getTeacherInviteInfo(hash)
        this.getTeacherInviteInfoSuccess = true
        this.teacherInviteInfo = response.data
        return response
      } catch (error) {
        this.getTeacherInviteInfoFail()
        return error
      } finally {
        this.getTeacherInviteInfoBusy = false
      }
    },

    // send invite status
    async sendInviteStatus({ hash, status }) {
      this.sendTeacherInviteStatusBusy = true
      try {
        await api.sendTeacherInviteStatus(hash, status)
        this.sendTeacherInviteStatusSuccess = true
        this.teacherInviteStatus = true
      } catch (error) {
        this.sendTeacherInviteStatusFail()
      } finally {
        this.sendTeacherInviteStatusBusy = false
      }
    },

    // Helper methods for mutation-like behavior
    getTeacherInviteInfoFail() {
      this.getTeacherInviteInfoBusy = false
      this.teacherInviteInfo = undefined
    },
    sendTeacherInviteStatusFail() {
      this.sendTeacherInviteStatusBusy = false
      this.sendTeacherInviteStatusSuccess = false
      this.teacherInviteStatus = false
    }
  },
})