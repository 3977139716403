<template>
  <div class="view-content__wrapper">
    <div class="view-content">
      <div class="d-flex justify-space-between align-center mb-6">
        <div class="headline-1">
          {{ $t('school_registration.' + currentStep.name) }}
        </div>
        <div>
          <v-progress-circular
            class="stepper-indicator"
            :model-value="currentStep.progressValue"
            :width="1.8"
            :size="46"
          >
            <span
              :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
              class="caption-1"
            >{{ currentStep.order }} / {{ registrationSteps.length }}</span>
          </v-progress-circular>
        </div>
      </div>
      <v-form
        v-if="currentStep.order == 1"
        ref="formA"
        validate-on="submit lazy"
        @submit.prevent="forward"
      >
        <div class="pb-4 pt-1">
          <e-text-field
            v-model="schoolName"
            :rules="[rules.required($i18n.t('fields.schoolName'))]"
            hide-details="auto"
            size="large"
            :label="$t('school_registration.schools_name')"
            name="schoolName"
          />
        </div>
        <div class="pb-4">
          <e-text-field
            v-model="registrationNumber"
            :rules="[rules.required($i18n.t('fields.registrationNumber'))]"
            hide-details="auto"
            size="large"
            :label="$t('school_registration.registration_number')"
            name="registrationNumber"
          />
        </div>
        <div class="pb-4">
          <e-select
            v-model="schoolType"
            size="large"
            :rules="[rules.required($i18n.t('fields.schoolType'))]"
            hide-details="auto"
            :label="$i18n.t('school_registration.schools_type')"
            :items="schoolTypes"
            :placeholder="$i18n.t('setup_account.select')"
            item-title="name"
            item-value="value"
            name="schoolType"
          />
        </div>
        <div class="pb-4">
          <e-text-field
            v-model="street"
            :rules="[rules.required($i18n.t('fields.street'))]"
            hide-details="auto"
            size="large"
            :label="$t('setup_account.street')"
            name="street"
          />
        </div>
        <div class="pb-4">
          <e-text-field
            v-model="city"
            :rules="[rules.required($i18n.t('fields.city'))]"
            hide-details="auto"
            size="large"
            :label="$t('setup_account.city')"
            name="city"
          />
        </div>
        <div class="pb-4">
          <e-select
            v-model="country"
            size="large"
            :rules="[rules.required($i18n.t('fields.country'))]"
            hide-details="auto"
            :label="$i18n.t('setup_account.country')"
            :items="countries"
            :placeholder="$i18n.t('setup_account.select')"
            item-title="Name"
            item-value="Code"
            name="country"
          />
        </div>
        <e-btn
          class="mt-4"
          :block="true"
          type="submit"
          variant="primary"
          size="large"
        >
          {{ $t('setup_account.forward') }}
        </e-btn>
        <div class="mt-4 text-center">
          <e-btn
            variant="link"
            size="small"
            :block="false"
            :to="'/' + $i18n.locale"
            exact
          >
            {{ $t('forgot_password.back_to_frontpage') }}
          </e-btn>
        </div>
      </v-form>
      <v-form
        v-if="currentStep.order == 2"
        ref="formB"
        validate-on="submit lazy"
        @submit.prevent="
          () => {
            schoolRegisterBusy ? '' : handleCheckPersonal()
          }
        "
      >
        <div class="pb-4 pt-1">
          <e-text-field
            v-model="firstName"
            :rules="[rules.required($i18n.t('fields.firstName'))]"
            hide-details="auto"
            size="large"
            :label="$i18n.t('setup_account.first_name')"
            name="firstName"
          />
        </div>
        <div class="pb-4">
          <e-text-field
            v-model="lastName"
            :rules="[rules.required($i18n.t('fields.lastName'))]"
            hide-details="auto"
            size="large"
            :label="$i18n.t('setup_account.last_name')"
            name="lastName"
          />
        </div>
        <div class="pb-4">
          <e-text-field
            v-model="email"
            :rules="[rules.required($i18n.t('fields.email')), rules.email($i18n.t('fields.email'))]"
            hide-details="auto"
            size="large"
            :label="$i18n.t('setup_account.email')"
            name="email"
          />
        </div>
        <div class="pb-4">
          <e-text-field
            v-model="number"
            :rules="[rules.required($i18n.t('fields.phone'))]"
            hide-details="auto"
            size="large"
            :label="$i18n.t('mobile_id_login.phone_number')"
            name="phone"
          />
        </div>
        <div class="mt-2 d-flex justify-space-between align-center">
          <div>
            <e-btn
              variant="link"
              size="small"
              :block="false"
              @click="back()"
            >
              {{ $t('setup_account.go_back') }}
            </e-btn>
          </div>

          <e-btn
            :block="false"
            type="submit"
            :loading="schoolRegisterBusy"
            variant="primary"
            size="large"
          >
            {{ $t('school_registration.register_school') }}
          </e-btn>
        </div>
        <p class="body-3 text-center mt-4">
          <span class="text-grayscale400">{{ $t('school_registration.school_terms_text') }} </span>
          <a
            class="text-decoration-none text-primary400"
            :href="
              $i18n.locale == 'et'
                ? 'https://www.ekool.eu/teenusetingimused'
                : 'https://www.ekool.eu/en/terms-of-service'
            "
            target="_blank"
          >{{ $t('school_registration.school_terms_link_1') }}
          </a>
          <span class="text-grayscale400">{{ $t('school_registration.and') }} </span>
          <a
            class="text-decoration-none text-primary400"
            :href="'https://ekool.eu/terms/data_processing_contract_' + $i18n.locale + '.html'"
            target="_blank"
          >{{ $t('school_registration.school_terms_link_2') }}</a>
        </p>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useGlobalStore, useSchoolRegisterStore } from '@/store'
import { countries } from '../utils/countries'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ETextField } from '@k12tech/ekool-vue-framework/src/components/ETextField'
import { ESelect } from '@k12tech/ekool-vue-framework/src/components/ESelect'
import { validationRules } from '@/validationRules.js'
export default {
  name: 'SchoolRegister',
  components: {
    EBtn,
    ETextField,
    ESelect,
  },
  data() {
    return {
      spinner: false,
      activeItem: 'school',
      schoolName: '',
      registrationNumber: '',
      schoolType: undefined,
      webpage: '',
      street: '',
      city: '',
      index: '',
      county: '',
      country: undefined,
      countries: countries,
      firstName: '',
      lastName: '',
      email: '',
      number: '',
      currentStep: {},
      registrationSteps: [
        { order: 1, name: 'schools_data', progressValue: 50 },
        { order: 2, name: 'personal_data', progressValue: 100 },
      ],
      rules: validationRules,
    }
  },
  computed: {
    ...mapGetters(useGlobalStore, ['getError',]),
    ...mapState(useSchoolRegisterStore, ['schoolRegisterBusy', 'schoolRegisterError', 'schoolRegisterSuccess', 'schoolRegisterErrors']),
    schoolTypes() {
      return [
        { value: 1, name: this.$i18n.t('school_registration.elementary') },
        { value: 2, name: this.$i18n.t('school_registration.high_school') },
        { value: 3, name: this.$i18n.t('school_registration.primary_school') },
        { value: 8, name: this.$i18n.t('school_registration.vocational') },
        { value: 9, name: this.$i18n.t('school_registration.hobby_school') },
        { value: 10, name: this.$i18n.t('school_registration.local_government') },
        { value: 18, name: this.$i18n.t('school_registration.publisher') },
        { value: 19, name: this.$i18n.t('school_registration.advertising_agency') },
        { value: 20, name: this.$i18n.t('school_registration.user_group') },
      ]
    },
  },
  mounted() {
    this.currentStep = this.registrationSteps[0]
  },
  methods: {
    ...mapActions(useSchoolRegisterStore, ['schoolRegister']),
    ...mapActions(useGlobalStore, ['changeError', 'showError']),
    forward() {
      this.$refs.formA.validate().then((valid) => {
        if (valid.valid) {
          this.currentStep = this.registrationSteps.find((el) => el.order == this.currentStep.order + 1)
        }
      })
    },
    back() {
      this.currentStep = this.registrationSteps.find((el) => el.order == this.currentStep.order - 1)
    },
    handleCheckPersonal() {
      this.$refs.formB.validate().then((valid) => {
        if (valid.valid) {
          this.checkPersonal()
        }
      })
    },
    checkPersonal() {
      if (!this.$refs.formB.isValid) return
      var schoolName = this.schoolName
      var registrationNumber = this.registrationNumber
      var type = this.schoolType
      var street = this.street
      var city = this.city
      var country = this.country
      var firstName = this.firstName
      var lastName = this.lastName
      var email = this.email
      var number = this.number
      this.schoolRegister({
          schoolName,
          registrationNumber,
          type,
          street,
          city,
          country,
          firstName,
          lastName,
          email,
          number,
        })
        .then(() => {
          if (this.schoolRegisterError && this.schoolRegisterErrors !== undefined) {
            this.changeError('school_registration.' + this.schoolRegisterErrors)
            this.showError(true)
          } else if (this.schoolRegisterError) {
            this.$router.push({
              name: 'failed',
              state: {
                type: 'schoolRegister',
              },
            })
          } else if (this.schoolRegisterSuccess) {
            this.$router.push({
              name: 'success',
              state: {
                type: 'school',
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
