import { defineStore } from 'pinia'
import * as api from '../api'

export const useRoleStore = defineStore('roles',{
  state: () => ({
    getRolesBusy: false,
    getRolesError: false,
    getRolesSuccess: false,
    getRolesErrors: undefined,
    rolesData: [],
  }),

  actions: {
    // Action for getting roles
    async getRoles() {
      this.getRolesBusy = true
      try {
        const response = await api.getRoles()
        if (response.success) {
          this.getRolesSuccess = true
          this.rolesData = response.data
        }
        return response
      } catch (error) {
        this.getRolesError = true
        this.getRolesErrors = error.error || error
        return error
      } finally {
        this.getRolesBusy = false
      }
    },
  },
})
