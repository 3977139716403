<template>
  <div class="view-content__wrapper">
    <div class="view-content d-block d-md-flex align-center justify-center mx-auto">
      <div>
        <div
          v-if="type == 'registration'"
          class="registration"
        >
          <div class="text-center mb-6">
            <v-icon size="70">
              icon-danger text-danger200
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            <span v-if="$te('registration_failed.' + error)">{{ $t('registration_failed.' + error) }}</span>
            <span v-if="$te('repeating_errors.' + error)">{{ $t('repeating_errors.' + error) }}</span>
          </p>
        </div>
        <div
          v-if="type == 'activation'"
          class="registration"
        >
          <div class="text-center mb-6">
            <v-icon size="70">
              icon-danger text-danger200
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('registration_failed.registration_failed') }}
          </p>
          <p
            class="body-3 text-center mb-6"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
          >
            {{ $t('registration_failed.activation_failed') }}
          </p>
        </div>
        <div
          v-if="type == 'schoolRegister'"
          class="registration"
        >
          <div class="text-center mb-6">
            <v-icon size="70">
              icon-danger text-danger200
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('school_registration.school_registration_failed') }}
          </p>
        </div>
        <div
          v-if="type == 'reset'"
          class="registration"
        >
          <div class="text-center mb-6">
            <v-icon size="70">
              icon-danger text-danger200
            </v-icon>
          </div>
          <p
            class="headline-4 text-center mx-10"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale100' : 'text-graybase'"
          >
            {{ $t('password_recovery.reset_failed') }}
          </p>
          <p
            class="body-3 text-center mb-6"
            :class="$vuetify.theme.global.name == 'dark' ? 'text-grayscale400' : 'text-grayscale500'"
          >
            <span v-if="$te('password_recovery.' + message)">{{ $t('password_recovery.' + message) }}</span>
            <span v-if="$te('repeating_errors.' + message)">{{ $t('repeating_errors.' + message) }}</span>
          </p>
        </div>
        <div class="text-center mt-4">
          <e-btn
            variant="link"
            size="small"
            :block="false"
            :to="'/' + $i18n.locale"
          >
            {{ $t('forgot_password.back_to_frontpage') }}
          </e-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  data() {
    return {
      email: '',
      type: undefined,
      error: '',
      message: undefined,
      hash: undefined,
    }
  },
  mounted() {
    this.email = window.history.state.email
    this.error = window.history.state.error
    this.message = window.history.state.message
    if (window.history.state.type) {
      this.type = window.history.state.type
    } else if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    //navigate to main page, if no type
    if (this.type == undefined) {
      this.$router.replace({
        name: 'login',
      })
    }
  },
}
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
