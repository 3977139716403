<template>
  <form @submit.prevent="login()">
    <div class="mb-0">
      <div class="text-center py-4 px-10 headline-4">
        {{ $t('id_card_login.id_card_text') }}
      </div>
      <e-btn
        class="mt-4"
        :block="true"
        type="submit"
        :loading="cardBusy"
        variant="primary"
        size="large"
      >
        {{ $t('password_login.login') }}
      </e-btn>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useCardStore, useGlobalStore, usePasswordStore } from '@/store'
import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
export default {
  components: {
    EBtn,
  },
  props: {
    register: Boolean,
  },
  computed: {
    ...mapState(useCardStore, [
      'cardBusy',
      'cardError',
      'cardSuccess',
      'cardErrors',
      'cardMultipleAccounts',
      'cardAccounts',
      'cardUser',
    ]),
    ...mapState(useGlobalStore, ['miniView']),
  },
  methods: {
    ...mapActions(useCardStore, ['cardLogin', 'cardRegister']),
    ...mapActions(useGlobalStore, ['changeError', 'showError']),
    ...mapActions(usePasswordStore, ['setUser']),
    login() {
      if (this.register == false) {
        this.loginId()
      } else if (this.register == true) {
        this.registerId()
      } else {
        this.changeError('id_card_login.submit_error')
        this.showError(true)
      }
    },
    loginId() {
      this.cardLogin().then((response) => {
        if (this.cardSuccess && response.login_method == 'id_card') {
          if (this.miniView) {
            this.postLoginMessage('success')
            return
          }
          if (response.redirect) {
            window.top.location.href = response.url
          } else {
            this.setUser({ url: response.url })
          }
        } else if (this.cardSuccess && this.cardMultipleAccounts) {
          this.$router.push({
            name: 'chooseUser',
            state: {
              users: this.cardAccounts,
            },
          })
        } else if (this.cardError) {
          this.changeError('id_card_login.submit_error')
          this.showError(true)
        }
      })
    },
    registerId() {
      this.cardRegister().then(() => {
        if (this.cardSuccess && this.cardMultipleAccounts) {
          this.$router.push({
            name: 'chooseUser',
            state: {
              users: this.cardAccounts,
            },
          })
        } else if (this.cardUser !== undefined) {
          this.$router.push({
            name: 'setupAccount',
            state: {
              user: this.cardUser,
            },
          })
        } else if (this.cardErrors == 'user_id_code_already_exist') {
          this.changeError('repeating_errors.' + this.cardErrors)
          this.showError(true)
        } else if (this.cardError && this.cardErrors !== 'user_id_code_already_exist') {
          this.changeError('id_card_login.submit_error')
          this.showError(true)
        }
      })
    },
  },
}
</script>
