import * as api from '../api'
import { defineStore } from 'pinia'

export const useSetupAccountStore = defineStore('setupAccount', {
  state: () => ({
    setupAccountBusy: false,
    setupAccountError: false,
    setupAccountErrors: '',
    setupAccountMessage: '',
    setupAccountSuccess: false,
    setupAccountCountry: undefined,
    setupAccountHash: undefined,
    proceedRegistrationErrors: '',
    proceedRegistrationSuccess: false,
    showPhoneNumberModal: false,
    registerEmailValidateSuccess: false,
    registerEmailValidateError: false,
    registerEmailValidateBusy: false,
    registerEmailValidateErrors: '',
    registerPhoneVerifyRequestBusy: false,
    registerPhoneVerifyRequestSuccess: false,
    registerPhoneVerifyRequestTimeout: 0,
    registerPhoneVerifyRequestError: false,
    registerPhoneVerifyRequestErrors: '',
    registerPhoneVerifyCodeBusy: false,
    registerPhoneVerifyCodeSuccess: false,
    registerPhoneVerifyCodeError: false,
    registerPhoneVerifyCodeErrors: '',
    getTeacherInviteDataBusy: false,
    getTeacherInviteDataSuccess: false,
    getTeacherInviteDataErrors: '',
    teacherInviteEmail: undefined,
  }),

  actions: {
    // Setup Account
    setupAccount({ firstName, lastName, idCode, issuer, dateOfBirth, gender, email, emailConfirmation, password, passwordConfirmation, wishPartnerAds, countryCode, phoneNumber, country, county, city, street, postalIndex, thirdPartyHash, inviteType, inviteHash }) {
      this.setupAccountBusy = true
      return api
        .registerUser(firstName, lastName, idCode, issuer, dateOfBirth, gender, email, emailConfirmation, password, passwordConfirmation, wishPartnerAds, countryCode, phoneNumber, country, county, city, street, postalIndex, thirdPartyHash, inviteType, inviteHash)
        .then((response) => {
          this.setupAccountBusy = false
          if (response.success) {
            this.setupAccountSuccess = true
            this.setupAccountMessage = response.message
            this.setupAccountHash = response.hash
          } else {
            this.setupAccountError = true
          }
          return response
        })
        .catch((error) => {
          this.setupAccountBusy = false
          this.setupAccountError = true
          if (error.errors) {
            this.setupAccountErrors = error.errors
          }
          return error
        })
    },

    // Proceed Registration
    proceedRegistration(hash) {
      return api.createAccSameId(hash).then((response) => {
        this.proceedRegistrationSuccess = response.success
        return response
      }).catch((error) => {
        this.proceedRegistrationSuccess = error.success
        this.proceedRegistrationErrors = error.errors
        return error
      })
    },

    // Get Country
    getCountry() {
      return api.getCountry().then((response) => {
        if (response.status === 200) {
          this.setupAccountCountry = response.country
        }
        return response
      }).catch(() => {
        return false
      })
    },

    // Register Email Validate
    registerEmailValidate(email) {
      this.registerEmailValidateBusy = true
      this.registerEmailValidateSuccess = false
      this.registerEmailValidateError = false
      this.registerEmailValidateErrors = ''
      
      return api.registerEmailValidate(email).then((response) => {
        this.registerEmailValidateBusy = false
        if (response.success) {
          this.registerEmailValidateSuccess = true
        } else {
          this.registerEmailValidateError = true
        }
        return response
      }).catch((error) => {
        this.registerEmailValidateBusy = false
        this.registerEmailValidateError = true
        this.registerEmailValidateSuccess = false
        if (error.errors) {
          this.registerEmailValidateErrors = error.errors
        }
        return error
      })
    },

    // Register Phone Verify Request
    registerPhoneVerifyRequest({ countryCode, phoneNumber, token }) {
      this.registerPhoneVerifyRequestBusy = true
      this.registerPhoneVerifyRequestSuccess = false
      this.registerPhoneVerifyRequestError = false
      this.registerPhoneVerifyCodeError = false
      this.registerPhoneVerifyCodeErrors = ''
      this.registerPhoneVerifyRequestErrors = ''
      this.registerPhoneVerifyRequestTimeout = 0
      
      return api.registerPhoneVerifyRequest(countryCode, phoneNumber, token).then((response) => {
        this.registerPhoneVerifyRequestBusy = false
        if (response.success) {
          this.registerPhoneVerifyRequestSuccess = true
        } else {
          this.registerPhoneVerifyRequestError = true
        }
        if (response.timeout) {
          this.registerPhoneVerifyRequestTimeout = response.timeout
        }
        return response
      }).catch((error) => {
        this.registerPhoneVerifyRequestBusy = false
        this.registerPhoneVerifyRequestError = true
        if (error.errors) {
          this.registerPhoneVerifyRequestErrors = error.errors
        }
        if (error.timeout) {
          this.registerPhoneVerifyRequestTimeout = error.timeout
        }
        return error
      })
    },

    // Register Phone Verify Code
    registerPhoneVerifyCode({ countryCode, phoneNumber, code }) {
      this.registerPhoneVerifyCodeBusy = true
      this.registerPhoneVerifyCodeSuccess = false
      this.registerPhoneVerifyCodeError = false
      this.registerPhoneVerifyCodeErrors = ''
      this.registerPhoneVerifyRequestError = false
      this.registerPhoneVerifyRequestErrors = ''
      
      return api.registerPhoneVerifyCode(countryCode, phoneNumber, code).then((response) => {
        this.registerPhoneVerifyCodeBusy = false
        if (response.success) {
          this.registerPhoneVerifyCodeSuccess = true
        } else {
          this.registerPhoneVerifyCodeError = true
        }
        return response
      }).catch((error) => {
        this.registerPhoneVerifyCodeBusy = false
        this.registerPhoneVerifyCodeError = true
        if (error.errors) {
          this.registerPhoneVerifyCodeErrors = error.errors
        }
        return error
      })
    },

    // Get Teacher Invite Data
    getTeacherInviteData(hash) {
      this.getTeacherInviteDataBusy = true
      this.getTeacherInviteDataSuccess = false
      this.getTeacherInviteDataErrors = ''
      
      return api.getTeacherInviteData(hash).then((response) => {
        this.getTeacherInviteDataBusy = false
        this.getTeacherInviteDataSuccess = response.success
        this.teacherInviteEmail = response.email
        return response
      }).catch((error) => {
        this.getTeacherInviteDataBusy = false
        this.getTeacherInviteDataSuccess = error.success
        if (error.errors) {
          this.getTeacherInviteDataErrors = error.errors
        }
        return error
      })
    },

    setRegisterEmailValidateSuccess(value) {
      this.registerEmailValidateSuccess = value
    },

    setPhoneNumberModal(value) {
      this.showPhoneNumberModal = value
    },

    setRegisterPhoneVerifyCodeSuccess(value) {
      this.registerPhoneVerifyCodeSuccess = value
    },
  },
})